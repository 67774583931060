import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/dashboard.svg',
    submenu: [],
  },
  {
    path: '',
    title: 'Results',
    icon: 'bi bi-cash',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/entities.svg',
    submenu: [
      {
        path: 'cte/closing',
        title: 'Closing Positions',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: 'cte/banks',
        title: 'Bank Balances',
        icon: 'bi bi-cash',
        class: '',
        extralink: false,
        image: './assets/images/icon/entities.svg',
        submenu: [],
      },
    ],
  },
  {
    path: 'charts',
    title: 'Charts',
    icon: 'bi bi-cash',
    class: '',
    extralink: false,
    image: './assets/images/icon/entities.svg',
    submenu: [],
  },
  {
    path: 'entities',
    title: 'Entities',
    icon: 'bi bi-cash',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/entities.svg',
    submenu: [
      {
        path: 'tte/asset',
        title: 'Assets',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/people.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'originator/profiles',
        title: 'Profiles',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: 'entities/risk-rate',
        title: 'Risk & Rate',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
    ],
  },
  {
    path: '',
    title: 'Buy',
    icon: 'bi bi-arrow-left-right',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [
      {
        path: '',
        title: 'Asset',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'tte/contracts',
        title: 'Contracts',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'buy/assess',
        title: 'Assess Analyse',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'buy/approve',
        title: 'Approve',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'buy/authorise',
        title: 'Authorise',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'buy/prepare',
        title: 'Prepare',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'buy/spool',
        title: 'Spool',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'buy/audit',
        title: 'Audit',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
    ],
  },
  {
    path: '',
    title: 'Cash',
    icon: 'bi bi-cash',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/cash.svg',
    submenu: [
      {
        path: 'cte/investments',
        title: 'Investments',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'cte/transactions',
        title: 'Transactions',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'cte/identification',
        title: 'Identification',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
    ],
  },
  {
    path: '',
    title: 'Trade',
    icon: 'bi bi-arrow-left-right',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [
      {
        path: 'finance/reserves',
        title: 'Reserves',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: 'tte/liquidated',
        title: 'Positions',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/liquidated.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'tte/investors/statement',
        title: 'Investments',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      /*{
        path: '/tte/balances',
        title: 'Balances',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },*/
      {
        path: 'tte/transactions',
        title: 'Transactions',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'tte/amortisation',
        title: 'Amortisation',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'tte/algorithms',
        title: 'Algorithms',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
    ],
  },
  {
    path: '',
    title: 'Finance',
    icon: 'bi bi-cash',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/entities.svg',
    submenu: [
      {
        path: 'finance/statutory',
        title: 'Statutory',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'finance/accounts',
        title: 'Accounts',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'finance/ledger',
        title: 'Ledger',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/people.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'finance/transactions',
        title: 'Transactions',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'finance/postings',
        title: 'Postings',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'finance/models',
        title: 'Models',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/people.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
    ],
  },
  {
    path: '',
    title: 'Exceptions',
    icon: 'bi bi-circle-fill',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/admin.svg',
    submenu: [
      {
        path: 'exceptions/tar',
        title: 'TaR',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Controls',
    icon: 'bi bi-cash',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/entities.svg',
    submenu: [

      {
        path: 'controls/data',
        title: 'Data',
        icon: 'bi bi-gear', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'controls/cash',
        title: 'Cash',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
      {
        path: 'controls/trade',
        title: 'Trade',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
      {
        path: 'controls/finance',
        title: 'Finance',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
      {
        path: 'exchange',
        title: 'Exchange',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
      {
        path: 'controls/check-zero',
        title: 'Check Zero',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
      {
        path: 'reports/checkbank',
        title: 'Check Bank',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
      {
        path: 'reports/bankaudit',
        title: 'Bank Audit',
        icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [], // Submenu of the submenu item if needed
      },
    ],
  },
  {
    path: '',
    title: 'Administration',
    icon: 'bi bi-circle-fill',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/admin.svg',
    submenu: [
      {
        path: 'update-bank',
        title: 'Identification',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
      {
        path: 'manage-users',
        title: 'Manage Users',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'System Logs',
    icon: 'bi bi-circle-fill',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/admin.svg',
    submenu: [
      {
        path: 'logs/etr/authorised',
        title: 'Authorised ETR',
        icon: 'bi bi-gear',
        class: '',
        extralink: false,
        image: './assets/images/icon/report.svg',
        submenu: [],
      },

    ],
  },
  {
    path: '',
    title: 'Data',
    icon: 'bi bi-arrow-left-right',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/report.svg',
    submenu: [
      {
        path: '',
        title: 'Investments',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Bank',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'ETR',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
    ],
  },
];


import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradeSharedService {
  public selectedEtrType = new Subject<any>();
  public selectedPageSize = new Subject<number>();

  setEtrType(EtrType: any) {
    this.selectedEtrType.next(EtrType);
  }

  getEtrType() {
    return this.selectedEtrType.asObservable();
  }

  setPageSize(pageSize: any) {
    this.selectedPageSize.next(pageSize);
  }

  getPageSize() {
    return this.selectedPageSize.asObservable();
  }

}

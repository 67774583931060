export const INVESTMENTS_PARAMS_KEYS: string[] = [
  'sort',
  'search',
  'startDate',
  'endDate',
  'date',
  'week',
  'month',
  'year',
  'id',
  'bankDate',
  'bankTransactionDetails',
  'bankTransactionType',
  'bankPayment',
  'bankReceipt',
  'intermediaryId',
  'intermediaryName',
  'investorId',
  'investorName',
  'product',
  'transactionType',
  'ccy',
  'faceValue',
  'buyRate',
  'commission',
  'months',
  'type',
  'comments',
  'history',
  'brand',
  'clutch',
  'cash',
  'ledgerName',
  'ledgerId',
  'savings',
  'trusteeId',
];

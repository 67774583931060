import { Injectable } from '@angular/core';
import { HttpService } from "../../services/http.service";
import { Observable } from "rxjs/internal/Observable";

@Injectable({ providedIn: 'root' })
export class BankUpdateService extends HttpService {

  generateBankAmounts(): Observable<any> {
    return this.http.patch(`${this.baseUrl}fx-rates/generate-bank-amounts`, {})
  }

  transactionIdentification(): Observable<any> {
    return this.http.patch(`${this.baseUrl}banks/transaction-identification`, {})
  }
}

export const PercentFormatter = (value: number, isShowPercentSign = true): string => {
  const originalValue = +value;
  let formattedValue = isShowPercentSign ? '-' : '0';
  if (!originalValue || originalValue === 0 || originalValue === null) {
    return formattedValue;
  }

  if (originalValue < 10) {
    formattedValue = `${originalValue.toLocaleString('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })}`;
  } else if (originalValue >= 10 && originalValue < 100) {
    formattedValue = `${originalValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  } else {
    formattedValue = `${originalValue.toLocaleString('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}`;
  }
  return isShowPercentSign ? `${formattedValue} %` : formattedValue;
};

export const PercentFormatterTest = (value: number, isShowPercentSign = true): string => {
  const originalValue = +value;
  let formattedValue = isShowPercentSign ? '-' : '0';
  if (!originalValue || originalValue === 0 || originalValue === null) {
    return formattedValue;
  }

  if (originalValue < 10) {
    formattedValue = `${originalValue.toLocaleString('en-US', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    })}`;
  } else if (originalValue >= 10 && originalValue < 100) {
    formattedValue = `${originalValue.toLocaleString('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })}`;
  } else {
    formattedValue = `${originalValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
  return isShowPercentSign ? `${formattedValue} %` : formattedValue;
};

<!--<ngx-spinner type="ball-scale-multiple" [showSpinner]="isLoading" />-->

<div class="iframe-container">
  <div class="card mb-0 common-header" id="header-row">
    <app-grid-filter
      [label]="'Cash Investments'"
      [selectedClutch]="selectedClutch"
      [clutchList]="clutchList"
      [currentFilter]="currentFilter"
      [ccyList]="ccyData"
      [selectedCcy]="selectedCcy"
      [selectedMonth]="selectedMonth"
      [selectedRecordPerPage]="selectedRecordPerPage"
      [selectedFinancialYear]="selectedFinancialYear"
      [dateRange]="dateRange"
      class="sideBarClass"
      [savedFilterModel]="savedFilterModel"
      [showNewButton]="true"
      (saveFilterData)="saveFilterData($event)"
      (refreshPage)="refreshPage()"
      (formChange)="filterChanged($event)"
      (createNew)="openModal($event)"
      (export)="exportData()"
      (print)="onPrint()"
    >
  <div content class="d-flex justify-content-center align-items-center">
    <label for="isApproved" class="me-1">Unapproved:</label>
      <input
      id="isApproved"
      type="checkbox"
      class="ft-width cursor-pointer"
      (change)="onCheckboxChange($event)"
    />
  </div>
  </app-grid-filter>
  </div>
  
  <app-grid-container
    [gridRowData]="rowData"
    [gridColDefs]="colDefs"
    [gridDefaultColDef]="defaultColDef"
    [gridPaginationPageSize]="paginationPageSize"
    [hasPagination]="false"
    class="sideBarClass"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (filterChanged)="onFilterChanged($event)"
    (sortChanged)="onSortChanged($event)"
    (columnVisible)="onColumnVisibilityChanged($event)"
    [sideBar]="sideBar"
  />
</div>

<app-paginator
  [currentPageIndex]="currentPageIndex"
  [paginationPageSize]="paginationPageSize"
  [totalPage]="totalPage"
  [totalPageString]="totalPageString"
  [totalRecordsDisplay]="totalRecordsDisplay"
  (pageChange)="changePageSelection($event)"
/>

<!-- come-from-modal right -->
<div
  *ngIf="isShowAddEditInvestments"
  class="modal fade show"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title">
          {{ currentRecord ? "Edit Investment" : "Add Investment" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body padding-model form-editing">
        <app-add-edit-investments
          [isUpload]="false"
          [investmentData]="currentRecord"
          [trusteeList]="trusteeList"
          (closeDialogEvent)="isShowAddEditInvestments = $event"
          (createUpdateEvent)="onGridRefresh()"
        />
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowAddEditInvestor"
     class="modal fade show"
     tabindex="-1"
     role="dialog"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" [ngStyle]="{width:dialogWidth}">
      <div class="modal-header justify-content-between">
          <h5 class="modal-title me-3">Add Investor</h5>
            <ng-toggle
              [value]="toggleSettings.values"
              [width]="isPersonInvestor ? 72 : 105"
              [height]="20"
              [labels]="toggleSettings.labels"
              [switchColor]="toggleSettings.switchColor"
              [color]="toggleSettings.color"
              [values]="toggleSettings.values"
              [fontColor]="toggleSettings.fontColor"
              (valueChange)="toggleChange($event)"
            />
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModalInvestor()"
        >
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body padding-model form-editing">
        <app-add-edit-investor
          [investmentData]="currentRecord"
          [isPerson]="isPersonInvestor"
          (closeDialogEvent)="isShowAddEditInvestor = $event"
          (createUpdateEvent)="onGridRefresh()"
        />

      </div>
    </div>
  </div>
</div>

<div
  *ngIf="multipleSelected"
  class="modal fade show"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content" style="width: 480px">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title">Edit Investments</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeBulkEditModal()"
        >
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body padding-model form-editing">
        <app-investment-bulk-update
          [selectedRows]="selectedRows"
          (closeDialogEvent)="multipleSelected = $event"
          (createUpdateEvent)="onGridRefresh()"
        />
      </div>
    </div>
  </div>
</div>


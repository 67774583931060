import { ColDef } from '@ag-grid-community/core';
import { tooltipRenderer } from '../shared/helpers/tooltip-renderer';
import { numberValueFormatter } from '../shared/helpers/number-value-formatter';
import { CustomSelectSearchFilterComponent } from '../shared/components/custom-select-search-filter/custom-select-search-filter.component';
import { CustomTooltipComponent } from "../shared/components/custom-tooltip.component";

const filterParams = {
  maxNumConditions: 1,
  debounceMs: 700,
  textMatcher: ({ value, filterText }: any) => {
    return true;
  },
};

export const COL_DEFS: ColDef[] = [
  {
    headerName: 'No',
    field: 'no',
    width: 35,
    colId: 'no',
    cellClass: 'ag-right-aligned-cell',
    hide: false,
    suppressMovable: true,
    filterParams: {
      debounceMs: 700,
      maxNumConditions: 1,
    },
    sortable: false,
    floatingFilter: false,
    valueGetter: (params) => {
      if (!params.node || params.data.type === '') return '';
      let index = 1;
      let result = '';
      params.api.forEachNodeAfterFilterAndSort((node:any) => {
        if (node.data.type !== '') {
          if (node === params.node) {
            result = index.toString();
          }
          index++;
        }
      });
      return result;
    }
  },
  {
    headerName: 'Ccy',
    sortable: false,
    field: 'ccy',
    filter: 'agTextColumnFilter',
    width: 40,
    colId: 'ccy',
    hide: false,
    suppressMovable: true,
    filterParams: filterParams,
    floatingFilter: true,
    floatingFilterComponent: CustomSelectSearchFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: null,
    },
    valueFormatter: (params) => (params.data.code === '0000' ? '' : params.value),
    suppressHeaderMenuButton: false
  },
  {
    headerName: 'Type',
    sortable: false,
    field: 'type',
    filter: 'agTextColumnFilter',
    width: 60,
    colId: 'type',
    hide: false,
    suppressMovable: true,
    filterParams: filterParams,
    floatingFilter: true,
    floatingFilterComponent: CustomSelectSearchFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: null,
    },
    valueFormatter: (params) => (params.data.code === '0000' ? '' : params.value),
    suppressHeaderMenuButton: false
  },
  {
    headerName: 'Trade ID',
    sortable: false,
    field: 'tradeId',
    width: 100,
    colId: 'tradeId',
    valueFormatter: (params) => (params.data.code === '0000' ? '' : params.value),
    hide: false,
    filter: 'agTextColumnFilter',
    suppressMovable: true,
    suppressHeaderMenuButton: true,
    filterParams: filterParams,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Transaction',
    sortable: false,
    field: 'tradeTransaction',
    width: 160,
    colId: 'tradeTransaction',
    valueFormatter: (params) => (params.data.code === '0000' ? '' : params.value),
    hide: false,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: filterParams,
    floatingFilter: false,
    suppressHeaderMenuButton: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Trade',
    sortable: false,
    field: 'tradeAmount',
    width: 80,
    colId: 'tradeAmount',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    cellRenderer: tooltipRenderer,
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Count',
    sortable: false,
    field: 'tradeCount',
    width: 60,
    colId: 'tradeCount',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: params => {
      if (params.value !== null && params.value !== undefined) {
        return Math.floor(params.value).toLocaleString();
      }
      return '';
    },
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Value',
    sortable: false,
    field: 'tradeValue',
    width: 100,
    colId: 'tradeValue',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'tradeValue',
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'EUR Value',
    sortable: false,
    field: 'tradeEurValue',
    width: 100,
    colId: 'tradeEurValue',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'tradeEurValue',
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: '',
    sortable: false,
    field: '',
    width: 20,
    cellClass: 'grey-background'
  },
  {
    headerName: 'Model No',
    sortable: false,
    field: 'modelNo',
    width: 50,
    colId: 'modelNo',
    headerTooltip: 'Model No',
    tooltipComponent: CustomTooltipComponent,
    hide: false,
    cellClass: 'ag-center-aligned-cell',
    suppressMovable: true,
  },
  {
    headerName: 'Journal No',
    sortable: false,
    field: 'journalNo',
    width: 20,
    colId: 'journalNo',
    headerTooltip: 'Journal No',
    tooltipComponent: CustomTooltipComponent,
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: (params) => (params.data.code === '0000' ? '' : params.value),
    filterParams: filterParams,
    floatingFilter: true,
    suppressHeaderMenuButton: false,
  },
  {
    headerName: 'Finance Transaction',
    sortable: false,
    field: 'financeTransaction',
    width: 160,
    colId: 'financeTransaction',
    valueFormatter: (params) => (params.data.code === '0000' ? '' : params.value),
    hide: false,
    suppressMovable: true,
  },
  {
    headerName: 'Trade',
    sortable: false,
    field: 'financeAmount',
    width: 80,
    colId: 'financeAmount',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    cellRenderer: tooltipRenderer,
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Count',
    sortable: false,
    field: 'financeCount',
    width: 60,
    colId: 'financeCount',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: params => {
      if (params.value !== null && params.value !== undefined) {
        return Math.floor(params.value).toLocaleString();
      }
      return '';
    },
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Value',
    sortable: false,
    field: 'financeValue',
    width: 100,
    colId: 'financeValue',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'financeValue',
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'EUR Value',
    sortable: false,
    field: 'financeEurValue',
    width: 100,
    colId: 'financeEurValue',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    tooltipField: 'financeEurValue',
    tooltipComponent: CustomTooltipComponent,
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: '',
    sortable: false,
    field: '',
    width: 15,
    cellClass: 'grey-background'
  },
  {
    headerName: 'Count',
    sortable: false,
    field: 'countResult',
    width: 60,
    colId: 'countResult',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: params => {
      if (params.value !== null && params.value !== undefined) {
        return Math.floor(params.value).toLocaleString();
      }
      return '';
    },
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Trade',
    sortable: false,
    field: 'tradeResult',
    width: 80,
    colId: 'tradeResult',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    cellRenderer: tooltipRenderer,
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'Value',
    sortable: false,
    field: 'valueResult',
    width: 100,
    colId: 'valueResult',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'valueResult',
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: 'EUR Value',
    sortable: false,
    field: 'eurValueResult',
    width: 100,
    colId: 'eurValueResult',
    hide: false,
    suppressMovable: true,
    cellClass: 'ag-right-aligned-cell',
    valueFormatter: numberValueFormatter,
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'eurValueResult',
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
];

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, DecimalPipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { AddEditInvestmentsComponent } from './investments/add-edit-investments/add-edit-investments.component';
import { AddUploadsComponent } from './uploads/add-uploads/add-uploads.component';
import { AddEtrComponent } from './uploads/add-etr/add-etr.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { provideUserIdleConfig } from 'angular-user-idle';
import { environment } from '../../src/environments/environment';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { InvestmentsComponent } from './investments/investments/investments.component';
import { UploadsComponent } from './uploads/uploads/uploads.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { EditUploadsComponent } from './uploads/edit-uploads/edit-uploads.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InvestmentComponent } from './crystalreports/investment/investment.component';
import { msalConfig } from './shared/config/auth-config';
import { MasterbankComponent } from './crystalreports/masterbank/masterbank.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { FxrateComponent } from './crystalreports/fxrate/fxrate.component';
import { ClickableStatusBarComponent } from './cash-transaction/components/clickable-status-bar-component.component';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { SharedModule } from './shared/shared.module';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { AddEditFxrateComponent } from './crystalreports/fxrate/components/add-edit-fxrate/add-edit-fxrate.component';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { NgToggleModule } from 'ng-toggle-button';
import { AddEditInvestorComponent } from './investments/add-edit-investor/add-edit-investor.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { InvestmentBulkUpdateComponent } from './investments/investment-bulk-update/investment-bulk-update.component';
import { ChartsComponent } from './charts/charts.component';
import { AgCharts, AgFinancialCharts } from 'ag-charts-angular';
import { InvestmentsChartsComponent } from './charts/investments-charts/investments-charts.component';
import { EtrChartsComponent } from './charts/etr-charts/etr-charts.component';
import { AgCharts as AgChartsEnterprise } from 'ag-charts-enterprise';
import { TarComponent } from './crystalreports/fxrate/components/tar/tar.component';
import { ControlsComponent } from './controls/controls.component';
import { NgProgressModule } from "ngx-progressbar";
import { HttpRequestResponseInterceptor } from "./interceptors/http-request-response.interceptor";

LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-048089 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( CREDEBT EXCHANGE LIMITED )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 November 2024 )____[v2]_MTczMTg4ODAwMDAwMA==480ab7edfe96fa6ecd32550c2f3532b4');
AgChartsEnterprise.setLicenseKey('Using_this_{AG_Charts}_Enterprise_key_{AG-067927}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Trade_Credebt_Limited}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Charts}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts}_Enterprise_versions_released_before_{2_October_2025}____[v3]_[02]_MTc1OTM1OTYwMDAwMA==cd11a87f20e7035eb822da03a6be6822');
ModuleRegistry.registerModules([RangeSelectionModule, ExcelExportModule, MasterDetailModule]);
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export const API_URL = new InjectionToken<string>('API_URL');

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const timestamp = new Date().getTime();

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
      //loginHint: timestamp.toString(),
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    AddEditInvestmentsComponent,
    AddEditInvestorComponent,
    AddUploadsComponent,
    LoginComponent,
    InvestmentsComponent,
    EditUploadsComponent,
    InvestmentComponent,
    UploadsComponent,
    MasterbankComponent,
    AddEtrComponent,
    FxrateComponent,
    // OnlyNumberDirective,
    //EditBankComponent,
    AddEditFxrateComponent,
    InvestmentBulkUpdateComponent,
    ChartsComponent,
    InvestmentsChartsComponent,
    EtrChartsComponent,
    TarComponent,
    ControlsComponent
  ],
  imports: [
    ClickableStatusBarComponent,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    RouterModule.forRoot([]),
    FullComponent,
    SidebarComponent,
    NavigationComponent,
    BsDatepickerModule.forRoot(),
    MsalModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    //AgGridModule,
    AgGridAngular,
    NgSelectModule,
    SharedModule,
    NgToggleModule.forRoot(),
    AgCharts,
    AgFinancialCharts,
    NgProgressModule,
  ],
  providers: [
    provideUserIdleConfig({
      idle: environment.Idle,
      timeout: environment.TimeOut,
      ping: 120,
    }),
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: API_URL,
      useValue: environment.url,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    // @todo need add functionality
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestResponseInterceptor,
      multi: true,
    },*/
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    DatePipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UploadsComponent,
    DecimalPipe,
    ClickableStatusBarComponent,
  ],
  exports: [UploadsComponent, ClickableStatusBarComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs/internal/Observable';
type Meta = {
  pageIndex: number;
  perPage: number;
  total: number;
};

export interface ControlTradeFinance {
  id: number;
  ccy: string;
  type: string;
  tradeId: string;
  tradeTransaction: string;
  tradeAmount: number;
  tradeCount: number;
  tradeValue: number;
  tradeEurValue: number;
  modelNo: string;
  journalNo: number;
  financeTransaction: string;
  financeAmount: number;
  financeCount: number;
  financeValue: number;
  financeEurValue: number;
  countResult: number;
  tradeResult: number;
  valueResult: number;
  eurValueResult: number;
}
export interface ControlTradeFinanceResponse {
  items: ControlTradeFinance[];
  meta: Meta;
}
@Injectable({
  providedIn: 'root',
})
export class ControlsService extends HttpService {
  getModelsDistinctValues(): Observable<{ ledgerCodes: string[]; modelNumbers: string[]; models: Array<{ name: string; modelNo: string }> }> {
    return this.http.get<{ ledgerCodes: string[]; modelNumbers: string[]; models: Array<{ name: string; modelNo: string }> }>(
      this.baseUrl + 'models/distinct-filter-values',
    );
  }
  getControlTradeFinance(params: any): Observable<ControlTradeFinanceResponse> {
    return this.http.get<ControlTradeFinanceResponse>(this.baseUrl + 'ledger-postings/control-trade-finance', { params });
  }
  getDistinct(): Observable<
    {
      ccy: string;
      type: string;
      tradeIds: string[];
    }[]
  > {
    return this.http.get<
      {
        ccy: string;
        type: string;
        tradeIds: string[];
      }[]
    >(this.baseUrl + 'ledger-postings/distinct-values-for-control');
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment/moment';
import Swal from 'sweetalert2';
import { FxRateFormProvider } from '../../common/providers/fx-rate-form.provider';
import { FxrateInterfce } from '../../common/interfaces/fxrate-response.interface';
import { FxrateHttpService } from '../../common/services/fxrate-http.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { getFormData } from '../../../../shared/helpers/utils';

@Component({
  selector: 'app-add-edit-fxrate',
  templateUrl: './add-edit-fxrate.component.html',
  styleUrls: ['./add-edit-fxrate.component.scss'],
})
export class AddEditFxrateComponent implements OnInit {
  fxRateForm: FormGroup;
  tooltipData = {
    date: 'Date',
    buyRate: 'Buy Rate',
    eonia: 'Euro Overnight Index Average',
    libor: 'London InterBank Offered Rate',
    obfr: 'Overnight Bank Funding Rate',
    us10y: 'US10Y',
    us3y: 'US3Y',
  };
  isSubmitted = false;

  @Input() fxRateData: FxrateInterfce;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() createUpdateEvent = new EventEmitter<boolean>();
  maxDate: NgbDateStruct;

  constructor(
    private fxrateHttpService: FxrateHttpService,
    private fxRateFormProvider: FxRateFormProvider,
  ) {}

  ngOnInit(): void {
    this.fxRateForm = this.fxRateFormProvider.initFxRateForm(this.fxRateData);
    this.maxDate = this.getCurrentDateAsNgbDateStruct();
  }

  getCurrentDateAsNgbDateStruct(): NgbDateStruct {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1, // Note: Months are 0-based in JavaScript
      day: now.getDate(),
    };
  }

  get errorControl() {
    return this.fxRateForm.controls;
  }

  onSubmit() {
    const fieldsToFormat = ['date'];

    fieldsToFormat.forEach((field) => {
      if (this.fxRateForm.value[field] && typeof this.fxRateForm.value[field] === 'object') {
        const dateMoment = moment(`${this.fxRateForm.value[field].year}-${this.fxRateForm.value[field].month}-${this.fxRateForm.value[field].day}`, 'YYYY-MM-DD');
        this.fxRateForm.value[field] = moment(dateMoment).format('YYYY-MM-DD');
      }
    });

    this.isSubmitted = true;

    if (this.fxRateForm.invalid) {
      return;
    }
    let rawData = {
      ...this.fxRateForm.value,
      buyRate: +(this.fxRateForm.value.buyRate / 100).toFixed(8),
      us10y: this.fxRateForm.value.us10y ? +(this.fxRateForm.value.us10y / 100).toFixed(8) : null,
      us3y: this.fxRateForm.value.us3y ? +(this.fxRateForm.value.us3y / 100).toFixed(8) : null,
      demand: this.fxRateForm.value.demand ? +(this.fxRateForm.value.demand / 100).toFixed(8) : null,
      fixed: this.fxRateForm.value.fixed ? +(this.fxRateForm.value.fixed / 100).toFixed(8) : null,
      term: this.fxRateForm.value.term ? +(this.fxRateForm.value.term / 100).toFixed(8) : null,
      gbp: this.fxRateForm.value.gbp ? +(+this.fxRateForm.value.gbp).toFixed(8) : null,
      usd: this.fxRateForm.value.usd ? +(+this.fxRateForm.value.usd).toFixed(8) : null,
      eonia: this.fxRateForm.value.eonia ? +(this.fxRateForm.value.eonia / 100).toFixed(4) : null,
      libor: this.fxRateForm.value.libor ? +(this.fxRateForm.value.libor / 100).toFixed(4) : null,
      obfr: this.fxRateForm.value.obfr ? +(this.fxRateForm.value.obfr / 100).toFixed(4) : null,
    };
    delete rawData.id;

    if (!this.fxRateData) {
      rawData = getFormData(rawData);
    }

    this.fxRateData?.id ? this.doUpdate(rawData) : this.doCreate(rawData);
  }

  doCreate(data: any): void {
    this.fxrateHttpService.postData('fx-rates', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.fxRateForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Fx & Overnight Rates successfully added.',
          showConfirmButton: false,
          timer: 3000,
        });
        this.createUpdateEvent.emit(true);
      },
    });
  }

  doUpdate(data: any): void {
    this.fxrateHttpService.updateDataV2('fx-rates', this.fxRateData.id, data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.fxRateForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Fx & Overnight Rates successfully updated.',
          showConfirmButton: false,
          timer: 3000,
        });
        this.createUpdateEvent.emit(true);
      },
    });
  }

  closeModal() {
    this.isSubmitted = false;
    this.closeDialogEvent.emit(false);
  }

  checkLength(e: any) {
    const KEYS = {
      leftKey: 37,
      rightKey: 39,
      backspace: 8,
      deleteKey: 46,
    };

    const { leftKey, rightKey, backspace, deleteKey } = KEYS;

    const isNavigationKey = e.keyCode === backspace || e.keyCode === deleteKey || e.keyCode === leftKey || e.keyCode === rightKey;

    if (isNavigationKey) {
      return true;
    }

    const target = e.target as HTMLInputElement;

    // Calculate the length of the input after the key is pressed
    const selectionStart = target.selectionStart || 0;
    const selectionEnd = target.selectionEnd || 0;
    const selectionLength = selectionEnd - selectionStart;
    const newLength = target.value.length - selectionLength + 1;

    if (newLength > target.maxLength) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    return true;
  }

  limitInputLength(inputField: any): void {
    const match = inputField.value.match(/^(\d+(\.\d{0,4})?)?/);
    if (match) {
      inputField.value = match[0];
    }
    /*if (inputField.value.length > inputField.maxLength) {
      inputField.value = inputField.value.slice(0, inputField.maxLength);
    }*/
  }
}

<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- First level menu -->
    <li
      *ngFor="let sidebarnavItem of sidebarnavItems"
      class="sidebar-item"
      routerLinkActive="active-parent"
    >
      <a
        *ngIf="!sidebarnavItem.extralink"
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="sidebarnavItem.path && !sidebarnavItem.isNeedBlockNavigate ? [sidebarnavItem.path] : null"
        [class.has-arrow]="sidebarnavItem.class === 'collapse'"
        [ngClass]="sidebarnavItem.class"
        routerLinkActive="active-parent"
        (click)="addExpandClass(sidebarnavItem.title)"
      >
        <img
          [src]="sidebarnavItem.image || 'assets/images/default-menu.png'"
          [alt]="sidebarnavItem.title + ' icon'"
          class="menu-image"
        />
        <span class="hide-menu">{{ sidebarnavItem.title }}</span>
        <span
          *ngIf="sidebarnavItem.title === 'Cash' && unapprovedCount > 0 && unapprovedCountShow"
          class="bg-danger border border-light rounded-circle"
        >
          {{ unapprovedCount }}
        </span>
      </a>

      <!-- Submenu -->
      <ul
        class="collapse first-level"
        [class.in]="showMenu === sidebarnavItem.title"
        aria-expanded="false"
        routerLinkActive="in"
      >
        <li *ngFor="let submenu of sidebarnavItem.submenu"
            class="sidebar-item cursor-pointer"
            routerLinkActive="active">
          <a
            class="sidebar-link cursor-pointer"
            [routerLink]="submenu.path && !submenu.isNeedBlockNavigate ? [submenu.path] : null"
            (click)="addExpandSubClass(submenu)"
          >
            <img
              [src]="submenu.image || 'assets/images/default-menu.png'"
              [alt]="submenu.title + ' icon'"
              class="menu-image"
            />
            <span class="hide-menu">{{ submenu.title }}</span>
            <span
              *ngIf="sidebarnavItem.title === 'Cash' && submenu.title === 'Investments' && unapprovedCount > 0 "
              class="bg-danger border border-light rounded-circle"
            >
              {{ unapprovedCount }}
            </span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>

import { Inject, Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TradeTransactionsQueryParams } from 'src/app/shared/interfaces/trade-transactions-query-params';
import { Observable } from 'rxjs/internal/Observable';
import { TradeTransactionsResponseInterface } from 'src/app/shared/interfaces/trade-transactions-response.interface';
import { Summary } from 'src/app/shared/interfaces/base-response-interface';
import { DistinctValuesResponse } from 'src/app/shared/interfaces/distinct-values-response.interface';
import { SelectItem } from 'src/app/shared/interfaces/select-item.interface';
import { BehaviorSubject, catchError, of, share, Subject, switchMap, take, takeUntil } from 'rxjs';
import { API_URL } from 'src/app/app.module';
import { ChartQueryParams } from 'src/app/shared/interfaces/chart-query-param';
import { ChartResponseInterface } from 'src/app/shared/interfaces/chart-response';

@Injectable({ providedIn: 'root' })
export class TradeHttpService extends HttpService {
  private requestTrigger$ = new BehaviorSubject<Partial<TradeTransactionsQueryParams> | null>(null);
  private requestTriggerByDebtor$ = new BehaviorSubject<Partial<TradeTransactionsQueryParams> | null>(null);
  private responseSubject$ = new Subject<TradeTransactionsResponseInterface>();
  private responseByDebtorSubject$ = new Subject<TradeTransactionsResponseInterface>();
  private summaryTrigger$ = new BehaviorSubject<Partial<TradeTransactionsQueryParams> | null>(null);
  private summarySubject$ = new Subject<Summary>();
  constructor(
    @Inject(API_URL) baseUrl: string,
    override http: HttpClient,
  ) {
    super(baseUrl, http);
    this.setupRequestHandler('etr', this.responseSubject$);
    this.setupRequestHandlerByDebtor('etr/by-debtor', this.responseByDebtorSubject$);
    this.setupRequestHandler('etr/summary', this.summarySubject$);
  }

  getTradeTransactionsData(params: Partial<TradeTransactionsQueryParams>): Observable<TradeTransactionsResponseInterface> {
    this.requestTrigger$.next(params);
    return this.responseSubject$.pipe(take(1));
  }

  getTradeTransactionsByDebtor(params: Partial<TradeTransactionsQueryParams>): Observable<TradeTransactionsResponseInterface> {
    this.requestTriggerByDebtor$.next(params);
    return this.responseByDebtorSubject$.pipe(take(1));
  }

  deleteTradeTransactionsData(id: any) {
    const baseUrl = `${this.baseUrl}etr/${id}`;
    return this.http.delete(baseUrl);
  }

  bulkDeleteTradeTransactionsData(ids: number[]) {
    const baseUrl = `${this.baseUrl}etr/bulk-delete`;
    return this.http.delete(baseUrl, { body: { ids } });
  }

  getTradeTransactionsByRefId(params: Partial<TradeTransactionsQueryParams>): Observable<TradeTransactionsResponseInterface> {
    return this.http.get<TradeTransactionsResponseInterface>(`${this.baseUrl}etr`, {
      params: this.queryCleaner(params),
    });
  }


  getEtrChart(params: Partial<ChartQueryParams>): Observable<ChartResponseInterface> {
    return this.http.get<ChartResponseInterface>(`${this.baseUrl}etr/chart`, {
      params: this.queryCleaner(params),
    });
  }

  getTradeTransactionsSummaryData(params: Partial<TradeTransactionsQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${this.baseUrl}fx-rates/summary`, {
      params: this.queryCleaner(params),
    });
  }

  getEtrSummary(params: Partial<TradeTransactionsQueryParams>): Observable<Summary> {
    this.summaryTrigger$.next(params);
    return this.summarySubject$.pipe(take(1));
  }

  getOriginators(): Observable<SelectItem[]> {
    const baseUrl = this.baseUrl + 'entity/originators';
    return this.http.get<SelectItem[]>(baseUrl);
  }

  getDebtors(): Observable<SelectItem[]> {
    const baseUrl = this.baseUrl + 'entity/debtors';
    return this.http.get<SelectItem[]>(baseUrl);
  }

  getCreditors(): Observable<SelectItem[]> {
    const baseUrl = this.baseUrl + 'entity/creditors';
    return this.http.get<SelectItem[]>(baseUrl);
  }

  getCredebtors(): Observable<SelectItem[]> {
    const baseUrl = this.baseUrl + 'entity/credebtors';
    return this.http.get<SelectItem[]>(baseUrl);
  }

  getLeasees(): Observable<SelectItem[]> {
    const baseUrl = this.baseUrl + 'entity/leasees';
    return this.http.get<SelectItem[]>(baseUrl);
  }
  getTrustees(): Observable<SelectItem[]> {
    const baseUrl = this.baseUrl + 'entity/trustees';
    return this.http.get<SelectItem[]>(baseUrl);
  }

  getEtrFilterDistinctValues(): Observable<DistinctValuesResponse> {
    return this.http.get<DistinctValuesResponse>(`${this.baseUrl}etr/filter-distinct-values`);
  }

  private setupRequestHandler(endpoint: string, subject: Subject<any>) {
    this.requestTrigger$
      .pipe(
        switchMap((params) => {
          if (params === null) return of([]);
          return this.http.get(`${this.baseUrl}${endpoint}?`, { params: this.queryCleaner(params) }).pipe(
            catchError((error) => {
              subject.error(error);
              return of(null);
            })
          );
        }),
        share(),
      )
      .subscribe(subject);
  }

  private setupRequestHandlerByDebtor(endpoint: string, subject: Subject<any>) {
    this.requestTriggerByDebtor$
      .pipe(
        switchMap((params) => {
          if (params === null) return of([]);
          return this.http.get(`${this.baseUrl}${endpoint}?`, { params: this.queryCleaner(params) }).pipe(
            catchError((error) => {
              subject.error(error);
              return of(null);
            }),
          );
        }),
        share(),
      )
      .subscribe(subject);
  }
}

import { ColDef } from '@ag-grid-community/core';
import { dateFilterParams } from '../../../../shared/helpers/date-comperator';
import { PercentValueFormatter4 } from '../../../../shared/helpers/percent-value-formatter';
import { numberValueFormatter2 } from '../../../../shared/helpers/number-value-formatter';
import { SummaryKeyFxrate } from '../../../../shared/enums/common.enum';
import { CustomPinnedRowRendererComponent } from '../../../../shared/components/custom-pinned-row';
import { FxrateComponent } from '../../fxrate.component';
import { ShowCellRendererComponent } from '../../show-cell-renderer.component';
import { AdvancedNumberValueFormatter } from '../../../../shared/helpers/number-formatter.helper';
import { CustomTooltipComponent } from '../../../../shared/components/custom-tooltip.component';
import { CustomTooltipComponent as CustomTooltip } from '../../components/custom-tooltip.component';

export const COL_DEFS: (component: FxrateComponent) => ColDef[] = (component) => [
  {
    headerName: 'No',
    field: 'no',
    width: 40,
    colId: 'no',
    hide: false,
    suppressMovable: true,
    filterParams: {
      maxNumConditions: 1,
    },
    sortable: false,
    floatingFilter: false,
    valueFormatter: (params) => {
      const originalValue = params.node?.rowPinned;
      if (originalValue) {
        return '';
      }
      return params.value;
    },
  },
  {
    headerName: 'Date',
    headerTooltip: 'Date',
    field: 'date',
    filter: 'agDateColumnFilter',
    colId: 'date',
    filterParams: dateFilterParams,
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    width: 80,
    floatingFilter: true,
    cellClass: 'ag-center-aligned-cell',
    cellRendererSelector: cellRendererSelector(component, SummaryKeyFxrate.BuyRate),
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'date',
  },
  {
    headerName: 'Demand',
    headerTooltip: 'Demand',
    field: 'demand',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'demand',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Fixed',
    headerTooltip: 'Fixed',
    field: 'fixed',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'fixed',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },

    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Term',
    headerTooltip: 'Term',
    field: 'term',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'term',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Buy Rate',
    headerTooltip: 'Buy Rate',
    field: 'buyRate',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'buyRate',
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'GBP',
    headerTooltip: 'GBP',
    field: 'gbp',
    width: 55,
    colId: 'gbp',
    filter: 'agNumberColumnFilter',
    cellClass: 'ag-right-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: numberValueFormatter2,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'USD',
    headerTooltip: 'USD',
    field: 'usd',
    width: 55,
    colId: 'usd',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (params.data.bank !== params.value) {
        return 'ag-right-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: numberValueFormatter2,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'NGN',
    headerTooltip: 'NGN',
    field: 'ngn',
    width: 55,
    colId: 'ngn',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (params.data.bank !== params.value) {
        return 'ag-right-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: numberValueFormatter2,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'US10Y',
    headerTooltip: 'US10Y',
    field: 'us10y',
    colId: 'us10y',
    filter: 'agNumberColumnFilter',
    width: 61,
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'US3Y',
    headerTooltip: 'US3Y',
    field: 'us3y',
    colId: 'us3y',
    filter: 'agNumberColumnFilter',
    width: 61,
    cellClass: 'ag-center-aligned-cell',
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'EURIBOR',
    headerTooltip: 'EURIBOR',
    field: 'eonia',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'eonia',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'LIBOR',
    headerTooltip: 'LIBOR',
    field: 'libor',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'libor',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'SOFR',
    headerTooltip: 'SOFR',
    field: 'obfr',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'obfr',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Demand Upfront',
    headerTooltip: 'Demand Upfront',
    field: 'demandUpfront',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'demandUpfront',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Demand Trailing',
    headerTooltip: 'Demand Trailing',
    field: 'demandTrailing',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'demandTrailing',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Demand Quarters',
    headerTooltip: 'Demand Quarters',
    field: 'demandQuarters',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'demandQuarters',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Fixed Upfront',
    headerTooltip: 'Fixed Upfront',
    field: 'fixedUpfront',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'fixedUpfront',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Fixed Trailing',
    headerTooltip: 'Fixed Trailing',
    field: 'fixedTrailing',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'fixedTrailing',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Fixed Quarters',
    headerTooltip: 'Fixed Quarters',
    field: 'fixedQuarters',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'fixedQuarters',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Term Upfront',
    headerTooltip: 'Term Upfront',
    field: 'termUpfront',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'demand',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Term Trailing',
    headerTooltip: 'Term Trailing',
    field: 'termTrailing',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'termTrailing',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: 'Term Quarters',
    headerTooltip: 'Term Quarters',
    field: 'termQuarters',
    filter: 'agNumberColumnFilter',
    width: 61,
    colId: 'termQuarters',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    hide: true,
  },
  {
    headerName: '',
    field: '',
    filter: false,
    width: 10,
    colId: '',
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    suppressMenu: false,
    cellStyle: { 'background-color': 'grey' },
    lockVisible: true,
  },
  {
    headerName: 'EUR',
    headerTooltip: 'EUR',
    field: 'bankEur',
    width: 62,
    colId: 'bankEur',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (!params.data.bankEur) {
        return 'ag-center-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    tooltipValueGetter: (params) => {
      return formatStringToHTML(params, 'bankEurDetails');
    },
    tooltipComponentParams: {
      color: 'black',
    },
    tooltipComponent: CustomTooltip,
  },
  {
    headerName: 'GBP',
    headerTooltip: 'GBP',
    field: 'bankGbp',
    width: 62,
    colId: 'bankGbp',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (!params.data.bankGbp) {
        return 'ag-center-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    tooltipValueGetter: (params) => {
      return formatStringToHTML(params, 'bankGbpDetails');
    },
    tooltipComponentParams: {
      color: 'black',
    },
    tooltipComponent: CustomTooltip,
  },
  {
    headerName: 'USD',
    headerTooltip: 'USD',
    field: 'bankUsd',
    width: 62,
    colId: 'bankUsd',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (!params.data.bankUsd) {
        return 'ag-center-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    tooltipValueGetter: (params) => {
      return formatStringToHTML(params, 'bankUsdDetails');
    },
    tooltipComponentParams: {
      color: 'black',
    },
    tooltipComponent: CustomTooltip,
  },
  {
    headerName: 'EUR Total',
    headerTooltip: 'EUR Total',
    field: 'bankEurTotal',
    width: 62,
    colId: 'bankEurTotal',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (!params.data.bankEurTotal) {
        return 'ag-center-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'bankEurTotal',
  },
  {
    field: 'Action',
    headerTooltip: 'Action',
    pinned: 'right',
    cellRenderer: ShowCellRendererComponent,
    cellDataType: false,
    width: 40,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    suppressMovable: true,
    sortable: false,
  },
];

function cellRendererSelector(component: FxrateComponent, column: SummaryKeyFxrate) {
  return (params: any) => {
    if (params.node.rowPinned) {
      return {
        component: CustomPinnedRowRendererComponent,
        params: {
          setTotal: component.changePinnedRowType,
          column,
          value: params.node.value,
          values: [
            // { id: 'sum', name: 'Sum' },
            { id: 'avg', name: 'Average' },
            { id: 'max', name: 'Maximum' },
            { id: 'min', name: 'Minimum' },
          ],
        },
      };
    } else {
      return undefined;
    }
  };
}

export const toolTipValueGetter = (params: any, field: string) => {
  return params.data?.[field]
    ?.split('\n')
    .map((line: any) => line.trim())
    .join('\n');
};

export const formatString = (input: any, field: string) => {
  // Remove underscores and normalize spaces
  let cleanedString = input.data?.[field]?.replace(/_/g, '').trim();

  // Ensure each entry starts on a new line
  cleanedString = cleanedString?.replace(/(\n|\r)+/g, '\n'); // Normalize line breaks
  cleanedString = cleanedString?.replace(/ from /g, ' from '); // Maintain "from" formatting
  return cleanedString;
};

export const formatStringToHTML = (input: any, field: string) => {
  // Remove underscores and normalize spaces
  let cleanedString = input.data?.[field]?.replace(/_/g, '').trim();

  // Ensure each entry starts on a new line
  cleanedString = cleanedString?.replace(/(\n|\r)+/g, '\n'); // Normalize line breaks

  // Split the string into lines and wrap each in a <p> tag
  const lines = cleanedString?.split('\n').map((line: any) => `<p>${line.trim()}</p>`);

  // Join the lines back into a single string
  return lines?.join('\n');
};

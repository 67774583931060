<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- First level menu -->
    <li
      *ngFor="let sidebarnavItem of sidebarnavItems"
      class="sidebar-item"
      routerLinkActive="active"
    >
      <a
        *ngIf="!sidebarnavItem.extralink"
        class="sidebar-link waves-effect waves-dark"
        routerLinkActive="active"
        [routerLink]="(!!sidebarnavItem.path && !sidebarnavItem.isNeedBlockNavigate) ? [sidebarnavItem.path] : null"
        [class.has-arrow]="sidebarnavItem.class === 'collapse'"
        [ngClass]="[sidebarnavItem.class]"
        (click)="addExpandClass(sidebarnavItem.title)"
      >
        <!--<i [ngClass]="[sidebarnavItem.icon]"></i>-->
        <img alt="" src="{{ sidebarnavItem.image }}" class="menu-image" />
        <span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </a>

      <ul aria-expanded="false" class="collapse first-level" [class.in]="showMenu === sidebarnavItem.title">
        <li *ngFor="let submenu of sidebarnavItem.submenu" class="sidebar-item">
          <a
            class="sidebar-link"
            routerLinkActive="active"
            [routerLink]="(!!submenu.path && !submenu.isNeedBlockNavigate) ? [submenu.path] : null"
            (click)="addExpandSubClass(submenu)"
          >
            <img alt="" src="{{ submenu.image }}" class="menu-image" />
            <span class="hide-menu"> {{ submenu.title }} </span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>

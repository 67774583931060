import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvestmentsSharedService {
  private statusSource = new BehaviorSubject<boolean>(false); // Default value is false
  status$ = this.statusSource.asObservable();

  sendStatus(status: boolean): void {
    this.statusSource.next(status);
  }

  getStatus() {
    return this.statusSource.asObservable();
  }
}
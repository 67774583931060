import { Component, OnInit } from '@angular/core';
import { AgCartesianChartOptions } from 'ag-charts-enterprise';
import { SelectItem } from '../../shared/interfaces/select-item.interface';
import { InvestmentsHttpService } from '../../investments/common/services/investments-http.service';
import { getFormData } from '../../shared/helpers/utils';

@Component({
  selector: 'app-investments-charts',
  templateUrl: './investments-charts.component.html',
  styleUrls: ['./investments-charts.component.scss'],
})
export class InvestmentsChartsComponent implements OnInit {
  isLoading = false;
  multipleLineOptions: AgCartesianChartOptions | any;
  queryParamsChart: any = {
    period: 'quarter',
    dateRange: 'all',
  };
  defaultQueryParamsChart = { ...this.queryParamsChart };
  periods: Array<SelectItem> = [
    { id: 'week', name: 'Week' },
    { id: 'month', name: 'Month' },
    { id: 'quarter', name: 'Quarter' },
    { id: 'year', name: 'Year' },
  ];
  selectedTimePeriod = '';

  intermediaryIdList: { id: string; name: string }[];
  investorIdListSnapshot: {
    id: string;
    name: string;
    intermediary: { id: string; name: string };
  }[];
  intermidiaryListSnapshot: { id: string; name: string }[];
  investorGeneratedList: {
    id: string;
    name: string;
    intermediary: { id: string; name: string };
  }[] = [];

  constructor(private investmentsHttpService: InvestmentsHttpService) {}

  ngOnInit() {
    this.initMultipleLineOptions();
    this.getInvestmentFiltersData();
    setTimeout(() => {
      this.getChartData();
    }, 300);
  }

  private getInvestmentFiltersData(): void {
    this.investmentsHttpService.getIntermediariesWithInvestors().subscribe((intermediariesWithInvestors) => {
      this.intermediaryIdList = [
        ...(intermediariesWithInvestors.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
          nameForSearch: `${value.id}-${value.name.toLowerCase()}`,
          investors: value.investors,
        })) || []),
      ];
      this.intermediaryIdList.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.intermidiaryListSnapshot = this.intermediaryIdList;

      intermediariesWithInvestors.forEach((currentValue: any) => {
        currentValue.investors.forEach((item: any) => {
          item.nameForSearch = `${item.id}-${item.name.toLowerCase()}`;
          item.name = `${item.id}-${item.name}`;
          item.intermediary = { name: currentValue.name, id: currentValue.id };
        });
        this.investorGeneratedList = [...this.investorGeneratedList, ...currentValue.investors];
        this.investorGeneratedList.sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      });

      this.investorIdListSnapshot = [...this.investorGeneratedList];
    });
  }

  initMultipleLineOptions() {
    this.multipleLineOptions = {
      zoom: {
        enabled: true,
        scrollingStep: 0.4,
        axes: 'xy',
        enableSelecting: true,
      },
      series: [
        { type: 'bar', stacked: true, xKey: 'quarter', yKey: 'demand', yName: 'Demand'},
        { type: 'bar', stacked: true, xKey: 'quarter', yKey: 'fixed', yName: 'Fixed'},
        { type: 'bar', stacked: true, xKey: 'quarter', yKey: 'term', yName: 'Term'},
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: { rotation: -90 },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: (params: any) => this.leftLabelValueFormatter(Number(params.value)),
          },
          gridLine: {
            style: [
              {
                stroke: 'rgba(0, 0, 0, 0.5)',
                lineDash: [0],
              },
            ],
          },
          min: 0,
          max: 1.0e8,
        },
      ],
      legend: {
        position: 'right',
        enabled: false,
      },
    };
  }

  handleIntermediaryChange(event: any): void {
    if (!event) {
      this.intermediaryIdList = this.intermidiaryListSnapshot;
      this.investorGeneratedList = this.investorIdListSnapshot;
    } else {
      this.investorGeneratedList = event?.investors || this.investorIdListSnapshot;
    }
    this.onChangeQueryParam('intermediaryId', event);
  }

  investorChange(event: any) {
    if (!event) {
      this.investorGeneratedList = this.investorIdListSnapshot;
      this.intermediaryIdList = this.intermidiaryListSnapshot;
    } else {
      this.intermediaryIdList = event ? [event.intermediary] : this.intermidiaryListSnapshot;
    }
    this.onChangeQueryParam('investorId', event);
  }

  ngSelectCustomSearch(term: string, item: any): any {
    return item.nameForSearch.indexOf(term.toLowerCase()) > -1;
  }

  getChartData() {
    this.isLoading = true;
    this.investmentsHttpService.getInvestmentsChartData(getFormData(this.queryParamsChart, false)).subscribe((value: any) => {
      const chartData = [];
      for (const key in value) {
        chartData.push({
          ...value[key],
          quarter: key,
        });
      }

      const minMaxValue = this.detectMinMaxNumbers(chartData);
      this.multipleLineOptions.axes[1].max = minMaxValue.max;
      this.multipleLineOptions.axes[1].min = minMaxValue.min;
      const yKeys = ['demand', 'fixed', 'term'];
      const trendlineData = this.calculateCombinedTrendline(chartData, yKeys);
      const existingTrendlineIndex = this.multipleLineOptions.series.findIndex((s: any) => s.yKey === 'trendValue');
      /*if (existingTrendlineIndex >= 0) {
        this.multipleLineOptions.series[existingTrendlineIndex] = {
          type: 'line',
          xKey: 'quarter',
          yKey: 'trendValue',
          data: trendlineData,
          yName: 'Trend Line',
          stroke: 'rgba(0, 0, 0, 0.5)',
          marker: { enabled: false },
        };
      } else {
        this.multipleLineOptions.series.push({
          type: 'line',
          xKey: 'quarter',
          yKey: 'trendValue',
          data: trendlineData,
          yName: 'Trend Line',
          stroke: 'rgba(0, 0, 0, 0.5)',
          marker: { enabled: false },
        });
      }*/
      this.multipleLineOptions = {
        ...this.multipleLineOptions,
        data: chartData,
      };
      this.isLoading = false;
    });
  }

  private detectBigNumbersAllFields(data: any) {
    let biggestNumber: any = null;

    // Loop through each record and each field to find the biggest number
    data.forEach((record: any) => {
      Object.values(record).forEach((value) => {
        if (typeof value === 'number') {
          if (biggestNumber === null || value > biggestNumber) {
            biggestNumber = value;
          }
        }
      });
    });

    return biggestNumber;
  }

  private detectMinMaxNumbers(data: any) {
    let minNumber: any = null;
    let maxNumber: any = null;

    // Loop through each record and each field to find the min and max numbers
    data.forEach((record: any) => {
      Object.values(record).forEach((value) => {
        if (typeof value === 'number') {
          if (minNumber === null || value < minNumber) {
            minNumber = value;
          }
          if (maxNumber === null || value > maxNumber) {
            maxNumber = value;
          }
        }
      });
    });

    return { min: minNumber, max: maxNumber };
  }

  private detectMinMaxNumbersObject(data: any) {
    let minNumber: number | null = null;
    let maxNumber: number | null = null;

    Object.keys(data).forEach((quarter) => {
      const record = data[quarter];

      Object.values(record).forEach((value) => {
        if (typeof value === 'number') {
          if (minNumber === null || value < minNumber) {
            minNumber = value;
          }
          if (maxNumber === null || value > maxNumber) {
            maxNumber = value;
          }
        }
      });
    });

    return { min: minNumber, max: maxNumber };
  }

  calculateCombinedTrendline(data: any[], yKeys: string[]): any[] {
    const n = data.length;
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumX2 = 0;
    data.forEach((d, i) => {
      sumX += i;
      let combinedY = 0;
      yKeys.forEach((yKey) => {
        combinedY += d[yKey];
      });

      sumY += combinedY;
      sumXY += i * combinedY;
      sumX2 += i * i;
    });
    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;
    return data.map((d, i) => ({
      quarter: d.quarter,
      trendValue: parseFloat((slope * i + intercept).toFixed(2)),
    }));
  }

  onSelectTimePeriod(dateRange: string) {
    this.selectedTimePeriod = this.queryParamsChart.dateRange = dateRange;
    this.getChartData();
  }

  onChangeQueryParam(key: string, value: any) {
    this.queryParamsChart[key] = value ? value.id : this.defaultQueryParamsChart[key]
    this.getChartData();
  }

  leftLabelValueFormatter = (value: number) => {
    const absValue = Math.abs(value);
    const decimal = 1;
    const sign = value < 0 ? '-' : '';
    if (absValue >= 1.0e9) {
      return sign + (absValue / 1.0e9).toFixed(decimal) + ' b';
    } else if (absValue >= 1.0e6) {
      return sign +(absValue / 1.0e6).toFixed(decimal) + ' m';
    } else if (absValue >= 1.0e3) {
      return sign +(absValue / 1.0e3).toFixed(decimal) + ' k';
    } else {
      return sign + absValue.toFixed(decimal);
    }
  };

  leftLabelValueFormatterContainsNegative = (value: number) => {
    const absValue = Math.abs(value);
    const decimal = 1;
    let formattedValue = '';

    if (absValue >= 1.0e9) {
      formattedValue = (absValue / 1.0e9).toFixed(decimal) + ' b';
    } else if (absValue >= 1.0e6) {
      formattedValue = (absValue / 1.0e6).toFixed(decimal) + ' m';
    } else if (absValue >= 1.0e3) {
      formattedValue = (absValue / 1.0e3).toFixed(decimal) + ' k';
    } else {
      formattedValue = absValue.toFixed(decimal);
    }

    // Reapply the negative sign if the original value was negative
    return value < 0 ? '-' + formattedValue : formattedValue;
  };
}

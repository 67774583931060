import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private isShowSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get showSpinner(): Observable<boolean> {
    return this.isShowSpinnerSubject
  }

   setShowSpinner(value: boolean) {
    this.isShowSpinnerSubject.next(value)
  }
}

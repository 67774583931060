import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { UploadsComponent } from 'src/app/uploads/uploads/uploads.component';
import { SelectItem } from '../interfaces/select-item.interface';
import { BankUpdateService } from '../services/bank-update.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, CommonModule, NgIf],
  templateUrl: './sidebar.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  isClassAdded = false;
  public sidebarnavItems: RouteInfo[] = [];
  uploadTypes: SelectItem[] = [
    { id: '1', name: 'Investments' },
    { id: '2', name: 'ETR' },
    { id: '3', name: 'Bank' },
  ];

  @ViewChild('projctModal') projctModal: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private up: UploadsComponent,
    private bankUpdateService: BankUpdateService,
  ) {}

  openModal() {
    this.projctModal.show();
  }

  callModal(uploadType: string) {
    const selectedType = this.uploadTypes.find((type) => type.name === uploadType);
    const uploadTypeId = selectedType ? selectedType.id : '0';
    const modalRef = this.modalService.open(UploadsComponent, {
      backdrop: true,
      windowClass: 'data-entry-custom-model',
    });
    modalRef.componentInstance.uploadTypeId = Number(uploadTypeId);
  }

  // this is for the open close
  addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.showSubMenu = '0';
    } else {
      this.showMenu = element;
      this.showSubMenu = '0';
    }
  }

  addExpandSubClass(element: any) {
    if (element.title === this.showSubMenu) {
      this.showSubMenu = '0';
    }
    if (element.title === 'Update Bank') {
      this.bankUpdate();
      return;
    } else if ((!element.path && element.title === 'Investments') || element.title === 'ETR' || element.title === 'Bank') {
      this.callModal(element.title);
    } else {
      this.showSubMenu = element.title;
    }
  }

  bankUpdate(): void {
    Swal.fire({
      icon: 'info',
      // text: 'This process will take approximately 5 minutes to complete.',
      text: 'The process of transactions identification has started, it might take up to 5 minutes.',
      showConfirmButton: false,
      timer: 5000,
    });
    this.bankUpdateService.generateBankAmounts()
      .subscribe();
    this.bankUpdateService.transactionIdentification()
      .subscribe();
  }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter((sidebarnavItem) => sidebarnavItem);
  }
}

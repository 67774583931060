import { environment } from '../../../environments/environment';
import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';

environment;
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    navigateToLoginRequestUrl: true,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    navigateFrameWait: 0,
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

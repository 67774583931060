import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { InvestmentsFormProvider } from '../investments-form-provider';
import { Investment } from 'src/app/shared/interfaces/investments-response.interface';
import {
  ConvertISONumberToNormal,
  DateFilterParamsSettings,
  getFormData,
} from '../../shared/helpers/utils';
import { InvestmentsHttpService } from '../common/services/investments-http.service';
import { FxrateHttpService } from '../../crystalreports/fxrate/common/services/fxrate-http.service';
import { FxrateQueryParamsInterface } from '../../crystalreports/fxrate/common/interfaces/fxrate-query-params.interface';
import { debounceTime, filter } from 'rxjs';
import { FormatWithCommasService } from 'src/app/shared/services/format-with-commas.services';
import { SimpleNumberValueFormatter } from 'src/app/shared/helpers/number-formatter.helper';
import { PercentFormatter } from '../../shared/helpers/percent-formatter.helper';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-investments',
  templateUrl: './add-edit-investments.component.html',
  styleUrls: ['./add-edit-investments.component.scss'],
})
export class AddEditInvestmentsComponent implements OnInit {
  investmentForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    bankDate: 'Bank Date',
    bankTransactionType: 'Bank Transaction Type',
    bankReceipt: 'Bank Receipt',
    bankPayment: 'Bank Payment',
    intermediaryId: 'Intermediary ID',
    intermediaryName: 'Intermediary Name',
    investorId: 'Investor ID',
    investorName: 'Investor Name',
    bankTransactionDetails: 'Bank Transaction Details',
    transactionType: 'Transaction Type',
    ccy: 'Currency',
    date: 'Date',
    faceValue: 'Face Value',
    buyRate: 'Buy Rate',
    commission: 'Commission',
    months: 'Months',
    type: 'Type',
    history: 'History',
    brand: 'Brand',
    product: 'Product',
    comments: 'Comments',
    fxRate: 'Fx Rate',
    ledger: 'Ledger',
    eurAmount: 'EUR Amount',
    trustee: 'Trustee',
  };
  isSubmitted = false;
  bankTransactionType: { id: string; name: string }[];
  transactionType: { id: string; name: string }[];
  ccy: { id: string; name: string }[];
  type: { id: string; name: string }[];
  history: { id: string; name: string }[];
  brand: { id: string; name: string }[];
  product: { id: string; name: string }[];
  trustees: { id: string; name: string }[];
  ledgerData: { id: string; name: string }[];
  intermediaryIdList: { id: string; name: string }[];
  investorIdListSnapshot: {
    id: string;
    name: string;
    intermediary: { id: string; name: string };
  }[];
  intermidiaryListSnapshot: { id: string; name: string }[];
  investorGeneratedList: {
    id: string;
    name: string;
    intermediary: { id: string; name: string };
  }[] = [];
  selectedIntermediary: any;
  isNew: boolean;
  savings: Array<string> = ['Deposit', 'Pension'];
  model = { option: 'Online' };
  savingModel = { option: 'Deposit' };
  maxDate: NgbDateStruct;

  private fxRateData: any;

  @Input() investmentData: Investment;
  @Input() trusteeList: { id: string; name: string }[];
  @Input() selectedTransactionType = '';
  @Input() isUpload = false;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() createUpdateEvent = new EventEmitter<boolean>();

  constructor(
    private investmentsHttpService: InvestmentsHttpService,
    private investmentsFormProvider: InvestmentsFormProvider,
    private fxrateHttpService: FxrateHttpService,
    private formatCommasService: FormatWithCommasService,
  ) {}

  ngOnInit(): void {
    this.investmentData = this.investmentData || {};
    this.isNew = !this.investmentData?.id;
    this.investmentForm = this.investmentsFormProvider.initInvestmentForm(
      this.investmentData,
      this.isNew,
    );
    if (!this.isNew) this.onSavingChange(this.investmentData.savings || 'Deposit');
    this.changeFormValidators(this.investmentForm.value.transactionType);
    this.getInvestmentFiltersData();
    this.getEntityList();
    this.getCurrenciesData();
    if (this.selectedTransactionType)
      this.investmentForm.get('transactionType')?.patchValue(this.selectedTransactionType);
    /* if (this.investmentData?.id) {
      this.getFxRateByDate(this.investmentData.date);
    }*/
    this.subscribeToDateChange();
    this.subscribeToCcyChange();
    this.subscribeToAmountChange();
    // this.subscribeToSellRateChange();
    // this.subscribeToCommissionChange();
    this.subscribeToTransactionChange();
    this.maxDate = this.getCurrentDateAsNgbDateStruct();
  }

  private getEntityList(): void {
    this.investmentsHttpService
      .getIntermediariesWithInvestors()
      .subscribe((intermediariesWithInvestors) => {
        this.intermediaryIdList = [
          ...(intermediariesWithInvestors.map((value: any) => ({
            id: value.id,
            name: `${value.id}-${value.name}`,
            nameForSearch: `${value.id}-${value.name.toLowerCase()}`,
            investors: value.investors,
          })) || []),
        ];
        this.intermediaryIdList.sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.intermidiaryListSnapshot = this.intermediaryIdList;

        intermediariesWithInvestors.forEach((currentValue: any) => {
          currentValue.investors.forEach((item: any) => {
            item.nameForSearch = `${item.id}-${item.name.toLowerCase()}`;
            item.name = `${item.id}-${item.name}`;
            item.intermediary = { name: currentValue.name, id: currentValue.id };
          });
          this.investorGeneratedList = [...this.investorGeneratedList, ...currentValue.investors];
          this.investorGeneratedList.sort((a: any, b: any) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        });

        this.investorIdListSnapshot = [...this.investorGeneratedList];
      });
  }

  private subscribeToTransactionChange(): void {
    this.investmentForm.get('transactionType')?.valueChanges.subscribe((value) => {
      this.changeFormValidators(value);
    });
  }

  changeFormValidators(type: 'Receipt' | 'Adjustment' | 'Redemption' | 'Renewal'): void {
    const controls = ['commission', 'months', 'ledgerId', 'ccy', 'buyRate', 'faceValue'];

    controls.forEach((control) => {
      const formControl = this.investmentForm.get(control);
      if (!formControl) return;

      if (type === 'Receipt' || type === 'Renewal') {
        if (control === 'commission') {
          formControl.addValidators([Validators.max(100), Validators.min(0)]);
        }
        if (control === 'buyRate') {
          formControl.addValidators([Validators.min(0.0001), Validators.max(99.99)]);
        }
        formControl.addValidators([Validators.required]);

        if (type === 'Renewal' && control === 'faceValue') {
          formControl.clearValidators();
        }
      } else {
        formControl.clearValidators();
      }
      formControl.updateValueAndValidity();
    });
  }

  getCurrentDateAsNgbDateStruct(): NgbDateStruct {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getDate() + 7 > 31 ? now.getMonth() + 2 : now.getMonth() + 1, // Note: Months are 0-based in JavaScript
      day: now.getDate() + 7 > 31 ? 7 : now.getDate() + 7 ,
    };
  }

  private subscribeToDateChange(): void {
    this.investmentForm
      .get('date')
      ?.valueChanges.pipe(filter((data) => !!data))
      .subscribe((date) => {
        const dateMoment = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD');
        // Formatting the date in 'YYYY-MM-DD' format
        const formattedDate = dateMoment.format('YYYY-MM-DD');
        if (formattedDate === 'Invalid date') return;
        this.getFxRateByDate(formattedDate);
      });
  }

  private subscribeToAmountChange(): void {
    this.investmentForm
      .get('faceValue')
      ?.valueChanges.pipe(debounceTime(100))
      .subscribe((faceValue) => {
        this.generateEURAmount();
      });
  }

  private subscribeToSellRateChange(): void {
    this.investmentForm
      .get('buyRate')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe((value) => {
        const formattedValue = PercentFormatter(+value, false);
        this.investmentForm.get('buyRate')?.patchValue(formattedValue, { emitEvent: false });
      });
  }

  private subscribeToCommissionChange(): void {
    if (+this.investmentForm.value.commission === 0) {
      this.investmentForm.get('commission')?.patchValue('0.000');
    }
    this.investmentForm
      .get('commission')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe((value) => {
        const formattedValue = PercentFormatter(value, false);
        this.investmentForm
          .get('commission')
          ?.patchValue(+formattedValue === 0 ? '0.000' : formattedValue, { emitEvent: false });
      });
  }

  handleIntermediaryChange(event: any): void {
    if (!event) {
      this.intermediaryIdList = this.intermidiaryListSnapshot;
      this.investorGeneratedList = this.investorIdListSnapshot;
    } else {
      this.investorGeneratedList = event?.investors || this.investorIdListSnapshot;
    }
  }

  private subscribeToCcyChange(): void {
    this.investmentForm.get('ccy')?.valueChanges.subscribe((ccy) => {
      this.addFxRateData(ccy);
      this.generateEURAmount();
    });
  }

  private addFxRateData(ccy: string): void {
    switch (ccy) {
      case 'EUR':
        this.investmentForm.get('fxRate')?.patchValue((1).toFixed(4));
        break;
      case 'USD':
        this.investmentForm
          .get('fxRate')
          ?.patchValue(this.fxRateData?.usd ? this.fxRateData.usd.toFixed(4) : (1).toFixed(4));
        break;
      case 'GBP':
        this.investmentForm
          .get('fxRate')
          ?.patchValue(this.fxRateData?.gbp ? this.fxRateData.gbp.toFixed(4) : (1).toFixed(4));
        break;
    }
  }

  private generateEURAmount(): void {
    const fxRate = ConvertISONumberToNormal(this.investmentForm.value.fxRate) || 1;
    const faceValue = ConvertISONumberToNormal(this.investmentForm.value.faceValue) || 0;
    const eurAmount: any = SimpleNumberValueFormatter(fxRate * faceValue);
    this.investmentForm.get('eurAmount')?.patchValue(eurAmount);
  }

  private getFxRateByDate(formattedDate: string): void {
    const date = {
      dateFrom: formattedDate,
      filterType: 'date',
      type: 'equals',
    };
    const filter: Partial<FxrateQueryParamsInterface> = DateFilterParamsSettings(date, 'date');
    this.fxrateHttpService.getFxRateData(filter).subscribe((response) => {
      this.fxRateData = response.items[0];
      if (this.investmentForm.value.ccy) this.addFxRateData(this.investmentForm.value.ccy);
      this.generateEURAmount();
    });
  }

  onSubmit() {
    const fieldsToFormat = ['bankDate', 'date'];

    fieldsToFormat.forEach((field) => {
      if (this.investmentForm.value[field]) {
        if (field === 'months') {
          this.investmentForm.value[field] = moment(this.investmentForm.value[field]).format('MM');
        } else {
          let formattedDate: string;
          const date = this.investmentForm.value[field];
          if (typeof date === 'string') {
            formattedDate = moment(date).format('YYYY-MM-DD');
          } else {
            // Creating a Moment.js object with the provided date
            const dateMoment = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD');
            // Formatting the date in 'YYYY-MM-DD' format
            formattedDate = dateMoment.format('YYYY-MM-DD');
          }
          if (this.investmentForm.value[field]) {
            this.investmentForm.value[field] = formattedDate;
          }
        }
      }
    });

    this.isSubmitted = true;

    if (this.investmentForm.invalid) {
      return;
    }
    let rawData = this.investmentForm.value;
    delete rawData.id;
    rawData = getFormData(rawData);
    if (rawData.savings == 'Deposit') {
      delete rawData.trusteeId;
    }
    if (Object.prototype.hasOwnProperty.call(rawData, 'commission')) {
      rawData['commission'] = rawData['commission'] / 100;
    }
    if (Object.prototype.hasOwnProperty.call(rawData, 'buyRate')) {
      rawData['buyRate'] = rawData['buyRate'] / 100;
    }

    rawData.faceValue = ConvertISONumberToNormal(rawData.faceValue);

    let requestData: Partial<Investment> = {};
    if (rawData.transactionType === 'Redemption') {
      requestData = {
        intermediaryId: rawData.intermediaryId,
        investorId: rawData.investorId,
        product: rawData.product,
        transactionType: rawData.transactionType,
        faceValue: rawData.faceValue,
        date: rawData.date,
        type: rawData.type,
        comments: rawData.comments,
        savings: rawData.savings,
        ccy: rawData.ccy,
        ...(rawData.trusteeId && { trusteeId: rawData.trusteeId }),
      };
    } else if (rawData.transactionType === 'Adjustment') {
      requestData = {
        intermediaryId: rawData.intermediaryId,
        investorId: rawData.investorId,
        product: rawData.product,
        transactionType: rawData.transactionType,
        faceValue: rawData.faceValue,
        date: rawData.date,
        type: rawData.type,
        comments: rawData.comments,
        savings: rawData.savings,
        ccy: rawData.ccy,
        ...(rawData.trusteeId && { trusteeId: rawData.trusteeId }),
      };
    } else {
      requestData = rawData;
      if (rawData.transactionType === 'Renewal') {
        delete requestData.faceValue;
      }
    }

    this.investmentData?.id ? this.doUpdate(requestData) : this.doCreate(requestData);
  }

  doCreate(data: any): void {
    delete data['fxRate'];
    delete data['eurAmount'];
    this.investmentsHttpService.postData('investments', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.investmentForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investment successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
        this.createUpdateEvent.emit(true);
      },
    });
  }

  doUpdate(data: any): void {
    delete data['fxRate'];
    delete data['eurAmount'];
    this.investmentsHttpService
      .updateDataV2('investments', this.investmentData.id, data)
      .subscribe({
        next: (response: any) => {
          this.closeModal();
          Swal.fire({
            icon: 'success',
            text: 'Investment successfully updated.',
            showConfirmButton: false,
            timer: 5000,
          });
          this.investmentForm.reset();
          this.createUpdateEvent.emit(true);
        },
      });
  }

  private getInvestmentFiltersData(): void {
    this.investmentsHttpService.getInvestmentsFilterData().subscribe((investmentsFilterData) => {
      this.ledgerData = [
        ...(investmentsFilterData.ledgers.map((ledger) => ({
          id: ledger.id,
          name: ledger.name,
        })) || []),
      ];

      /* this.bankTransactionType = [
        ...(investmentsFilterData.bankTransactionType.map((bankTransactionType) => ({
          id: bankTransactionType,
          name: bankTransactionType,
        })) || []),
      ];*/

      this.transactionType = [
        ...(investmentsFilterData.transactionTypes.map((transactionType) => ({
          id: transactionType,
          name: transactionType,
        })) || []),
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.type = [
        ...(investmentsFilterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];

      this.history = [
        ...(investmentsFilterData.histories.map((history) => ({
          id: history,
          name: history,
        })) || []),
      ];

      this.brand = [
        ...(investmentsFilterData.brands.map((brand) => ({
          id: brand,
          name: brand,
        })) || []),
      ];

      this.product = [
        ...(investmentsFilterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];
    });
  }

  private getCurrenciesData(): void {
    this.investmentsHttpService.getCurrencyFilterData().subscribe((currencyFilterData) => {
      this.ccy = [
        ...(currencyFilterData.map((currencyFilterData) => ({
          id: currencyFilterData,
          name: currencyFilterData,
        })) || []),
      ];
    });
  }

  closeModal() {
    this.isSubmitted = false;
    this.closeDialogEvent.emit(false);
  }

  investorChange(event: any) {
    if (!event) {
      this.investorGeneratedList = this.investorIdListSnapshot;
      this.intermediaryIdList = this.intermidiaryListSnapshot;
    } else {
      this.intermediaryIdList = event ? [event.intermediary] : this.intermidiaryListSnapshot;
      if (event) {
        this.investmentForm.get('intermediaryId')?.patchValue(event.intermediary.id);
      }
    }
  }

  ngSelectCustomSearch(term: string, item: any): any {
    return item.nameForSearch.indexOf(term.toLowerCase()) > -1;
  }

  onSavingChange(value: string): void {
    this.savingModel.option = value;
    this.investmentForm.get('savings')?.setValue(value);
    if (value === 'Pension') {
      this.investmentForm.get('trusteeId')?.addValidators([Validators.required]);
      this.investmentForm.get('trusteeId')?.updateValueAndValidity();
    } else {
      this.investmentForm.get('trusteeId')?.removeValidators([Validators.required]);
      this.investmentForm.get('trusteeId')?.updateValueAndValidity();
    }
  }

  handleFocusFaceValue(): void {
    this.investmentForm
      .get('faceValue')
      ?.patchValue(ConvertISONumberToNormal(this.investmentForm.value.faceValue));
  }

  handleFocusOutFaceValue(): void {
    this.investmentForm
      .get('faceValue')
      ?.patchValue(SimpleNumberValueFormatter(this.investmentForm.value.faceValue));
  }

  formatSellRate(): void {
    const formattedValue = PercentFormatter(+this.investmentForm.value.buyRate, false);
    this.investmentForm
      .get('buyRate')
      ?.patchValue(
        +this.investmentForm.value.buyRate > 99.99 || +this.investmentForm.value.buyRate < 0
          ? +this.investmentForm.value.buyRate
          : formattedValue,
        { emitEvent: false },
      );
  }

  formatCommission(): void {
    const formattedValue = PercentFormatter(this.investmentForm.value.commission, false);
    this.investmentForm
      .get('commission')
      ?.patchValue(
        +this.investmentForm.value.commission > 99.99 || +this.investmentForm.value.commission < 0
          ? +this.investmentForm.value.commission
          : +formattedValue === 0
            ? '0.000'
            : formattedValue,
        { emitEvent: false },
      );
  }
}

import { ColDef } from '@ag-grid-community/core';
import { CustomSelectFilterComponent } from '../../shared/components/custom-select-filter.component';
import { dateFilterParams } from '../../shared/helpers/date-comperator';
import { CustomPinnedRowRendererComponent } from '../../shared/components/custom-pinned-row';
import { InvestmentsComponent } from './investments.component';
import { numberValueFormatter, numberValueFormatter2 } from '../../shared/helpers/number-value-formatter';
import { tooltipRenderer } from 'src/app/shared/helpers/tooltip-renderer';
import { ShowCellRendererComponent } from './show-cell-renderer.component';
import { SummaryKeyInvestments } from '../../shared/enums/common.enum';
import {
  PercentValueFormatter,
  PercentValueFormatter2,
  PercentValueFormatter4, PercentValueFormatterTest
} from "../../shared/helpers/percent-value-formatter";
import { CustomSelectSearchFilterComponent } from '../../shared/components/custom-select-search-filter/custom-select-search-filter.component';
import { CCY_SORT_ORDER, Savings_Json } from 'src/app/shared/constants/helpers.const';

export const COL_DEFS: (component: InvestmentsComponent) => ColDef[] = (component) => [
  {
    headerName: 'No',
    field: 'no',
    width: 50,
    colId: 'no',
    hide: false,
    suppressMovable: true,
    filterParams: {
      maxNumConditions: 1,
    },
    sortable: false,
    floatingFilter: false,
    valueFormatter: (params) => {
      const originalValue = params.node?.rowPinned;
      if (originalValue) {
        return '';
      }
      return params.value;
    },
  },
  {
    headerName: 'Ledger',
    field: 'ledger.entity.name',
    filter: 'agTextColumnFilter',
    width: 88,
    colId: 'ledgerName',
    hide: false,
    suppressMovable: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    suppressHeaderMenuButton: false,
    cellRenderer: tooltipRenderer,
    cellClass: (params) => [cellClassRenderer(params)],
  },
  {
    headerName: 'Product',
    field: 'product',
    filter: 'agTextColumnFilter',
    width: 45,
    colId: 'product',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-left-aligned-cell', cellClassRenderer(params)],
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    suppressMenu: false,
  },
  {
    headerName: 'Intermediary ID',
    field: 'intermediaryId',
    filter: 'agTextColumnFilter',
    width: 120,
    colId: 'intermediaryId',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-left-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectSearchFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
      disabled: false,
    },
    cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Intermediary',
    field: 'intermediary.entity.name',
    filter: 'agTextColumnFilter',
    width: 110,
    colId: 'intermediaryName',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-left-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectSearchFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
      disabled: false,
    },
  },
  {
    headerName: 'Investor ID',
    field: 'investorId',
    filter: 'agTextColumnFilter',
    width: 120,
    colId: 'investorId',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-left-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectSearchFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
      disabled: false,
    },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Investor',
    field: 'investor.entity.name',
    filter: 'agTextColumnFilter',
    width: 120,
    colId: 'investorName',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-left-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectSearchFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
      disabled: false,
    },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Date',
    field: 'date',
    filter: 'agDateColumnFilter',
    width: 105,
    colId: 'date',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-center-aligned-cell', cellClassRenderer(params)],
    floatingFilter: true,
    filterParams: dateFilterParams,
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    cellRendererSelector: cellRendererSelector(component, SummaryKeyInvestments.Buy_Rate),
  },
  {
    headerName: 'Ccy',
    field: 'ccy',
    filter: 'agTextColumnFilter',
    width: 45,
    colId: 'ccy',
    hide: false,
    suppressMovable: true,
    filterParams: {
      textMatcher: () => true,
    },

    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: CCY_SORT_ORDER.reduce((accumulator: { id: string; name: string }[], currentValue: string) => {
        accumulator.push({ id: currentValue, name: currentValue });
        return accumulator;
      }, []),
      defaultValue: '',
    },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    cellClass: (params) => [cellClassRenderer(params)],
  },
  {
    headerName: 'Face Value',
    field: 'faceValue',
    filter: 'agNumberColumnFilter',
    width: 100,
    colId: 'faceValue',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-right-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: numberValueFormatter,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Fx Rate',
    field: 'fx_rate',
    width: 65,
    colId: 'fx_rate',
    hide: false,
    cellClass: (params) => ['ag-right-aligned-cell', cellClassRenderer(params)],
    valueFormatter: numberValueFormatter2,
    sortable: false,
    suppressMovable: true,
  },
  {
    headerName: 'EUR Face Value',
    field: 'eurFaceValue',
    width: 100,
    colId: 'eurFaceValue',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-right-aligned-cell', cellClassRenderer(params)],
    valueFormatter: numberValueFormatter,
    sortable: false,
  },
  {
    headerName: 'Type',
    field: 'type',
    filter: 'agTextColumnFilter',
    width: 60,
    colId: 'type',
    hide: false,
    suppressMovable: true,
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    suppressMenu: false,
    cellClass: (params) => [cellClassRenderer(params)],
  },
  {
    headerName: 'Mos',
    field: 'months',
    filter: 'agNumberColumnFilter',
    width: 50,
    colId: 'months',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-center-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
  },
  {
    headerName: 'Buy Rate',
    field: 'buyRate',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'buyRate',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-center-aligned-cell', cellClassRenderer(params)],
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
  },
  {
    headerName: 'Upfront',
    field: 'upfrontRate',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'upfrontRate',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-center-aligned-cell', cellClassRenderer(params)],
    valueFormatter: PercentValueFormatterTest,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
  },
  {
    headerName: 'Trailing',
    field: 'trailingRate',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'trailingRate',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-center-aligned-cell', cellClassRenderer(params)],
    valueFormatter: PercentValueFormatterTest,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
  },
  {
    headerName: 'Qtrs',
    field: 'quarters',
    filter: 'agNumberColumnFilter',
    width: 50,
    colId: 'quarters',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-center-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
  },
  {
    headerName: 'Savings',
    field: 'savings',
    filter: 'agTextColumnFilter',
    width: 60,
    colId: 'savings',
    hide: false,
    suppressMovable: true,
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: Savings_Json,
      defaultValue: '',
    },
    cellClass: (params) => [cellClassRenderer(params)],
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    suppressMenu: false,
  },
  {
    headerName: 'Trustee',
    field: 'trustee.entity.name',
    filter: 'agTextColumnFilter',
    width: 60,
    colId: 'trusteeId',
    hide: false,
    suppressMovable: true,
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    cellClass: (params) => [cellClassRenderer(params)],
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    suppressMenu: false,
  },
  {
    headerName: 'Transaction',
    field: 'transactionType',
    filter: 'agTextColumnFilter',
    width: 80,
    colId: 'transactionType',
    hide: false,
    suppressMovable: true,
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    suppressMenu: false,
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    cellClass: (params) => [cellClassRenderer(params)],
    cellRenderer: tooltipRenderer,
    cellRendererSelector: cellRendererSelector(component, SummaryKeyInvestments.Payment_Out),
  },
  {
    headerName: 'Payments',
    field: 'bankPayment',
    filter: 'agNumberColumnFilter',
    width: 80,
    colId: 'bankPayment',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-right-aligned-cell', cellClassRenderer(params)],
    autoHeaderHeight: true,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },

    valueFormatter: numberValueFormatter,
    floatingFilter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
    sortable: true,
  },
  {
    headerName: 'Receipts',
    field: 'bankReceipt',
    filter: 'agNumberColumnFilter',
    width: 80,
    colId: 'bankReceipt',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-right-aligned-cell', cellClassRenderer(params)],
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: numberValueFormatter,
    floatingFilter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: false,
    sortable: true,
    cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Comments',
    field: 'comments',
    filter: 'agTextColumnFilter',
    width: 80,
    colId: 'comments',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => [cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },

    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Brand',
    field: 'brand',
    filter: 'agTextColumnFilter',
    width: 80,
    colId: 'brand',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => [cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    suppressMenu: false,
  },
  {
    headerName: 'History',
    field: 'history',
    filter: 'agTextColumnFilter',
    width: 80,
    colId: 'history',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => [cellClassRenderer(params)],
    filterParams: {
      textMatcher: () => true,
    },
    floatingFilter: true,
    floatingFilterComponent: CustomSelectFilterComponent,
    floatingFilterComponentParams: {
      maxValue: 7,
      suppressFilterButton: true,
      selectTitle: 'All',
      selectArray: [],
      defaultValue: '',
    },
    suppressMenu: false,
  },
  {
    headerName: 'Cash',
    field: 'cash',
    filter: 'agNumberColumnFilter',
    width: 80,
    colId: 'cash',
    hide: false,
    suppressMovable: true,
    cellClass: (params) => ['ag-right-aligned-cell', cellClassRenderer(params)],
    autoHeaderHeight: true,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    valueFormatter: numberValueFormatter,
    floatingFilter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: false,
    cellRenderer: tooltipRenderer,
    sortable: true,
  },
  {
    field: 'Action',
    pinned: 'right',
    cellRenderer: ShowCellRendererComponent,
    cellDataType: false,
    width: 70,
    resizable: false,
    floatingFilter: false,
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    floatingFilterComponentParams: {
      suppressFilterButton: false,
    },
    suppressMovable: false,
    // lockVisible: true,
  },
];

function cellRendererSelector(component: InvestmentsComponent, column: SummaryKeyInvestments) {
  return (params: any) => {
    if (params.node.rowPinned) {
      return {
        component: CustomPinnedRowRendererComponent,
        params: {
          setTotal: component.changePinnedRowType,
          column,
          value: params.node.value,
          values: [
            { id: 'sum', name: 'Sum' },
            { id: 'avg', name: 'Average' },
            { id: 'max', name: 'Maximum' },
            { id: 'min', name: 'Minimum' },
          ],
        },
      };
    } else {
      return undefined;
    }
  };
}

function cellClassRenderer(params: any) {
  const { data, node, value } = params;
  if (node.rowPinned) return '';
  const isEmpty = data.buyRate == null && data.commission == null && data.savings == null && data.trustee == null;
  const isReceipt = ['Receipt', 'Renewal'].includes(data.transactionType);
  return isEmpty && isReceipt ? 'cell-border-yellow' : '';
}

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { Investment } from '../shared/interfaces/investments-response.interface';
import { FormatWithCommasService } from '../shared/services/format-with-commas.services';
import { SimpleNumberValueFormatter } from '../shared/helpers/number-formatter.helper';
import { PercentFormatter } from '../shared/helpers/percent-formatter.helper';

@Injectable({ providedIn: 'root' })
export class InvestmentsFormProvider {
  investmentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private formatCommasService: FormatWithCommasService,
  ) {}

  initInvestmentForm(data?: Partial<Investment>, isNew = true): FormGroup {
    return (this.investmentForm = this.fb.group({
      id: [data?.id],
      intermediaryId: [
        data?.intermediaryId ? `${data?.intermediaryId}` : null,
        [Validators.required],
      ],
      investorId: [data?.investorId ? `${data?.investorId}` : null, [Validators.required]],
      transactionType: [isNew ? '' : data?.transactionType || 'Receipt', [Validators.required]],
      type: [data?.type, [Validators.required]],
      date: [data?.date ? this.parseDate(data?.date) : null, [Validators.required]],
      faceValue: [
        data?.faceValue ? SimpleNumberValueFormatter(+data.faceValue) : null,
        [Validators.required],
      ],
      product: [data?.product, [Validators.required]],
      savings: [data?.savings || 'Deposit', [Validators.required]],

      commission: [
        data?.commission || data?.commission === 0
          ? PercentFormatter(+data?.commission * 100, false)
          : null,
        [Validators.required, Validators.max(99.99), Validators.min(0)],
      ],
      months: [data?.months, [Validators.required]],
      comments: [data?.comments],
      ledgerId: [data?.ledgerId, [Validators.required]],
      ccy: [data?.ccy, [Validators.required]],
      buyRate: [
        data?.buyRate ? PercentFormatter(+data?.buyRate * 100, false) : null,
        [Validators.min(0.001), Validators.max(99.99), Validators.required],
      ],
      //@todo not in BE
      fxRate: [data?.fx_rate ? data?.fx_rate.toFixed(4) : null],
      eurAmount: [data?.eurFaceValue ? SimpleNumberValueFormatter(data?.eurFaceValue) : null],
      trusteeId: [data?.trusteeId || ''],
    }));
  }

  private parseDate(param: string) {
    return {
      year: parseInt(moment(param).format('YYYY'), 10),
      day: parseInt(moment(param).format('DD'), 10),
      month: parseInt(moment(param).format('M'), 10),
    };
  }

  private changeDigits(param: number) {
    return param.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

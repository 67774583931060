import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilter, IFloatingFilterParams, NumberFilterModel } from 'ag-grid-community';

export interface SliderFloatingFilterParams extends IFloatingFilterParams {
  value: number;
  maxValue: number;
  selectArray: any;
  selectTitle: string;
  defaultValue: string;
  callBackFunctionfilter: any;
}

@Component({
  standalone: true,
  imports: [FormsModule, CommonModule],
  template: ` <select
    style="border: 1px solid #dddddd;
    width: 100%;
    height:20px!important;
    font-family: verdana, Geneva, sans-serif !important;
    font-size: 11px;
    margin-top:3px;
    color:#54667a;
    max-width: 150px;"
    name="ddl_ccy"
    id="ddl_ccy"
    [(ngModel)]="currentValue"
    (ngModelChange)="valueChanged()"
  >
    <option selected="selected" value="">{{ selectTitle }}</option>
    <option *ngFor="let select of selectArray" value="{{ select.id }}" [title]="select.name">
      {{ select?.name?.length > 20 ? select?.name?.substr(0, 25) + '...' : select?.name }}
    </option>
  </select>`,
})
export class CustomSelectFilterComponent implements IFloatingFilter, AgFrameworkComponent<SliderFloatingFilterParams> {
  private params!: SliderFloatingFilterParams;

  public maxValue!: number;
  public currentValue = '';
  public selectArray!: any;
  public selectTitle = '';
  public defaultValue = '';

  agInit(params: SliderFloatingFilterParams): void {
    this.params = params;
    this.maxValue = this.params.maxValue;
    this.currentValue = this.params.defaultValue;
    this.selectArray = this.params.selectArray;
    this.selectTitle = this.params.selectTitle;
  }

  valueChanged() {
    const valueToUse = this.currentValue === '' ? '' : this.currentValue;
    if (valueToUse == 'Blanks') {
      this.params.parentFilterInstance(function (instance) {
        instance.onFloatingFilterChanged('equals', valueToUse);
      });
    } else {
      this.params.parentFilterInstance(function (instance) {
        instance.onFloatingFilterChanged('equals', valueToUse);
      });
    }
  }

  onParentModelChanged(parentModel: NumberFilterModel): void {
    if (!parentModel) {
      this.currentValue = '';
    }
  }
}

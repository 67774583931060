import { ColDef } from '@ag-grid-community/core';
import { CustomPinnedRowRendererComponent } from '../../../../shared/components/custom-pinned-row';
import { TarComponent } from '../../components/tar/tar.component';
import { AdvancedNumberValueFormatter } from '../../../../shared/helpers/number-formatter.helper';
import { CustomTooltipComponent } from '../../../../shared/components/custom-tooltip.component';

export const TAR_COL_DEFS: (component: TarComponent) => ColDef[] = (component) => [
  {
    headerName: 'TaR',
    field: 'tarLabel',
    width: 50,
    filter: false,
    colId: 'tarLabel',
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
  },
  {
    headerName: 'Date',
    field: 'traded',
    colId: 'traded',
    width: 80,
    filter: false,
    floatingFilter: true,
    autoHeaderHeight: false,
    cellRendererSelector: cellRendererSelector(component, 'total'),
  },
  {
    headerName: 'Total',
    field: 'total',
    colId: 'total',
    width: 70,
    cellClass: 'ag-right-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    filter: false,
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'total',
  },
  {
    headerName: '<= EDSO',
    field: 'edsoTotal',
    colId: 'edsoTotal',
    width: 70,
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    filter: false,
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'edsoTotal',
  },
  {
    headerName: 'EDSO 60',
    field: 'edso60Total',
    colId: 'edso60Total',
    width: 70,
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    filter: false,
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'edso60Total',
  },
  {
    headerName: 'TaR',
    field: 'tarTotal',
    colId: 'tarTotal',
    width: 65,
    cellClass: 'ag-right-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    filter: false,
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'tarTotal',
  },
  {
    headerName: '',
    field: 'firstCheck',
    colId: 'firstCheck',
    width: 20,
    filter: false,
    floatingFilter: true,
    autoHeaderHeight: false,
    cellRenderer: (params: any) => getArrowIcon(params.data.firstCheck),
  },
  {
    headerName: 'EDSO 365',
    field: 'edso365Total',
    colId: 'edso365Total',
    width: 75,
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    filter: false,
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'edso365Total',
  },
  {
    headerName: '',
    field: 'secondCheck',
    colId: 'secondCheck',
    filter: false,
    width: 20,
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    cellRenderer: (params: any) => getArrowIcon(params.data.secondCheck),
  },
  {
    headerName: 'TaR +',
    field: 'tarPlusTotal',
    colId: 'tarPlusTotal',
    width: 65,
    cellClass: 'ag-center-aligned-cell',
    floatingFilter: true,
    autoHeaderHeight: false,
    filter: false,
    valueFormatter: (params) => {
      return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
    },
    tooltipComponent: CustomTooltipComponent,
    tooltipField: 'tarPlusTotal',
  },
  {
    headerName: '',
    field: 'thirdCheck',
    colId: 'thirdCheck',
    filter: false,
    width: 20,
    floatingFilter: true,
    autoHeaderHeight: false,
    cellRenderer: (params: any) => getArrowIcon(params.data.thirdCheck),
  },
];

export function getArrowIcon(params: any): string {
  if (params === undefined) return '';
  let iconHtml = '';
  if (params) {
    iconHtml = `<img alt="" src="/assets/images/icon/arrow-up-green.svg" width="13" height="10" style="vertical-align: middle;"/>`;
  } else {
    iconHtml = params === null ? '-' : `<img alt="" src="/assets/images/icon/arrow-down-red.svg" width="13" height="10" style="vertical-align: middle;"/>`;
  }

  return `
    <div style="display: flex; align-items: center; justify-content: center">
      <span>${iconHtml}</span>
    </div>
  `;
}

function cellRendererSelector(component: TarComponent, column: any) {
  return (params: any) => {
    if (params.node.rowPinned) {
      return {
        component: CustomPinnedRowRendererComponent,
        params: {
          setTotal: component.changePinnedRowType,
          column,
          value: params.node.value,
          valueFormatted: params.value,
          values: [
            // { id: 'sum', name: 'Sum' },
            { id: 'avg', name: 'Average' },
            { id: 'max', name: 'Maximum' },
            { id: 'min', name: 'Minimum' },
          ],
        },
      };
    } else {
      return undefined;
    }
  };
}

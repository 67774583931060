import { Component } from '@angular/core';

import type { ITooltipAngularComp } from 'ag-grid-angular';
import type { ITooltipParams } from 'ag-grid-community';

@Component({
  standalone: true,
  template: `
    <div class="custom-tooltip" [style.background-color]="color">
      <div [innerHTML]="params.value"></div>
    </div>`,
  styles: [
    `
      :host {
        position: absolute;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        padding: 5px;
        background-color: #5577cc;
        width: 280px;
        margin: 5px;
        color: white;
        font-size: 11px;
        font-weight: 400;
        margin-bottom: 0 !important;
      }

      ::ng-deep .custom-tooltip p {
        font-size: 11px;
        color: white;
        margin-bottom: 0 !important;
      }

    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  params!: { color: string } & ITooltipParams;
  public color!: string;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.color = this.params.color || '#fff';
  }
}

import { Component, OnInit } from "@angular/core";
import { AgCartesianChartOptions } from "ag-charts-enterprise";
import { EntityQueryParams } from "../../shared/interfaces/entity-query-params";
import { ChartQueryParams } from "../../shared/interfaces/chart-query-param";
import { SelectItem } from "../../shared/interfaces/select-item.interface";
import { generateYearRange } from "../../shared/helpers/generate-date-range";
import { EntitiesHttpService } from "../../entities/entities-http.service";
import { TradeHttpService } from "../../trade/services/trade-http.service";
import { InvestmentsHttpService } from "../../investments/common/services/investments-http.service";
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-etr-charts',
  templateUrl: './etr-charts.component.html',
  styleUrls: ['./etr-charts.component.scss']
})
export class EtrChartsComponent implements OnInit {
  isLoading = false;
  multipleLineOptions: AgCartesianChartOptions | any;
  queryParamsEntity: Partial<EntityQueryParams> = {
    pageIndex: 0,
    perPage: 10000,
  };
  queryParamsChart: Partial<ChartQueryParams> = {
    pageIndex: 0,
    perPage: 10000,
    period: 'quarter',
    dateRange: 'all',
    year: '',
  };
  originatorIds: Array<SelectItem> = [];
  investorsIds: Array<SelectItem> = [];
  credebtorsIds: Array<SelectItem> = [];
  etrTypes: Array<SelectItem> = [];
  tradeTransTypes: Array<SelectItem> = [];
  periods: Array<SelectItem> = [
    { id: 'week', name: 'Week' },
    { id: 'month', name: 'Month' },
    { id: 'quarter', name: 'Quarter' },
    { id: 'year', name: 'Year' },
  ];
  yearList: Array<SelectItem> = generateYearRange();
  selectedPeriod = '';
  selectedYear = '';
  selectedType = '';
  selectedETR = '';
  selectedTimePeriod = '';
  selectedCredebtor: string;
  selectedOriginator: string;

  constructor(
    private entityService: EntitiesHttpService,
    private tradeTransactionsHttpService: TradeHttpService,
    private investmentsHttpService: InvestmentsHttpService,
  ) {}

  ngOnInit() {
    this.initMultipleLineOptions();
    this.getDropDownValues();
    setTimeout(() => {
      this.getChartData();
    }, 300);
  }

  initMultipleLineOptions() {
    this.multipleLineOptions = {
      zoom: {
        enabled: true,
        scrollingStep: 0.4,
        axes: 'xy',
        enableSelecting: true,
      },
      series: [
        { type: 'bar', xKey: 'quarter', yKey: 'aEtr', yName: 'a-ETR', fill: '#000000'},
        { type: 'bar', xKey: 'quarter', yKey: 'bEtr', yName: 'b-ETR', fill: '#000000' },
        { type: 'bar', xKey: 'quarter', yKey: 'cEtr', yName: 'c-ETR', fill: '#000000' },
        { type: 'bar', xKey: 'quarter', yKey: 'dEtr', yName: 'd-ETR', fill: '#000000' },
        { type: 'bar', xKey: 'quarter', yKey: 'fEtr', yName: 'f-ETR', fill: '#000000' },
        { type: 'bar', xKey: 'quarter', yKey: 'deduction', yName: 'Deduction', fill: '#000000'},
        { type: 'bar', xKey: 'quarter', yKey: 'creditNote', yName: 'Credit Note', fill: '#000000'},
        { type: 'bar', xKey: 'quarter', yKey: 'deposit', yName: 'Deposit', fill: '#000000'},
        { type: 'bar', xKey: 'quarter', yKey: 'ocpa', yName: 'OCPA', fill: '#000000'},
        { type: 'bar', xKey: 'quarter', yKey: 'icp', yName: 'ICP', fill: '#000000'},
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: { rotation: -90},
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: (params: any) => this.leftLabelValueFormatter(Number(params.value)),
          },
          gridLine: {
            style: [
              {
                stroke: 'rgba(0, 0, 0, 0.5)',
                lineDash: [0],
              },
            ],
          },
          min: 0,
          max: 1.0e8,
        },
      ],
      legend: {
        position: 'right',
        enabled: false,
      },
    };
  }

  getDropDownValues() {
    forkJoin({
      etrsTypes: this.tradeTransactionsHttpService.getEtrFilterDistinctValues(),
      entities: this.entityService.getEntities({ ...this.queryParamsEntity, role: 'Investor' }),
      originators: this.entityService.getEntityOriginators(),
      credebtors: this.entityService.getEntityCredebtors(),
      tradeTransTypes: this.investmentsHttpService.getInvestmentsFilterData(),
    }).subscribe({
      next: ({ etrsTypes, entities, originators, credebtors, tradeTransTypes }) => {
        this.etrTypes = etrsTypes.types
          .filter((value) => value !== 'ETR')
          .map((value) => ({
            id: value,
            name: value,
          }));
        this.tradeTransTypes = tradeTransTypes.types.map((value) => ({
          id: value,
          name: value,
        }));
        this.investorsIds = entities.items.map((entity) => ({
          id: entity.entityRoles[0].id.toString(),
          name: entity.name,
        }));
        this.originatorIds = originators;
        this.credebtorsIds = credebtors;
      },
    });
  }

  getChartData() {
    this.isLoading = true;
    this.tradeTransactionsHttpService.getEtrChart(this.queryParamsChart)
      .subscribe((value: any) => {
        const minMaxValue = this.detectMinMaxNumbers(value);
        this.multipleLineOptions.axes[1].max = minMaxValue.max;
        const yKeys = ['aEtr', 'bEtr', 'cEtr', 'dEtr', 'fEtr', 'deduction', 'creditNote', 'deposit', 'ocpa', 'icp'];
        const trendlineData = this.calculateCombinedTrendline(value, yKeys);
        const existingTrendlineIndex = this.multipleLineOptions.series.findIndex((s: any) => s.yKey === 'trendValue');
        if (existingTrendlineIndex >= 0) {
          this.multipleLineOptions.series[existingTrendlineIndex] = {
            type: 'line',
            xKey: 'quarter',
            yKey: 'trendValue',
            data: trendlineData,
            yName: 'Trend Line',
            stroke: 'rgba(0, 0, 0, 0.5)',
            marker: { enabled: false },
          };
        } else {
          this.multipleLineOptions.series.push({
            type: 'line',
            xKey: 'quarter',
            yKey: 'trendValue',
            data: trendlineData,
            yName: 'Trend Line',
            stroke: 'rgba(0, 0, 0, 0.5)',
            marker: { enabled: false },
          });
        }
        this.multipleLineOptions = {
          ...this.multipleLineOptions,
          data: value,
        };
        this.isLoading = false;
      });
  }

  private detectBigNumbersAllFields(data: any) {
    let biggestNumber: any = null;

    // Loop through each record and each field to find the biggest number
    data.forEach((record: any) => {
      Object.values(record).forEach(value => {
        if (typeof value === 'number') {
          if (biggestNumber === null || value > biggestNumber) {
            biggestNumber = value;
          }
        }
      });
    });

    return biggestNumber;
  }

  private detectMinMaxNumbers(data: any) {
    let minNumber:any = null;
    let maxNumber:any = null;

    // Loop through each record and each field to find the min and max numbers
    data.forEach((record: any) => {
      Object.values(record).forEach(value => {
        if (typeof value === 'number') {
          if (minNumber === null || value < minNumber) {
            minNumber = value;
          }
          if (maxNumber === null || value > maxNumber) {
            maxNumber = value;
          }
        }
      });
    });

    return { min: minNumber, max: maxNumber };
  }

  calculateCombinedTrendline(data: any[], yKeys: string[]): any[] {
    const n = data.length;
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumX2 = 0;
    data.forEach((d, i) => {
      sumX += i;
      let combinedY = 0;
      yKeys.forEach((yKey) => {
        combinedY += d[yKey];
      });

      sumY += combinedY;
      sumXY += i * combinedY;
      sumX2 += i * i;
    });
    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;
    return data.map((d, i) => ({
      quarter: d.quarter,
      trendValue: parseFloat((slope * i + intercept).toFixed(2)),
    }));
  }

  onSelectTimePeriod(dateRange: string) {
    this.selectedTimePeriod = this.queryParamsChart.dateRange = dateRange;
    this.getChartData();
  }

  onChangeQueryParam(key: keyof typeof this.queryParamsChart, value: any) {
    this.queryParamsChart[key] = value;
    if (this.queryParamsChart.type == 'All') {
      delete this.queryParamsChart.type;
    }
    this.getChartData();
  }

  leftLabelValueFormatter = (value: number) => {
    const absValue = Math.abs(value);
    const decimal = 1;
    if (absValue >= 1.0e9) {
      return (absValue / 1.0e9).toFixed(decimal) + ' b';
    } else if (absValue >= 1.0e6) {
      return (absValue / 1.0e6).toFixed(decimal) + ' m';
    } else if (absValue >= 1.0e3) {
      return (absValue / 1.0e3).toFixed(decimal) + ' k';
    } else {
      return absValue.toFixed(decimal);
    }
  };

  leftLabelValueFormatterContainsNegative = (value: number) => {
    const absValue = Math.abs(value);
    const decimal = 1;
    let formattedValue = '';

    if (absValue >= 1.0e9) {
      formattedValue = (absValue / 1.0e9).toFixed(decimal) + ' b';
    } else if (absValue >= 1.0e6) {
      formattedValue = (absValue / 1.0e6).toFixed(decimal) + ' m';
    } else if (absValue >= 1.0e3) {
      formattedValue = (absValue / 1.0e3).toFixed(decimal) + ' k';
    } else {
      formattedValue = absValue.toFixed(decimal);
    }

    // Reapply the negative sign if the original value was negative
    return value < 0 ? '-' + formattedValue : formattedValue;
  };
}

<div class="iframe-container">
  <div class="card mb-0 common-header" id="header-row">
    <div class="card-body header-title pb-3">
      <div class="row">
        <div class="col-md-12 d-flex">
          <p class="card-title ps-1 pe-3">Charts</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <app-etr-charts />
    </div>

    <div class="col-6">
      <app-investments-charts />
    </div>
  </div>
</div>


export const FXRATE_PARAMS_KEYS: string[] = [
  'search',
  'string',
  'startDate',
  'endDate',
  'date',
  'week',
  'month',
  'year',
  'buyRate',
  'gbp',
  'usd',
  'eonia',
  'libor',
  'obfr',
  'demand',
  'fixed',
  'term',
  'us10y',
  'us3y',
  'bankEur',
  'bankEurTotal',
  'bankGbp',
  'bankUsd',
];

<div class="row p-1">
    <div class="col-lg-12 col-xl-12">
        <div class="card w-100">
            <div class="card">
                <form
                    *ngIf="investorForm"
                    [formGroup]="investorForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="row">
                        <div [ngClass]="isPerson ? 'width-234' : 'width-293'" class="width-234" >
                            <div >
                                <label for="intermediaryId" class="form-title"><span class="required">*</span>Intermediary
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.intermediaryName }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                                </label>
                                <ng-select class="custom"
                                           id="intermediaryId"
                                           [style.flex]="1"
                                           [items]="intermediaryIdList"
                                           bindLabel="name"
                                           bindValue="id"
                                           placeholder="All"
                                           formControlName="intermediaryId"
                                           (change)="handleIntermediaryChange($event)">
                                </ng-select>
                            </div>
                        </div>

                      <div *ngIf="!isPerson" class="mb-3 field-group width-293">
                        <label for="firstName" class="form-title"><span class="required">*</span> Organisation
                          <span
                            class="tooltip-ng ms-0"
                            placement="bottom"
                            ngbTooltip="{{ tooltipData.organization }}"
                          ><i class="bi bi-question-circle"></i></span
                          ></label>
                        <input type="text" class="form-control" formControlName="firstName" id="organisation" />
                      </div>

                        <div *ngIf="isPerson" class="mb-3 field-group width-110">
                            <label for="firstName" class="form-title"><span class="required">*</span> First Name
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.firstName }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <input type="text" class="form-control" formControlName="firstName"
                              id="firstName" />
                          </div>


                          <div *ngIf="isPerson" class="mb-3 field-group width-110">
                            <label for="lastName" class="form-title"><span class="required">*</span> Last Name
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.lastName }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <input type="text" class="form-control" formControlName="lastName"
                              id="lastName" />
                          </div>

                          <div *ngIf="isPerson" class="mb-3 field-group width-131">
                            <label for="dob" class="form-title"
                            > Date of Birth
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.dob }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <div class="input-group birth_date">
                              <input type="text" class="form-control" placeholder="YYYY-MM-DD" name="d1" formControlName="birthDate"
                                ngbDatepicker #d1="ngbDatepicker" />
                              <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d1.toggle()" type="button"><img
alt=""                                  src="./assets/images/icon/calendar-icon.svg" /></button>
                            </div>
                          </div>

                          <div class="mb-3 field-group width-145" >
                            <label for="email" class="form-title"
                            > Email
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.email }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <input type="text" class="form-control" formControlName="email" id="email" />

                          </div>

                            <div class="mb-3 field-group width-135" >
                              <label for="lineOne" class="form-title"><span class="required">*</span> Address
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.address }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control"  formControlName="lineOne"
                                id="lineOne" />

                            </div>

                            <div class="mb-3 mt-1 field-group width-135" >
                              <label for="lineTwo" class="form-title"> </label>
                              <input type="text" class="form-control"  formControlName="lineTwo"
                                id="lineTwo" />
                            </div>


                            <div class="mb-3 field-group width-90">
                              <label for="city" class="form-title"><span class="required">*</span> City
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.city }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control"  formControlName="city" id="city" />
                            </div>

                            <div class="mb-3 field-group width-80">
                              <label for="state" class="form-title"><span class="required">*</span> County
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.state }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control" formControlName="state" id="state" />
                            </div>

                            <div class="mb-3 field-group width-80">
                              <label for="area" class="form-title"
                              > Area
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.area }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control" formControlName="area" id="area" />
                            </div>

                            <div class="mb-3 field-group width-80">
                              <label for="zipCode" class="form-title text-nowrap">Post Code
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.postCode }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control inputNumberText"  formControlName="zipCode"
                                id="zipCode" />

                            </div>

                            <div class="mb-3 field-group width-95">
                              <label for="country" class="form-title"><span class="required">*</span> Country
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.country }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                            <div class="input-group">
                              <select class="custom-form-select border-radius-100" id="country" formControlName="country" (change)="onSelectCountry($event)">
                                <option value="" selected="true" >All</option>
                                <option *ngFor="let country of countryList" [value]="country.name">
                                  {{ country.name }}
                                </option>
                              </select>

                            </div>
                            </div>

                                <div class="mb-3 field-group width-110">
                                  <label for="home" class="form-title text-nowrap"
                                  > Home Phone
                                  <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.homePNumber }}"
                                    ><i class="bi bi-question-circle"></i></span
                                ></label>
                                  <input  type="text" appOnlyNumber maxlength="15" class="form-control inputNumberText"
                                    formControlName="home" id="home" />
                                </div>

                                <div class="mb-3 field-group width-110" >
                                  <label for="office" class="form-title text-nowrap"
                                  > Office Phone
                                  <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.officePNumber }}"
                                    ><i class="bi bi-question-circle"></i></span
                                ></label>
                                  <input  type="text" appOnlyNumber maxlength="15" class="form-control inputNumberText"
                                    formControlName="office" id="office" />
                                </div>

                                <div class="mb-3 field-group width-110" >
                                  <label for="mobile" class="form-title text-nowrap"
                                  > Mobile Phone
                                  <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.mobileNumber }}"
                                    ><i class="bi bi-question-circle"></i></span
                                ></label>
                                  <input  type="text" appOnlyNumber maxlength="15" class="form-control inputNumberText"
                                    formControlName="mobile" id="mobile" />
                                </div>

                                <div  class="mb-3 field-group width-95">
                                    <label for="sortCode" class="form-title"><span *ngIf="investorForm.get('sortCode')?.validator" class="required">*</span> Sort Code
                                      <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.sortCode }}"
                                        ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                    <input type="text" maxlength="14" class="form-control sd-width" placeholder="" formControlName="sortCode" id="sortCode" />
                                  </div>

                                <div class="mb-3 field-group width-110">
                                    <label for="account" class="form-title"><span *ngIf="investorForm.get('account')?.validator" class="required">*</span> Account
                                      <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.account }}"
                                        ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                    <input type="text" appOnlyNumber class="form-control" formControlName="account" id="account" />
                                </div>

                                <div  class="mb-3 field-group width-100">
                                      <label for="swift" class="form-title"
                                      > SWIFT BIC
                                          <span
                                              class="tooltip-ng ms-0"
                                              placement="bottom"
                                              ngbTooltip="{{ tooltipData.swift }}"
                                          ><i class="bi bi-question-circle"></i></span
                                          ></label>
                                      <input
                                          id="swift"
                                          type="text"
                                          class="form-control ft-width"
                                          placeholder=""
                                          formControlName="swiftBic"
                                          maxlength="8"
                                          style="text-transform: uppercase;"
                                      />
                              </div>

                              <div class="mb-3 field-group width-188">
                                      <label for="iban" class="form-title"><span *ngIf="investorForm.get('iban')?.validator" class="required">*</span> IBAN
                                          <span
                                              class="tooltip-ng ms-0"
                                              placement="bottom"
                                              ngbTooltip="{{ tooltipData.iban }}"
                                          ><i class="bi bi-question-circle"></i></span
                                          ></label>
                                      <input
                                          id="iban"
                                          type="text"
                                          class="form-control ft-width"
                                          placeholder=""
                                          formControlName="iban"
                                          maxlength="34"
                                          style="text-transform: uppercase;"
                                      />

                              </div>

                        <div class="col-12 bottom-fixed">
                            <hr />
                            <div class="d-flex justify-content-center mt-3">
                                <button type="submit" [disabled]="investorForm.invalid"
                                        class="btn btn-light me-3 btn-custom-footer">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-light btn-custom-footer"
                                    data-dismiss="modal"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="ps-4 me-2 gap-3 pb-2 d-flex align-items-center">
  <div class="width-20p">
    <ng-select class="custom"
               id="intermediaryId"
               [style.flex]="1"
               [items]="intermediaryIdList"
               bindLabel="name"
               bindValue="id"
               placeholder="Intermidiary"
               (change)="handleIntermediaryChange($event)"
    />
  </div>

  <div class="width-20p">
    <ng-select class="custom"
               id="investorId"
               [style.flex]="1"
               [items]="investorGeneratedList"
               bindLabel="name"
               bindValue="id"
               placeholder="Investors"
               [searchFn]="ngSelectCustomSearch"
               (change)="investorChange($event)"
    />
  </div>

  <div>
    <ng-select class="custom"
               [style.flex]="1"
               [items]="periods"
               bindLabel="name"
               bindValue="id"
               placeholder="View By"
               (change)="onChangeQueryParam('period', $event)"
    />
  </div>

</div>
<div class="row pl-5">
  <ag-charts [options]="multipleLineOptions" class=""></ag-charts>
</div>
<div class="row pl-5 d-flex justify-content-end">
  <div class="time-periods me-4">
    <a *ngFor="let period of ['1M', '6M', '1Y', '5Y', '10Y', '25Y', 'all']"
       (click)="onSelectTimePeriod(period)">
      {{ period === 'all' ? 'All' : period }}
    </a>
  </div>
</div>

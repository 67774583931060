
<div class="row">
   <div class="col-lg-12">
      <div class="card w-100">
         <div class="card">
            <form [formGroup]="investmentForm" (ngSubmit)="onSubmit()">
              <div class="row">
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Bank  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Bank_Date}}"><i class="bi bi-question-circle"></i></span></label>

                       <div class="input-group">
                         <input
                             type="text"
                            class="form-control"
                            placeholder="YYYY-MM-DD"
                            name="d2"
                            formControlName="Bank_Date"
                            ngbDatepicker
                            #d2="ngbDatepicker"
                            [readonly]="true"
                         />
                         <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d2.toggle()" type="button"> <img alt="" src="./assets/images/icon/calendar-icon.svg"></button>
                      </div>

                    </div>
                    <div [innerHTML]="ShowError(errorControl.Bank_Date,isSubmitted, 'Bank')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Type  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Type}}"><i class="bi bi-question-circle"></i></span></label>
                       <ng-select [items]="type" formControlName="Type" bindLabel="name" bindValue="id" placeholder="--- Select ---"></ng-select>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Type,isSubmitted, 'Type')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title "> Receipt  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Receipt_In}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right ft-width"  placeholder="" formControlName="Receipt_In" maxlength="12" >
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Receipt_In,isSubmitted, 'Receipt')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title "> Payment  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Payment_Out}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right ft-width"  placeholder="" formControlName="Payment_Out" maxlength="12" >
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Payment_Out,isSubmitted, 'Paymant')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Intermediary ID  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Intermediary_ID}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right sd-width"  placeholder="" formControlName="Intermediary_ID" maxlength="20">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Intermediary_ID,isSubmitted, 'Intermediary ID')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Intermediary  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Intermediary}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control sd-width"  placeholder="" formControlName="Intermediary" maxlength="20">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Intermediary,isSubmitted, 'Intermediary')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Investor ID  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Investor_ID}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right sd-width"  placeholder="" formControlName="Investor_ID" maxlength="20">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Investor_ID,isSubmitted, 'Investor ID')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Investor  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Investor_name}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control sd-width"  placeholder="" formControlName="Investor_name" maxlength="20">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Investor_name,isSubmitted, 'Investor')" class="error-msg"></div>
                 </div>
                 <div class="col-md-12">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Transaction Details  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Transaction_Details}}"><i class="bi bi-question-circle"></i></span></label>
                       <textarea type="text" class="form-control "  placeholder="Write here about  transaction..." formControlName="Transaction_Details" row="3" maxlength="512"> </textarea>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Transaction_Details,isSubmitted, 'Transaction Details')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Transaction  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Transaction}}"><i class="bi bi-question-circle"></i></span></label>

                       <ng-select [items]="transaction" formControlName="Transaction" bindLabel="name" bindValue="id" placeholder="--- Select ---"></ng-select>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Transaction,isSubmitted, 'Transaction')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Ccy  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Ccy}}"><i class="bi bi-question-circle"></i></span></label>
                       <ng-select [items]="ccy" formControlName="Ccy" bindLabel="name" bindValue="id" placeholder="--- Select ---" class="ng-select-custom"></ng-select>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Ccy,isSubmitted, 'Ccy')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Date  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Date}}"><i class="bi bi-question-circle"></i></span></label>
                       <div class="input-group">
                         <input
                            class="form-control"
                            placeholder="YY/MM/DD"
                            name="d3"
                            formControlName="Date"
                            ngbDatepicker
                            #d3="ngbDatepicker"
                            [readonly]="true"
                         />
                         <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d3.toggle()" type="button"><img alt="" src="./assets/images/icon/calendar-icon.svg"></button>
                      </div>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Date,isSubmitted, 'Date')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Face Value  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Face_Value}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right ft-width"  placeholder="" formControlName="Face_Value" maxlength="12">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Face_Value,isSubmitted, 'Face value')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Sell Rate  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Buy_Rate}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right ft-width "  placeholder="0%" formControlName="Buy_Rate" maxlength="10">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Buy_Rate,isSubmitted, 'Sell rate')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Commission  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Commission}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right ft-width "  placeholder="" formControlName="Commission" maxlength="10">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Commission,isSubmitted, 'Commission')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Months  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Months}}"><i class="bi bi-question-circle"></i></span></label>
                       <input type="text" class="form-control text-right ft-width "  placeholder="" formControlName="Months" maxlength="3">
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Months,isSubmitted, 'Months')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> Type  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Type2}}"><i class="bi bi-question-circle"></i></span></label>

                       <ng-select [items]="type2" formControlName="Type2" bindLabel="name" bindValue="id" placeholder="--- Select ---"></ng-select>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Type2,isSubmitted, 'Type')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"><span class="required">*</span> History  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Old_Transaction}}"><i class="bi bi-question-circle"></i></span></label>
                       <ng-select [items]="oldtransaction" formControlName="Old_Transaction" bindLabel="name" bindValue="id" placeholder="--- Select ---"></ng-select>
                    </div>
                    <div [innerHTML]="ShowError(errorControl.Old_Transaction,isSubmitted, 'History')" class="error-msg"></div>
                 </div>
                 <div class="col-md-3">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"> Brand <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Old_Investment}}"><i class="bi bi-question-circle"></i></span></label>
                       <ng-select [items]="oldinvestment" formControlName="Old_Investment" bindLabel="name" bindValue="id" placeholder="--- Select ---"></ng-select>
                    </div>
                 </div>
                 <div class="col-md-12">
                    <div class="mt-3">
                       <label for="tb-fname"  class="form-title"> Comments  <span class="tooltip-ng" placement="bottom" ngbTooltip="{{getquestion.Comments}}"><i class="bi bi-question-circle"></i></span></label>
                       <textarea type="text" class="form-control"  placeholder="Type somthing here..." formControlName="Comments" row="3" maxlength="512"> </textarea>
                    </div>
                 </div>

                 <div class="col-12 bottom-fixed">
                   <hr >
                    <div class="d-flex justify-content-center mt-3">
                      <button type="submit" class="btn btn-light  me-3 btn-custom-footer">
                            Save
                      </button>
                      <button type="button" class="btn btn-light  btn-custom-footer" data-dismiss="modal" (click)="closeModal()">
                         Cancel
                      </button>


                    </div>
                 </div>
              </div>
            </form>
         </div>
      </div>
   </div>
 </div>

import { DateFilterParamsSettings, omitKey } from './utils';

export function queryCleanerModel(queryParams: any, filterModel: any, currentColumnId: string, PARAMS_KEYS: string[]): any {
  if (currentColumnId.toLowerCase().search('date') >= 0 && !filterModel[currentColumnId]) {
    for (const string of ["from", "to", "comparator"]) {
      queryParams = omitKey(queryParams, `${currentColumnId}[${string}]`);
    }
  }

  if (!filterModel[currentColumnId]) queryParams = omitKey(queryParams, currentColumnId);
  for (const key of PARAMS_KEYS) {
    if (filterModel[key]?.filterType === 'text') {
      const dateFilter = filterModel[key];
      queryParams[key] = dateFilter.filter === '~' ? 'Blank' : dateFilter.filter;
    }

    if (filterModel[key]?.filterType === 'number') {
      const dateFilter = filterModel[key];
      queryParams[key] = dateFilter.filter;
    }

    if (filterModel[key]?.filterType === 'date') {
      const dateFilter = filterModel[key];
      queryParams = { ...queryParams, ...DateFilterParamsSettings(dateFilter, key) };
    }

    if (filterModel[key]?.filterType === 'multi') {
      const dateFilter = filterModel[key];
      queryParams[key] = dateFilter.filterModels[1].values;
    }
  }
  return queryParams;
}

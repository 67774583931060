import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleNumberValueFormatter } from '../helpers/number-formatter.helper';
import { ConvertISONumberToNormal, ConvertISONumberToNormalKeepSigns } from "../helpers/utils";

@Directive({
  selector: '[appOnlyNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyNumberDirective),
      multi: true,
    },
  ],
})
export class OnlyNumberDirective implements ControlValueAccessor {
  @Input() appOnlyNumber: boolean | '' = false;
  @Input() isFx: boolean | '' = false;
  @Input() allowedSign: string | 'none' = '';
  @Input() keepSignAndZero: boolean;
  private onChange!: (val: string | number) => void;
  private onTouched!: () => void;
  private value!: string | number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const filteredValue: string | number = filterValue(value, this.allowedSign);
    this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  @HostListener('blur')
  onBlur() {
    if (this.appOnlyNumber) {
      this.onValueFormating();
    }
  }
  @HostListener('focus')
  onFocus() {
    if (!this.value) {
      return;
    }
    if (this.keepSignAndZero) {
      this.updateTextInput(ConvertISONumberToNormalKeepSigns(this.value, this.keepSignAndZero), false);
    } else {
      this.updateTextInput(isNaN(ConvertISONumberToNormal(this.value as string)) ? this.value : ConvertISONumberToNormal(this.value as string), false);
    }
  }

  private updateTextInput(value: string | number, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  onValueFormating() {
    if (!this.value) {
      return;
    }
    this.updateTextInput(SimpleNumberValueFormatter(this.value, this.isFx ? 4 : 2)!, false);
  }
  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    value = value
      ? this.appOnlyNumber
        ? SimpleNumberValueFormatter(value, this.isFx ? 4 : 2)
        : String(value)
      : '';
    this.updateTextInput(value, false);
  }
}

function filterValue(value: any, allowedSign = ''): string | number {
  // If allowedSign is 'none', allow only numbers 0-9
  if (allowedSign === 'none') {
    return value.replace(/[^0-9]/g, '');
  }

  const regex= allowedSign ? new RegExp(`[^0-9\\${allowedSign}]`, 'g') : /[^0-9+\-.]/g;

  value = value.replace(regex, '');
  // Ensure the allowed sign appears only once in the entire value
  if (allowedSign && value.includes(allowedSign)) {
    const firstOccurrenceIndex = value.indexOf(allowedSign);
    value =
      value.slice(0, firstOccurrenceIndex + 1) + // Keep the sign and numbers up to its position
      value.slice(firstOccurrenceIndex + 1).replace(new RegExp(`\\${allowedSign}`, 'g'), ''); // Remove extra occurrences
  }
  return value;
}

import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "../../services/spinner.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-spinner-component',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  constructor(
    private spinnerService: SpinnerService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinnerService.showSpinner.subscribe(status => {
      status ? this.spinner.show() : this.spinner.hide();
    })
  }
}

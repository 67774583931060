import { AdvancedNumberValueFormatter } from '../../../../shared/helpers/number-formatter.helper';
import { CustomTooltipComponent } from "../../../../shared/components/custom-tooltip.component";

export const detailGridOptions = {
  rowHeight: 20,
  getContextMenuItems: () => ['copy', 'copyWithHeaders'],
  domLayout: 'autoHeight',
  refreshStrategy: 'rows',
  headerHeight: 0,
  // suppressHorizontalScroll: true,
  headerClass: 'no-header-detail-grid',
  gridClass: 'custom_1',
  columnDefs: [
    {
      headerName: '',
      field: 'type',
      colId: 'type',
      filter: false,
      width: 49,
      minWidth: 49,
      cellClass: 'ag-center-aligned-cell',
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      autoHeaderHeight: true,
    },
    {
      headerName: '',
      field: 'traded',
      colId: 'traded',
      width: 80,
      filter: 'false',
      floatingFilter: false,
      autoHeaderHeight: true,
    },
    {
      headerName: '',
      field: 'total',
      colId: 'total',
      width: 70,
      cellClass: 'ag-right-aligned-cell',
      floatingFilter: false,
      filter: false,
      autoHeaderHeight: true,
      valueFormatter: (params: any) => {
        return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
      },
      tooltipComponent: CustomTooltipComponent,
      tooltipField: 'total',
    },
    {
      headerName: '',
      field: 'edso',
      colId: 'edso',
      width: 70,
      cellClass: 'ag-right-aligned-cell',
      floatingFilter: false,
      filter: false,
      autoHeaderHeight: true,
      valueFormatter: (params: any) => {
        return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
      },
      tooltipComponent: CustomTooltipComponent,
      tooltipField: 'edso',
    },
    {
      headerName: '',
      field: 'edso60',
      colId: 'edso60',
      width: 70,
      cellClass: 'ag-right-aligned-cell',
      floatingFilter: false,
      filter: false,
      autoHeaderHeight: true,
      valueFormatter: (params: any) => {
        return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
      },
      tooltipComponent: CustomTooltipComponent,
      tooltipField: 'edso60',
    },
    {
      headerName: '',
      field: 'tar',
      colId: 'tar',
      width: 65,
      cellClass: 'ag-right-aligned-cell',
      floatingFilter: false,
      filter: false,
      autoHeaderHeight: true,
      valueFormatter: (params: any) => {
        return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
      },
      tooltipComponent: CustomTooltipComponent,
      tooltipField: 'tar',
    },
    {
      headerName: '',
      field: 'firstCheck',
      colId: 'firstCheck',
      filter: false,
      width: 20,
      minWidth: 20,
      floatingFilter: false,
      autoHeaderHeight: true,
      cellRenderer: (params: any) => getArrowIcon(params.data.firstCheck),
    },
    {
      headerName: '',
      field: 'edso365',
      colId: 'edso365',
      width: 75,
      cellClass: 'ag-right-aligned-cell',
      floatingFilter: false,
      filter: false,
      autoHeaderHeight: true,
      valueFormatter: (params: any) => {
        return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
      },
      tooltipComponent: CustomTooltipComponent,
      tooltipField: 'edso365',
    },
    {
      headerName: '',
      field: 'secondCheck',
      colId: 'secondCheck',
      filter: false,
      width: 20,
      minWidth: 20,
      floatingFilter: false,
      autoHeaderHeight: true,
      cellRenderer: (params: any) => getArrowIcon(params.data.secondCheck),
    },
    {
      headerName: '',
      field: 'tarPlus',
      colId: 'tarPlus',
      filter: 'false',
      width: 65,
      cellClass: 'ag-right-aligned-cell',
      floatingFilter: false,
      autoHeaderHeight: true,
      valueFormatter: (params: any) => {
        return AdvancedNumberValueFormatter({ number: params.value, isShowZero: false });
      },
      tooltipComponent: CustomTooltipComponent,
      tooltipField: 'tarPlus',
    },
    {
      headerName: '',
      field: 'thirdCheck',
      colId: 'thirdCheck',
      filter: false,
      width: 20,
      minWidth: 20,
      floatingFilter: false,
      autoHeaderHeight: true,
      cellRenderer: (params: any) => getArrowIcon(params.data.thirdCheck),
    },
  ],
  defaultColDef: {
    rowDrag: false,
    floatingFilter: false,
    filter: false,
    autoHeaderHeight: true,
  },
};

export function getArrowIcon(params: any): string {

  let iconHtml = '';
  if (params) {
    iconHtml = `<img alt="" src="/assets/images/icon/arrow-up-green.svg" width="13" height="10" style="vertical-align: middle;"/>`;
  } else {
    iconHtml = params === null ? '-' : `<img alt="" src="/assets/images/icon/arrow-down-red.svg" width="13" height="10" style="vertical-align: middle;"/>`;
  }

  return `
    <div style="display: flex; align-items: center; justify-content: center">
      <span>${iconHtml}</span>
    </div>
  `;
}


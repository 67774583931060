import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { SimpleNumberValueFormatter } from '../helpers/number-formatter.helper';

/**
 * @whatItDoes
 * Drawing tooltip on cell
 *
 * @howToUse
 * just place
 * tooltipComponent: CustomTooltip, <- required
 * tooltipField: "date" <-required
 * tooltipComponentParams: { isStringField: true }, <- optional
 *
 * ### examples
 *  {
 *     headerName: 'GBP',
 *     field: 'bankGbp',
 *     width: 65,
 *     colId: 'bankGbp',
 *     tooltipComponent: CustomTooltip,
 *     tooltipField: "bankGbp",
 *     filter: 'agNumberColumnFilter',
 *     cellClass: 'ag-right-aligned-cell',
 *     filterParams: {
 *       filterOptions: [
 *         {
 *           displayKey: 'all',
 *           displayName: 'all',
 *           predicate: () => true,
 *         },
 *       ],
 *     },
 *
 *     valueFormatter: (params) => {
 *       return AdvancedNumberValueFormatter({number: params.value, isShowZero: false });
 *     },
 *     floatingFilter: true,
 *     floatingFilterComponentParams: {
 *       suppressFilterButton: true,
 *     },
 *     suppressMenu: false,
 *   },
 *
 * {
 *     headerName: 'Date',
 *     field: 'date',
 *     filter: 'agDateColumnFilter',
 *     colId: 'date',
 *     filterParams: dateFilterParams,
 *     valueFormatter: (params) => {
 *       const originalValue = params.value;
 *       let formattedValue = '';
 *       if (originalValue == '~') {
 *         formattedValue = '';
 *       } else {
 *         formattedValue = originalValue;
 *       }
 *       return formattedValue;
 *     },
 *     width: 80,
 *     floatingFilter: true,
 *     cellClass: 'ag-center-aligned-cell',
 *     cellRendererSelector: cellRendererSelector(component, SummaryKeyFxrate.BuyRate),
 *     tooltipComponent: CustomTooltip,
 *     tooltipField: "date",
 *   },
 * {
 *     headerName: 'Trustee ID',
 *     field: 'trusteeId',
 *     colId: 'trusteeId',
 *     width: 90,
 *     floatingFilter: true,
 *     filter: 'agTextColumnFilter',
 *     suppressMovable: true,
 *     floatingFilterComponent: CustomSelectSearchFilterComponent,
 *     floatingFilterComponentParams: {
 *       maxValue: 7,
 *       suppressFilterButton: true,
 *       selectTitle: 'All',
 *       selectArray: [],
 *       defaultValue: '',
 *     },
 *     valueFormatter,
 *     tooltipComponent: CustomTooltip,
 *     tooltipField: "trusteeId",
 *     tooltipComponentParams: { isStringField: true }
 *   }
 */
@Component({
  standalone: true,
  template: ` <div class="custom-tooltip">
    <div>
      <p>{{ value }}</p>
    </div>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        background-color: black;
      }

      .custom-tooltip p {
        margin: 5px;
        color: white;
        padding: 5px;
        font-size: 11px;
        font-weight: 400;
        white-space: nowrap;
      }
    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  params!: { isStringField: boolean } & ITooltipParams;
  value!: string;

  agInit(params: { isStringField: boolean } & ITooltipParams): void {
    this.params = params;
    this.value = (isNaN(+params.value) || params.isStringField) ? params.value : SimpleNumberValueFormatter(params.value);
  }
}

import { isNumber } from 'lodash';

export const PercentValueFormatter = (params: any): string => {
  const originalValue = params.value;
  if (originalValue?.toString().indexOf('%') == -1 && originalValue.toString().indexOf('-') != 0) {
    let formattedValue = '-';
    if (originalValue != 0 && originalValue != null) {
      formattedValue = originalValue.toLocaleString('en-US') + '%';
    }
    return formattedValue;
  } else return originalValue;
};

export const PercentValueFormatter2 = (params: any): string => {
  const originalValue = params.value;
  if (originalValue.toString().indexOf('%') == -1 && originalValue.toString().indexOf('-') != 0) {
    let formattedValue = '-';
    if (originalValue != 0 && originalValue != null) {
      formattedValue =
        (originalValue * 100).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + '%';
    }
    return formattedValue;
  } else return originalValue;
};

export const PercentValueFormatter3 = (formatedValue: any): string => {
  const originalValue = formatedValue;
  if (originalValue.toString().indexOf('%') == -1 && originalValue.toString().indexOf('-') != 0) {
    let formattedValue = '-';
    if (originalValue != 0 && originalValue != null) {
      formattedValue = originalValue.toLocaleString('en-US');
    }
    return formattedValue + '%';
  } else return originalValue;
};

export const PercentValueFormatter4 = (params: any): string => {
  return isNumber(params.value) ? formatPercentValue4(params.value) : '-';
}
const formatPercentValue4 = (value: any): string => {
  const originalValue = +value * 100;
  if (originalValue === null || value === '-' || isNaN(originalValue)) {
    return '-';
  }
  const fractionDigits = originalValue < 10 ? 3 : originalValue < 100 ? 2 : 1;
  return originalValue.toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }) + '%';
};

export const PercentValueFormatter5 = (params: any): string => {
  const originalValue = params.value;
  let formattedValue = '-';
  if (originalValue == 0 || originalValue == null || params.value == '-') {
      return formattedValue;
  }
  if (originalValue && originalValue < 10) {
      formattedValue =
          originalValue.toLocaleString('en-US', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
          }) + '%';
  } else if (originalValue && originalValue > 10 && originalValue < 100) {
      formattedValue =
          originalValue.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }) + '%';
  }
  else {
      formattedValue =
          originalValue.toLocaleString('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
          }) + '%';
  }
  return formattedValue;
}

/**
 * @todo added this for show only fx rate and if Patric approve this function name is fixed and used or remove
 */
export const PercentValueFormatterTest = (params: any): string => {
    let formattedValue = '-';
    if(params.value){
        const originalValue = +params.value * 100;
        if (originalValue == 0 || originalValue == null || params.value == '-') {
            return formattedValue;
        }
        if (originalValue) {
            formattedValue =
                originalValue.toLocaleString('en-US', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                }) + '%';
        }
    }
    return formattedValue;
}

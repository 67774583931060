import { Component } from "@angular/core";
import { ColDef, GridReadyEvent } from "@ag-grid-community/core";
import { TAR_COL_DEFS } from "../../common/constants/tar-columns.const";
import { TarService } from "../../common/services/tar-service";
import { detailGridOptions } from "../../common/constants/detail-grid-options";
import { Router } from "@angular/router";
import { Summary } from "../../../../shared/interfaces/base-response-interface";

@Component({
  selector: "app-tar",
  templateUrl: "./tar.component.html",
  styleUrls: ["./tar.component.scss"]
})
export class TarComponent {
  isLoading = true;
  tarColDefs: ColDef[] = TAR_COL_DEFS(this);
  gridApi: any;
  showInnerGrid: boolean;
  rowData!: any[];
  params: any;
  tarDefaultColDef: ColDef = {
    rowDrag: false,
    suppressMovable: true,
    filter: false,
    floatingFilter: false,
    autoHeaderHeight: true
  };

  detailCellRendererParams: any = {
    detailGridOptions: {
      ...detailGridOptions,

      onCellDoubleClicked: (params: any) => {
        this.handleNavigate(params);
      }
    },

    getDetailRowData: (params: any) => {
      detailGridOptions;
      this.collapseAllRows(params);

      setTimeout(() => {
        params.node.detailGridInfo?.api?.forEachNodeAfterFilter(() => {
          setTimeout(() => {
            params.node.detailGridInfo.api.redrawRows();
          });
        });
      });

      params.successCallback(params.data.detailGridData);
    }
  };

  private summaryData: any;

  constructor(
    private tarService: TarService,
    private router: Router
  ) {
  }

  private collapseAllRows(params?: any): void {
    this.gridApi.forEachNode((node: any) => {
      if (params) {
        if (node.data.id != params.data.id) {
          node.setExpanded(false);
        } else {
          node.setExpanded(true);
        }
      } else {
        node.setExpanded(false);
      }
    });
  }

  private handleNavigate(params: any): void {
    if (['type','firstCheck','secondCheck','thirdCheck','traded'].includes(params.column.colId)) return;

    const queryParams = {
      type: params.data.type,
      traded: params.data.traded,
      ...(params.column.colId === "total" && { traded: params.data.traded }),
      ...(params.column.colId === "edso" && { edso: params.data.edso }),
      ...(params.column.colId === "edso60" && { edso60: params.data.edso60 }),
      ...(params.column.colId === "tar" && { tar: params.data.tar }),
      ...(params.column.colId === "edso365" && { edso365: params.data.edso365 }),
      ...(params.column.colId === "tarPlus" && { tarPlus: params.data.tarPlus }),
    };
    // this.router.navigate(["/tte/transactions"], { queryParams });
    const queryString = new URLSearchParams(queryParams).toString();
    void this.router.navigate([]).then(() => {
      window.open(`/tte/transactions?${queryString}`, '_blank');
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.params = params;
    this.getTarData();
  }

  private getTarData(): void {
    this.tarService.getTarData().subscribe({
      next: (response) => {
        let totalsList: {
          tarLabel: string;
          total: number;
          edsoTotal: number;
          edso60Total: number;
          tarTotal: number;
          edso365Total: number;
          tarPlusTotal: number;
          traded: string;
          oldTotal: number;
          oldEdsoTotal: number;
          oldEdso60Total: number;
          oldTarTotal: number;
          oldEdso365Total: number;
          oldTarPlusTotal: number;
        }[] = [];
        for (const item in response) {
          const totals = response[item].reduce(
            (acc, item) => {
              acc.total += item.total;
              acc.edsoTotal += item.edso;
              acc.edso60Total += item.edso60;
              acc.tarTotal += item.tar;
              acc.edso365Total += item.edso365;
              acc.tarPlusTotal += item.tarPlus;
              acc.traded = item.traded;
              acc.oldTotal += item.total_old;
              acc.oldEdsoTotal += item.edso_old;
              acc.oldEdso60Total += item.edso60_old;
              acc.oldTarTotal += item.tar_old;
              acc.oldEdso365Total += item.edso365_old;
              acc.oldTarPlusTotal += item.tarPlus_old;
              return acc;
            },
            {
              tarLabel: "TaR >",
              total: 0,
              edsoTotal: 0,
              edso60Total: 0,
              tarTotal: 0,
              edso365Total: 0,
              tarPlusTotal: 0,
              traded: "",
              oldTotal: 0,
              oldEdsoTotal: 0,
              oldEdso60Total: 0,
              oldTarTotal: 0,
              oldEdso365Total: 0,
              oldTarPlusTotal: 0
            }
          );
          totalsList.push(
            this.detectTotalArrowType({
              ...totals,
              detailGridData: this.detectArrowType(response[item])
            })
          );
        }

        totalsList = totalsList.map((item, index) => {
          return { ...item, id: index + 1 };
        });
        this.rowData = totalsList.sort((a, b) => (new Date(b.traded) as any) - (new Date(a.traded) as any));
        this.summaryData = this.generateSummaryData();
        this.setPinnedData();
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  changePinnedRowType = (event: { value: keyof Summary; column: any }) => {
    this.setPinnedData(event.value, event.column);
  };

  setPinnedData(key: keyof Summary = "avg", type?: any): void {
    setTimeout(() => {
      const pinnedBottomData = { ...this.summaryData[key], traded: key };
      this.gridApi.setGridOption("pinnedBottomRowData", [pinnedBottomData]);
    }, 500);
  }

  private calculateStats(data: any, field: string) {
    const values = data.map((item: any) => item[field]);
    const sum = values.reduce((acc: any, val: any) => acc + val, 0);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = sum / values.length;
    return { min, max, avg, sum };
  }

  generateSummaryData(): any {
    const fields = ["total", "edsoTotal", "edso60Total", "tarTotal", "edso365Total", "tarPlusTotal"];
    const results: any = {
      sum: {},
      min: {},
      max: {},
      avg: {}
    };
    fields.forEach((field: any) => {
      const { sum, min, max, avg } = this.calculateStats(this.rowData, field);
      results.sum[field] = sum;
      results.min[field] = min;
      results.max[field] = max;
      results.avg[field] = avg;
    });
    return results;
  }

  private detectTotalArrowType(data: any): any {
    const firstCheck = this.totalFirstCheck(data);
    const secondCheck = this.totalSecondCheck(data);
    const thirdCheck = this.totalThirdCheck(data);
    return { ...data, firstCheck, secondCheck, thirdCheck };
  }

  private detectArrowType(data: any): any {
    data = data.map((item: any, index: number) => {
      const firstCheck = this.firstCheck(item);
      const secondCheck = this.secondCheck(item);
      const thirdCheck = this.thirdCheck(item);
      return { ...item, firstCheck, secondCheck, thirdCheck, id: index + 1 };
    });
    return data;
  }

  private totalFirstCheck(data: any): boolean | null {
    const checkedResult = [
      this.detectBigValue(data.total, data.oldTotal),
      this.detectBigValue(data.edsoTotal, data.oldEdsoTotal),
      this.detectBigValue(data.edso60Total, data.oldEdso60Total),
      this.detectBigValue(data.tarTotal, data.oldTarTotal)
    ];

    const counts = checkedResult.reduce(
      (acc, value) => {
        if (value === true) acc.plus++;
        else if (value === null) acc.equals++;
        else if (value === false) acc.minus++;
        return acc;
      },
      { plus: 0, equals: 0, minus: 0 }
    );

    return counts.plus >= 3 ? true : counts.minus >= 3 ? false : null;
  }

  private firstCheck(data: any): boolean | null {
    const checkedResult = [
      this.detectBigValue(data.total, data.total_old),
      this.detectBigValue(data.edso, data.edso_old),
      this.detectBigValue(data.edso60, data.edso60_old),
      this.detectBigValue(data.tar, data.tar_old)
    ];

    const counts = checkedResult.reduce(
      (acc, value) => {
        if (value === true) acc.plus++;
        else if (value === null) acc.equals++;
        else if (value === false) acc.minus++;
        return acc;
      },
      { plus: 0, equals: 0, minus: 0 }
    );

    return counts.plus >= 3 ? true : counts.minus >= 3 ? false : null;
  }

  private totalSecondCheck(data: any): boolean | null {
    return this.detectBigValue(data.edso365Total, data.oldEdso365Total);
  }

  private secondCheck(data: any): boolean | null {
    return this.detectBigValue(data.edso365, data.edso365_old);
  }

  private totalThirdCheck(data: any): boolean | null {
    return this.detectBigValue(data.tarPlusTotal, data.oldTarPlusTotal);
  }

  private thirdCheck(data: any): boolean | null {
    return this.detectBigValue(data.tarPlus, data.tarPlus_old);
  }

  private detectBigValue(first: number, second: number): boolean | null {
    return first > second ? true : first < second ? false : null;
  }

  onRowGroupOpened(event: any) {
    this.showInnerGrid = event.expanded;
  }

  handleCellDoubleClick(event: any) {
    if (event.node && event.node.master) {
      event.node.setExpanded(!event.node.expanded);
    }
  }
}

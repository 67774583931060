<form [formGroup]="form">
  <div class="card-body header-title">
    <div class="row">
      <div class="col-md-7 col-lg-7 col-xl-7 d-flex align-items-center"
           [ngStyle]="styleForContent?.contentPart"
           [ngClass]="classForContent">
        <p [class.showEllipsis]="isSmallScreen"
           [title]="isSmallScreen ? label: ''"
           class="card-title ps-1 me-4 header-allow-overflow"
           style="float: left">{{ label }}
        </p>
        <ng-container *ngIf="infoText">
          <b class="me-2 d-flex align-items-center daterange_sec">{{ infoText }}</b>
        </ng-container>
        <ng-container *ngIf="buttonsConfig.length">
          <div *ngFor="let button of buttonsConfig" class="me-2 d-flex align-items-center daterange_sec">
            <button type="button" class="btn btn-light custom-tab-button text-nowrap"
                    (click)="button.buttonEvent($event)"
                    [disabled]="disabled">
              {{ button.label }}
            </button>
          </div>
        </ng-container>
        <ng-content select="[content]"></ng-content>
      </div>
      <div class="col-md-5 col-lg-5 col-xl-5 text-sm-end d-flex justify-content-end custom-invest-filter"
           [ngStyle]="styleForContent?.filterPart">
        <div
          *ngIf="ccyList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_bank_filter"
        >
          <label for="" class="me-1">Ccy:</label>
          <select
            formControlName="ccy"
            class="custom-filter-select border-radius-100 select_currency"
            placeholder="Select bank code"
          >
            <option value="" selected="selected">All</option>
            <option *ngFor="let list of ccyList" [value]="list.id">
              {{ list.name }}
            </option>
          </select>
        </div>

        <div
          *ngIf="reports?.length"
          class="me-2 d-flex align-items-center daterange_sec reports-filter"
        >
          <label for="" class="me-1">Reports:</label>
          <ng-select
            [items]="reports"
            [virtualScroll]="true"
            bindLabel="name"
            class="custom"
            bindValue="id"
            placeholder="All"
            formControlName="report"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span style="width: 150px" [title]="item.name" tooltip="item.name">{{ item.name }}</span>
            </ng-template>
          </ng-select>
        </div>

        <div
          *ngIf="tradeTransactionList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter etr_filter"
        >
          <label for="" class="me-1">ETR:</label>
          <ng-select
            [items]="tradeTransactionList"
            [virtualScroll]="true"
            bindLabel="name"
            class="custom"
            bindValue="id"
            placeholder="All"
            formControlName="type"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span [title]="item.name" tooltip="item.name">{{ item.name }}</span>
            </ng-template>
          </ng-select>
        </div>
        <div
          *ngIf="clutchList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter"
        >
          <label for="" class="me-1">Clutch:</label>
          <select class="custom-filter-select border-radius-100" formControlName="clutch">
            <option *ngFor="let clutch of clutchList" [value]="clutch?.id">
              {{ clutch?.name }}
            </option>
          </select>
        </div>

        <div
          *ngIf="ledgers?.length"
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter"
        >
          <label for="" class="me-1">Ledger:</label>
          <ng-select
            class="custom"
            style="width: 150px !important"
            [multiple]="false"
            [closeOnSelect]="true"
            [clearable]="false"
            [selectableGroup]="false"
            placeholder="All"
            formControlName="ledger"
          >
            <ng-option *ngFor="let item of ledgers" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
        </div>

        <div
          *ngIf="originatorIds?.length"
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter"
        >
          <label for="" class="me-1">Originator:</label>
          <ng-select
            class="custom"
            style="width: 250px !important"
            [multiple]="false"
            [closeOnSelect]="true"
            [selectableGroup]="false"
            [clearable]="false"
            placeholder="All"
            formControlName="originatorId"
          >
            <ng-option *ngFor="let item of originatorIds" [value]="item.id"
            >{{ item.id ? item.id + ' - ' : '' }}{{ item.name }}
            </ng-option
            >
          </ng-select>
        </div>

        <div
          *ngIf="banksList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_bank_filter bank_mutiselect mutiselect_account"
        >
          <label for="" class="me-1">Bank:</label>
          <ng-select
            [items]="banksList"
            bindLabel="name"
            bindValue="name"
            [multiple]="filtersMultiple"
            [closeOnSelect]="false"
            groupBy=""
            [selectableGroup]="false"
            placeholder="All"
            formControlName="bank"
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items ? items.slice(0, 4) : []">
                <span class="ng-value-label">{{ item.name }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div
          *ngIf="accountsList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_account_filter mutiselect_account"
        >
          <label for="" class="me-1">Account:</label>
          <ng-select
            [items]="accountsList"
            bindLabel="account_no"
            bindValue="account_no"
            [multiple]="filtersMultiple"
            [closeOnSelect]="!filtersMultiple"
            [clearable]="filtersMultiple"
            groupBy="bank_code"
            [selectableGroup]="false"
            placeholder="All"
            formControlName="account"
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items ? items.slice(0, 4) : []">
                <span class="ng-value-label">{{ item.account_no }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter"
          *ngIf="label === 'Entities'"
        >
          <label for="" class="me-1">Role:</label>
          <select class="custom-filter-select border-radius-100" formControlName="role">
            <option value="" selected="selected">All</option>
            <option *ngFor="let role of roleList" [value]="role?.id">
              {{ role?.name }}
            </option>
          </select>
        </div>

        <div
          *ngIf="!noDateAndSearch && !noSearch"
          class="me-2 d-flex align-items-center daterange_sec search_filter"
        >
          <input
            type="text"
            placeholder="Search"
            class="me-0 btn-light text-start custom-tab-button"
            formControlName="search"
          />
        </div>

        <div class="me-2 d-flex align-items-center daterange_sec">
          <button
            *ngIf="showNewButton"
            type="button"
            class="btn btn-light custom-tab-button text-nowrap"
            (click)="openAddNewModal('add')"
            [disabled]="disabled"
          >
            {{ label === "Cash Investments" ? "Add Transaction" : "New" }}
          </button>

          <button
            *ngIf="showNewButton && label === 'Cash Investments'"
            type="button"
            class="btn btn-light custom-tab-button text-nowrap"
            (click)="openAddNewModal('add Investor')"
            [disabled]="disabled"
          >
            Add Investor
          </button>
          <div
            *ngIf="rangeSelector"
            class="m-0 date-range d-flex align-items-center single_select_filter"
          >
            <button
              *ngIf="!noDateAndSearch"
              type="button"
              class="btn btn-light custom-tab-button"
              (click)="onShowData('All')"
              [ngClass]="{ active: currentFilter === 'All' }"
              [disabled]="disabled"
            >
              All
            </button>
            <button
              *ngIf="!noDateAndSearch"
              type="button"
              class="btn btn-light custom-tab-button"
              (click)="onShowData('Month')"
              [ngClass]="{ active: currentFilter === 'Month' }"
            >
              Month
            </button>
            <button
              type="button"
              class="btn btn-light custom-tab-button"
              (click)="onShowData('Year')"
              [ngClass]="{ active: currentFilter === 'Year' }"
              [disabled]="disabled"
            >
              Year
            </button>
            <button
              *ngIf="!noDateAndSearch"
              type="button"
              class="btn btn-light custom-tab-button"
              (click)="onShowData('Date')"
              [ngClass]="{ active: currentFilter === 'Date' }"
              [disabled]="disabled"
            >
              Range
            </button>
          </div>
          <div
            *ngIf="currentFilter === 'Month'"
            class="m-0 date-range d-flex align-items-center single_select_filter"
          >
            <label for="" class="me-1">Month:</label>
            <select
              class="custom-filter-select border-radius-100"
              id="MonthList"
              formControlName="month"
            >
              <option value="" selected="selected">All</option>
              <option *ngFor="let list of monthList" [value]="list?.date">
                {{ list?.date }}
              </option>
            </select>
          </div>
          <div
            class="date-range d-flex align-items-center m-0 single_select_filter"
            *ngIf="currentFilter === 'Year'"
          >
            <label for="" class="me-1">Year:</label>
            <select
              class="custom-filter-select border-radius-100"
              id="financialList"
              formControlName="year"
            >
              <option *ngFor="let list of financialList" [value]="list?.id">
                {{ list?.name }}
              </option>
            </select>
          </div>
          <div
            class="d-flex align-items-center single_select_filter"
            *ngIf="currentFilter !== 'Year' && currentFilter !== 'Month' && rangeSelector"
          >
            <label for="" class="me-1">Date:</label>
            <input
              type="search"
              class="form-control from-daterange mt-0"
              placeholder="Start Date - End Date"
              bsDaterangepicker
              [bsConfig]="{
                rangeInputFormat: 'YYYY-MM-DD',
                dateInputFormat: 'YYYY-MM-DD',
                showWeekNumbers: false
              }"
              formControlName="startDate"
              autocomplete="off"
            />
          </div>
        </div>
        <select
          *ngIf="isShowPerPage"
          class="custom-invest-select border-radius-100 ng-pristine ng-valid ng-touched filter-pagesize selectbox-per-page"
          id="pageSize"
          formControlName="perPage"
        >
          <option *ngFor="let size of pageSizes" [value]="size" class="ng-star-inserted text-end">
            {{ size | number: "1.0" : "en-US" }} per page
          </option>
        </select>
        <div *ngIf="isShowActionButtonGroup" class="action-buttons-group">
          <button type="button" class="btn custom-invest-button print_btn" (click)="print.emit()">
            <img alt="" src="./assets/images/icon/printer.svg" width="16px" alt="printer" />
          </button>
          <button type="button" class="btn custom-invest-button download_btn" [disabled]="disabled">
            <img
              alt=""
              (click)="export.emit()"
              src="./assets/images/icon/download_icon.svg"
              width="16px"
              alt="download"
            />
          </button>

          <button
            type="button"
            (click)="resetFormGroup()"
            class="btn custom-invest-button"
            [disabled]="disabled"
          >
            <img alt="" src="./assets/images/icon/replay.svg" width="16px" />
          </button>
          <button type="button" class="btn custom-invest-button me-0 document-print" disabled>
            <img alt="" src="./assets/images/icon/document.svg" width="16px" alt="document" />
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

export enum SummaryKeyInvestments {
  Payment_Out = 'bankPayment',
  Receipt_In = 'bankReceipt',
  Face_Value = 'faceValue',
  Buy_Rate = 'buyRate',
  Commission = 'commission',
  Months = 'months',
}

export enum SummaryKeyFxrate {
  BuyRate = 'buyRate',
  Gbp = 'gbp',
  Usd = 'usd',
  Eonia = 'eonia',
  Libor = 'libor',
  Obfr = 'obfr',
  Demand = 'demand',
  Fixed = 'fixed',
  Term = 'term',
  BankEurTotal = 'bankEurTotal',
  BankUsd = 'bankUsd',
  BankGbp = 'bankGbp',
  BankEur = 'bankEur',
}

export enum SummaryKeyTradeTransactions {
  PTV = 'ptv',
  SellRate = 'sellRate',
  FaceValue = 'faceValue',
  EurFacevalue = 'eurFacevalue',
  Ldc = 'ldc',
  Reserves = 'reserves',
  Outstanding = 'outstanding',
  DueOutstanding = 'dueOutstanding',
  EurPurchased = 'eurPurchased',
  Deferred = 'deferredIncome',
  TradedDate = 'tradedDate',
  EDSO = 'edso',
  RDSO = 'rdso',
  ADSO = 'adso',
  Months = 'months',
  PurchasedValue = 'purchasedValue',
  SettledBalance = 'settledBalance',
  ClosedBalance = 'closedBalance',
  EurReserves = 'eurReserves',
  Purchased = 'purchased',
  EurOutstanding = 'eurOutstanding',
  Deposit = 'deposit',
  EurDeposit = 'eurDeposit',
  Investor = 'investor',
  EurInvestor = 'eurInvestor',
  EOT = 'eot',
  EurEOT = 'eurEot',
  Day180Reserves = 'day180Reserves',
  EurDay180Reserves = 'eurDay180Reserves',
  EurSettled = 'eurSettled',
  EurDeferredIncome = 'eurDeferredIncome',
  SettledCommission = 'settledCommission',
  EurSettledCommission = 'eurSettledCommission',
  EurLDC = 'eurLDC',
  LossPremium = 'lossPremium',
  EurLossPremium = 'eurLossPremium',
  MarginCallSettled = 'marginCallSettled',
  EurMarginCallSettled = 'eurMarginCallSettled',
  MarginSettled = 'marginSettled',
  EurMarginSettled = 'eurMarginSettled',
  TradeProfit = 'tradeProfit',
  EurTradeProfit = 'eurTradeProfit',
  DayRule = 'dayRule',
  EurDayRule = 'eurDayRule',
  FxGainSettled = 'fxGainSettled',
  EurFxGainSettled = 'eurFxGainSettled',
  FxGainReserve = 'fxGainReserve',
  EurFxGainReserve = 'eurFxGainReserve',
  MarginCallClosed = 'marginCallClosed',
  EurMarginCallClosed = 'eurMarginCallClosed',
  Instalment = 'instalment',
  EurInstalment = 'eurInstalment',
  InstalmentPrincipal = 'instalmentPrincipal',
  EurInstalmentPrincipal = 'eurInstalmentPrincipal',
  InstalmentProfit = 'instalmentProfit',
  EurInstalmentProfit = 'eurInstalmentProfit',
  IncomeValue = 'incomeValue',
  EurIncomeValue = 'eurIncomeValue'
}

export enum SummaryKeyFinancialStatements {
  Payment_Amount = 'paymentAmount',
  Receipt_Amount = 'receiptAmount',
  Yield = 'yield',
  Balance = 'balance',
}

export enum SummaryKeyDashboardEntitySummary {
  AccountNumber = 'accountNumber',
  Iban = 'iban',
  ImportedRecords = 'importedRecords',
  Orphans = 'orphans',
  TotalBanks = 'totalBanks',
}

export enum SummaryKeyTradeCommissionSummary {
  PaymentAmount = 'paymentAmount',
  ReceiptAmount = 'receiptAmount',
  Principal = 'principal',
  CommissionRate = 'commissionRate',
  CommissionAmount = 'commissionAmount',
  Balance = 'balance',
}

export enum AllowedFormatsEnum {
  PDF = 'pdf',
  WORD = 'docx',
  EXCEL = 'xlsx',
  CSV = 'csv',
}

export enum InvestorTypeEnum {
  Organization = 1,
  Person = 2,
}

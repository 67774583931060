import { Component } from '@angular/core';
import { ColDef, DomLayoutType } from '@ag-grid-community/core';
import { COL_DEFS } from './col-defs';
import { CLUTCH } from '../shared/constants/helpers.const';
import { GridOptions } from 'ag-grid-community';
import { BaseComponent } from './base.component';
import { queryCleanerModel } from '../shared/helpers/query-cleaner-model';
import { SelectItem } from '../shared/interfaces/select-item.interface';
@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent extends BaseComponent {
  accounts: any = [];
  currentFilter: any = 'All';
  selectedMonth: any = '';
  selectedFinancialYear: any = '';
  override colDefs: ColDef[] = COL_DEFS;
  override postings = true;
  defaultColDef: ColDef = {
    resizable: true,
    floatingFilter: false,
    filter: false,
    autoHeight: true,
  };
  paginationPageSize = 50;
  currentPageIndex: any = 0;
  totalPage: any = 0;
  totalRecords: any = 0;
  recordsPerPage: any = 100;
  totalPageString: any;
  totalRecordsDisplay: any = 0;
  faceValue = '';
  result: number | undefined;
  domLayoutType: DomLayoutType = 'normal';
  clutchList = CLUTCH;
  dateRange: any;
  savedFilterModel: Partial<any>;
  index = 0;
  gridOptions: GridOptions = {
    getRowClass: (params) => {
      if (params.data?.modelNo === '') {
        return 'check-row';
      }
      return '';
    },
  };
  allCurrencyTypeTradeIdData: { ccy: string; type: string; tradeIds: string[] }[];
  ccy: SelectItem[];
  types: SelectItem[];

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  initTable(response: any): void {
    this.rowData = response;
    this.isLoading = false;
  }

  override getData(params: any, pageChanged?: boolean): void {
    this.paginationPageSize = params.perPage;
    this.recordsPerPage = params.perPage;
    this.isLoading = true;
    this.ftService.getControlTradeFinance(params).subscribe((data) => {
      const rowData = data.items;
      let lastModelNo: any = null;
      const newRowData: any[] = [];

      rowData.forEach((row) => {
        // Insert separator row if modelNo changes
        if (lastModelNo !== null && lastModelNo !== row.modelNo) {
          newRowData.push({
            ccy: '',
            etrCount: null,
            eurValueEtr: null,
            eurValuePosting: null,
            financeTransaction: '',
            id: 0,
            journalNo: '',
            modelNo: '',
            postingCount: null,
            tradeEtr: null,
            tradeEtr3: null,
            tradeId: '',
            transaction: '',
            type: '',
            valueEtr: null,
            valuePosting: null,
          });
        }

        newRowData.push(row);
        lastModelNo = row.modelNo;
      });

      // Assign new indices to maintain unique IDs
      newRowData.forEach((item, idx) => {
        item.id = idx + 1;
      });

      this.rowData = newRowData;

      this.initTable(this.rowData);
      this.totalRecordsDisplay = data.meta.total;
      this.paginationPageSize = data.meta.total;
      this.totalPageString = 1;
      this.totalPage = 1;
    });
  }

  override onFilterChange(event: any) {
    if (event.api != undefined) {
      const filterModel = this.gridApi.getFilterModel();
      this.queryParams = queryCleanerModel(
        this.queryParams,
        filterModel,
        event.columns[0].colId,
        this.colDefs.map((value) => value.colId) as string[],
      );

      this.setColDefs();
      event.api.showLoadingOverlay();
      this.getData({ ...this.queryParams, ...this.filterQueryParams });
    }
  }

  override filterChanged($event: any) {
    super.filterChanged($event);
  }
}

import { Component } from '@angular/core';
import { ColDef, DomLayoutType } from '@ag-grid-community/core';
import { COL_DEFS } from './col-defs';
import { CLUTCH } from '../shared/constants/helpers.const';
import { GridOptions } from 'ag-grid-community';
import { BaseComponent } from './base.component';
import { queryCleanerModel } from '../shared/helpers/query-cleaner-model';
import { SelectItem } from '../shared/interfaces/select-item.interface';
@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent extends BaseComponent {
  accounts: any = [];
  currentFilter: any = 'All';
  selectedMonth: any = '';
  selectedFinancialYear: any = '';
  override colDefs: ColDef[] = COL_DEFS;
  override postings = true;
  defaultColDef: ColDef = {
    resizable: true,
    floatingFilter: false,
    filter: false,
    autoHeight: true,
  };
  paginationPageSize = 50;
  currentPageIndex: any = 0;
  totalPage: any = 0;
  totalRecords: any = 0;
  recordsPerPage: any = 100;
  totalPageString: any;
  totalRecordsDisplay: any = 0;
  faceValue = '';
  result: number | undefined;
  domLayoutType: DomLayoutType = 'normal';
  clutchList = CLUTCH;
  dateRange: any;
  savedFilterModel: Partial<any>;
  index = 0;
  gridOptions: GridOptions = {
    getRowClass: (params) => {
      if (params.data?.modelNo === '') {
        return 'check-row';
      }
      return '';
    },
  };
  selectedCurrencyInFilter: string;
  allCurrencyTypeTradeIdData: { ccy: string; type: string; tradeIds: string[] }[];
  ccy: SelectItem[];
  types: SelectItem[];

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.ftService.getDistinct().subscribe((distincts) => {
      this.allCurrencyTypeTradeIdData = distincts;
      const ccy = Array.from(new Set(distincts.map((distinct) => distinct.ccy)));
      const types = Array.from(new Set(distincts.map((distinct) => distinct.type)));
      this.ccy = ccy.map((ccy) => ({ id: ccy, name: ccy }));
      this.types = types.map((type) => ({ id: type, name: type }));
      this.colDefs.find((value) => value.colId === 'ccy')!.floatingFilterComponentParams.selectArray = this.ccy;

      if (this.colDefs?.find((value) => value.colId === 'type')?.floatingFilterComponentParams?.selectArray) {
        this.colDefs.find((value) => value.colId === 'type')!.floatingFilterComponentParams.selectArray = this.types;
      }
      this.setColDefs();
    });
  }

  initTable(response: any): void {
    this.rowData = response;
    this.isLoading = false;
  }

  override getData(params: any, pageChanged?: boolean): void {
    this.paginationPageSize = params.perPage;
    this.recordsPerPage = params.perPage;
    this.isLoading = true;

    this.ftService.getControlTradeFinance(params).subscribe((data) => {
      this.rowData = data.items;
      let lastModelNo: any = null;
      this.rowData.forEach((row, idx) => {
        if (lastModelNo !== null && lastModelNo !== row.modelNo) {
          const newRow = {
            ccy: '',
            etrCount: null,
            eurValueEtr: null,
            eurValuePosting: null,
            financeTransaction: '',
            id: 0,
            journalNo: '',
            modelNo: '',
            postingCount: null,
            tradeEtr: null,
            tradeEtr3: null,
            tradeId: '',
            transaction: '',
            type: '',
            valueEtr: null,
            valuePosting: null,
          };
          this.rowData.splice(idx, 0, newRow);
          this.index = idx + 1;
        }
        lastModelNo = row.modelNo;
      });
      let index = 0;
      this.rowData.forEach((item) => {
        index++;
        item.id = index;
      });

      this.initTable(this.rowData);
      this.totalRecordsDisplay = data.meta.total;
      this.paginationPageSize = data.meta.total;
      this.totalPageString = 1;
      this.totalPage = 1;
    });
  }

  override onFilterChange(event: any) {
    if (event.api != undefined) {
      const filterModel = this.gridApi.getFilterModel();
      this.queryParams = queryCleanerModel(
        this.queryParams,
        filterModel,
        event.columns[0].colId,
        this.colDefs.map((value) => value.colId) as string[],
      );
      if (filterModel['ccy']?.filter) {
        this.loadTypeFromCurrency(filterModel['ccy'].filter);
      } else {
        this.colDefs.find((value) => value.colId === 'ccy')!.floatingFilterComponentParams.selectArray = this.ccy;
      }
      if (filterModel['type']?.filter) {
        this.loadTradeIds(filterModel['type'].filter);
      } else {
        this.colDefs[2].floatingFilterComponentParams.defaultValue = '';
        this.colDefs[3].floatingFilter = false;
        delete filterModel['tradeId'];
        this.gridApi.setFilterModel(filterModel);
        this.setColDefs();
      }

      if (!filterModel['tradeId']?.filter) {
        return;
      } else {
        this.setColDefs();
      }
      event.api.showLoadingOverlay();
      this.getData({ ...this.queryParams, ...this.filterQueryParams });
    }
  }

  override filterChanged($event: any) {
    super.filterChanged($event);
  }

  loadTradeIds(selectedType: string) {
    if (selectedType) {
      this.colDefs[2].floatingFilterComponentParams.defaultValue = selectedType;
      this.colDefs[3].floatingFilter = true;
      this.setColDefs();
    }
  }

  loadTypeFromCurrency(selectedCurrency: string, selectedType?: string) {
    this.selectedCurrencyInFilter = selectedCurrency;

    if (selectedCurrency != '') {
      const types: any[] = [];
      const fda = this.allCurrencyTypeTradeIdData.filter((item: any) => item.ccy == selectedCurrency);
      fda.forEach((items) => {
        types.push({ id: items.type, name: items.type });
      });
      this.colDefs[1].floatingFilterComponentParams.defaultValue = selectedCurrency;
    }
    this.setColDefs();
  }
}

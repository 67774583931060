import { Component, EventEmitter, Output } from '@angular/core';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [NgbDropdownModule],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent {
  @Output() toggleSidebar = new EventEmitter<void>();
  userName: string | null = localStorage.getItem('name');

  constructor(
    private modalService: NgbModal,
    public router: Router,
    private authService: MsalService,
  ) {}

  public logout() {
    this.authService
      .logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      })
      .subscribe(() => {
        localStorage.removeItem('token');
      });
  }
}

<div class="ps-4 me-2 gap-3 pb-2 d-flex align-items-center">
  <div class="width-7p">
    <select class="custom-form-select form-select-g" id="period" [(ngModel)]="selectedPeriod"
            (ngModelChange)="onChangeQueryParam('period', $event)">
      <option value="" disabled *ngIf="!selectedPeriod">View By</option>
      <option *ngFor="let item of periods" [value]="item.id">{{ item.name }}</option>
    </select>
  </div>
  <div class="width-6p">
    <select class="custom-form-select form-select-g" id="type" [(ngModel)]="selectedETR"
            (ngModelChange)="onChangeQueryParam('type', $event)">
      <option *ngFor="let item of etrTypes" [value]="item.id">{{ item.name }}</option>
    </select>
  </div>

  <div class="width-20p">
    <ng-select class="custom-form-select" [(ngModel)]="selectedCredebtor" [multiple]="false"
               [closeOnSelect]="true" [selectableGroup]="false" placeholder="Credebtor"
               (ngModelChange)="onChangeQueryParam('credebtorId', $event)">
      <ng-option *ngFor="let item of credebtorsIds" [value]="item.id">{{ item.id }}- {{ item.name }}
      </ng-option>
    </ng-select>
  </div>

  <div class="width-20p">
    <ng-select class="custom-form-select" [(ngModel)]="selectedOriginator" [multiple]="false"
               [closeOnSelect]="true" [selectableGroup]="false" placeholder="Originator"
               (ngModelChange)="onChangeQueryParam('originatorId', $event)">
      <ng-option *ngFor="let item of originatorIds" [value]="item.id">{{ item.id }}- {{ item.name }}
      </ng-option>
    </ng-select>
  </div>

</div>
<div class="row pl-5">
  <ag-charts [options]="multipleLineOptions" class=""></ag-charts>
</div>
<div class="row pl-5 d-flex justify-content-end">
  <div class="time-periods me-4">
    <a *ngFor="let period of ['1M', '6M', '1Y', '5Y', '10Y', '25Y', 'all']"
       (click)="onSelectTimePeriod(period)">
      {{ period === 'all' ? 'All' : period }}
    </a>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from 'src/app/shared/header/navigation.component';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';
import { MsalService } from '@azure/msal-angular';

//declare var $: any;

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [RouterModule, SidebarComponent, NavigationComponent, CommonModule, NgbCollapseModule, NgbDropdownModule],
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
  providers: [NgbDropdownConfig],
})
export class FullComponent implements OnInit {
  constructor(
    public router: Router,
    config: NgbDropdownConfig,
    private authService: MsalService,
  ) {
    config.placement = 'top-start';
    config.autoClose = false;
  }
  public isCollapsed = false;
  public innerWidth = 0;
  public defaultSidebar = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';
  public userName = '';

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard']);
    }
    this.userName = this.authService.instance.getActiveAccount()?.name ?? '';
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'full';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }
  onLogoutClick() {
    const account = this.authService.instance.getActiveAccount();
    this.authService.logoutRedirect({ account: account });
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }
}

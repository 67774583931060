import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatPhoneNumber } from 'src/app/shared/helpers/utils';

@Injectable({ providedIn: 'root' })
export class CreditorFormProvider {
  creditorForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  initEntityForm(data: any): void {
    this.creditorForm = this.fb.group({
      roleId: [data?.id ? this.detectRoleValues(data?.entityRoles) : null, [Validators.required]],
      name: [data?.name, [Validators.required]],
      firstName: [data?.people.length ? data?.people[0].firstName : null, [Validators.required]],
      lastName: [data?.people.length ? data?.people[0].lastName : null, [Validators.required]],
      tradingAs: [data?.tradeName], 
      previousName: [null], 
      type: [data?.type || 2, [Validators.required]],
      isPrevious: [data?.isPrevious],
      phoneCountryCode: [data?.entityProperties.length ? formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))[0] : null],
      phoneAreaCode: [data?.entityProperties.length ? formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))[1] : null],
      phoneNumber: [data?.entityProperties.length ? formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))[2] : null],
      email: [data?.entityProperties.length ? this.detectProperty(data?.entityProperties, 'email') : null, [Validators.email]],
      bankId: [data?.entityRoles[0]?.bankAccounts?.[0]?.bankId], 
      bankName: [data?.entityRoles[0]?.bankAccounts?.[0]?.name], 
      accountNumber: [data?.entityRoles[0]?.bankAccounts?.[0]?.account],
      sortCode: [data?.entityRoles[0]?.bankAccounts?.[0]?.sortCode],
      swiftBic: [data?.entityRoles[0]?.bankAccounts?.[0]?.swiftBic],
      iban: [data?.entityRoles[0]?.bankAccounts?.[0]?.iban],
      address1: [data?.addresses[0]?.lineOne, [Validators.required]],
      address2: [data?.addresses[0]?.lineTwo],
      area: [data?.addresses[0]?.area],
      city: [data?.addresses[0]?.city, [Validators.required]],
      state: [data?.addresses[0]?.state],
      postCode: [data?.addresses[0]?.zipCode],
      country: [data?.addresses[0]?.country, [Validators.required]],
      POFile: [data?.documents?.POFile],
      PoDFile: [data?.documents?.PoDFile],
      etrFile: [data?.documents?.etrFile],
      fxFile: [data?.documents?.fxFile],
      organisationFile: [data?.documents?.organisationFile],
      proofFile: [data?.documents?.proofFile],
      signedFile: [data?.documents?.signedFile],
      comments: [null], 
    });
  }


  private detectProperty(data: any[], propertyId: string): string {
    const property = data.find((item) => item.propertyId === propertyId);
    return property?.propertyValue || '';
  }

  detectProperty2(data: any[], propertyId: string, propertyTitle?: string): string[] {
    const result = data
      .filter((item) => item.propertyId === propertyId && (!propertyTitle || item.propertyTitle === propertyTitle))
      .map((item) => item.propertyValue);
    return result || '';
  }

  detectRoleValues(data: any[]): string[] {
    return data.reduce((acc, curr) => {
      if (curr.role?.name) {
        acc.push(curr.role.name);
      }
      return acc
    }, [])
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Investment } from 'src/app/shared/interfaces/investments-response.interface';
import { getFormData } from '../../shared/helpers/utils';
import { InvestmentsHttpService } from '../common/services/investments-http.service';
import { COUNTRY_LIST } from 'src/app/shared/constants/countryList.const';
import { InvestorFormProvider } from '../Investor-form-provider';
import { NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { InvestorTypeEnum } from '../../shared/enums/common.enum';

@Component({
  selector: 'app-add-edit-investor',
  templateUrl: './add-edit-investor.component.html',
  styleUrls: ['./add-edit-investor.component.scss'],
})
export class AddEditInvestorComponent implements OnInit, OnChanges {
  investorForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    intermediaryName: 'Intermediary Name',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    email: 'Email',
    dob: 'Date Of Birth',
    address: 'Address',
    postCode: 'Post Code',
    city: 'City',
    state: 'County',
    country: 'Country',
    homePNumber: 'Home Phone',
    officePNumber: 'Office Phone',
    mobileNumber: 'Mobile Phone',
    completed: 'Completed',
    ccy: 'Currency',
    amount: 'Amount',
    quotedRate: 'Quoted Rate',
    trustee: 'Trustee',
    savings: 'Savings',
    area: 'Area',
    swift: 'SWIFT BIC',
    iban: 'IBAN',
    sortCode: 'Sort Code',
    account: 'Account',
    organization: 'Organization',
  };
  private subscriptions: Subscription[] = [];
  isSubmitted = false;
  countryList: { id: string; name: string; countryCode: string }[] = COUNTRY_LIST;
  allBankData: { id: string; name: string }[] = [];
  intermediaryIdList: { id: string; name: string }[];
  intermidiaryListSnapshot: { id: string; name: string }[];

  @Input() investmentData: Investment;
  @Input() isPerson: boolean;
  @Output() createUpdateEvent = new EventEmitter<boolean>();
  @Output() createUpdateEventResponse = new EventEmitter<any>();
  investorGeneratedList: {
    id: string;
    name: string;
    intermediary: { id: string; name: string };
  }[] = [];
  investorIdListSnapshot: {
    id: string;
    name: string;
    intermediary: { id: string; name: string };
  }[];
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  constructor(
    private investmentsHttpService: InvestmentsHttpService,
    private investorFormProvider: InvestorFormProvider,
    private config: NgbDatepickerConfig,
  ) {}

  ngOnInit(): void {
    this.investorForm = this.investorFormProvider.initInvestorForm(this.investmentData);
    this.getDropDownsData();
    this.initDatePickerConfig();
    this.subscribeToSortCodeValueChange();
    this.subscribeToValueChanges();
    this.updateValidationOnSubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isPerson'] && this.investorForm) {
      this.investorForm?.get('type')?.patchValue(changes['isPerson'].currentValue ? InvestorTypeEnum.Person : InvestorTypeEnum.Organization);
      this.changeFormValidators(this.isPerson);
    }
  }

  changeFormValidators(isPerson: boolean): void {
    const controls = ['lastName'];

    controls.forEach((control) => {
      const formControl = this.investorForm.get(control);
      if (!formControl) return;

      if (isPerson) {
        formControl.addValidators([Validators.required]);
      } else {
        formControl.clearValidators();
      }
      formControl.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.investorForm.invalid) {
      return;
    }
    let rawData = this.investorForm.value;
    rawData = getFormData(rawData);
    if (rawData.birthDate) {
      const { day, month, year } = rawData.birthDate;
      rawData.birthDate = moment(`${year}-${month}-${day}`, 'YYYY-M-D').format('YYYY-MM-DD');
    }
    const apiData = this.createApiData(rawData);
    if (!this.isPerson) apiData.lastName = '';
    this.doCreate(apiData);
  }

  doCreate(data: any): void {
    this.investmentsHttpService.postData('entity/investor', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.investorForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investor successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
        this.createUpdateEvent.emit(true);
        this.createUpdateEventResponse.emit(response);
      },
    });
  }

  createApiData(rawData: any): any {
    const data = {
      intermediaryId: rawData?.intermediaryId || '',
      firstName: rawData.firstName || '',
      lastName: rawData.lastName || '',
      email: rawData.email || '',
      birthDate: rawData.birthDate || '',
      address: {
        country: rawData.country || '',
        countryCode: rawData.countryCode || 'IR',
        state: rawData.state || '',
        city: rawData.city || '',
        area: rawData.area || '',
        lineOne: rawData.lineOne || '',
        lineTwo: rawData.lineTwo || '',
        zipCode: rawData.zipCode ? rawData?.zipCode?.toString() : '',
      },
      phoneNumbers: {
        home: rawData.home || '',
        office: rawData.office || '',
        mobile: rawData.mobile || '',
      },
      bankAccount: {
        swiftBic: rawData.swiftBic || '',
        sortCode: rawData?.sortCode?.toString() || '',
        account: rawData.account || '',
        iban: rawData.iban || '',
      },
      type: rawData.type,
    };
    return this.removeEmpty(data);
  }

  removeEmpty(obj: any): any {
    const result = Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v !== null && v !== '')
        .map(([k, v]) => [k, v && typeof v === 'object' ? this.removeEmpty(v) : v]),
    );
    for (const key in result) {
      if (result[key] && typeof result[key] === 'object' && Object.keys(result[key]).length === 0) {
        delete result[key];
      }
    }
    return result;
  }

  private getDropDownsData(): void {
    this.investmentsHttpService.getEntityBanks().subscribe((response: any) => {
      response.forEach((item: any) => {
        this.allBankData.push({
          id: item.id,
          name: item.name,
        });
      });
    });

    this.investmentsHttpService.getInvestmentsFilterData().subscribe((investmentsFilterData) => {
      this.intermediaryIdList = [
        ...(investmentsFilterData.intermediariesWithInvestors.map((value) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
          nameForSearch: `${value.id}-${value.name}`,
          investors: value.investors,
        })) || []),
      ];
      this.intermidiaryListSnapshot = this.intermediaryIdList;
    });
  }

  closeModal() {
    this.isSubmitted = false;
    this.closeDialogEvent.emit(false);
  }

  onSelectCountry(event: any): void {
    const selectedCountry = event.target.value;
    const country = this.countryList.find((c) => c.name === selectedCountry);
    this.investorForm.patchValue({ countryCode: country ? country.countryCode : '' });
  }

  handleIntermediaryChange(event: any): void {
    if (!event) {
      this.intermediaryIdList = this.intermidiaryListSnapshot;
    } else {
      this.investorGeneratedList = event?.investors || this.investorIdListSnapshot;
    }
  }

  onBankChange(event: any): void {
    const selectedId = event.target.value;
    const selectedBank = this.allBankData.find((bank) => bank.id == selectedId);
    const selectedBankName = selectedBank ? selectedBank.name : '';
    this.investorForm.get('name')?.setValue(selectedBankName);
  }

  private getCurrentDate(): NgbDate {
    const today = new Date();
    return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  initDatePickerConfig() {
    const currentDate = this.getCurrentDate();
    this.config.minDate = { year: 1910, month: 1, day: 1 };
    this.config.maxDate = currentDate;
  }

  resetDatePickerConfig() {
    this.config.minDate = { year: 1, month: 1, day: 1 };
    this.config.maxDate = { year: 9999, month: 12, day: 31 };
  }

  private subscribeToSortCodeValueChange() {
    this.subscribeToFormControl('sortCode', (value: string) => {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      const formattedValue = this.formatSortCode(sanitizedValue);
      this.investorForm.get('sortCode')?.patchValue(formattedValue, { emitEvent: false });
    });
  }

  private subscribeToValueChanges() {
    this.subscribeToFormControl('sortCode', this.updateValidationOnSubscribe.bind(this));
    this.subscribeToFormControl('account', this.updateValidationOnSubscribe.bind(this));
    this.subscribeToFormControl('iban', this.updateValidationOnSubscribe.bind(this));
  }

  private subscribeToFormControl(controlName: string, callback: (value: any) => void) {
    const control = this.investorForm.get(controlName);
    const subscription = control?.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(callback);

    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  private updateValidationOnSubscribe() {
    const sortCodeControl = this.investorForm.get('sortCode');
    const accountControl = this.investorForm.get('account');
    const ibanControl = this.investorForm.get('iban');
    const sortCodeValue = sortCodeControl?.value;
    const accountValue = accountControl?.value;
    const ibanValue = ibanControl?.value;
    if (ibanValue) {
      sortCodeControl?.clearValidators();
      accountControl?.clearValidators();
      ibanControl?.setValidators([Validators.required]);
    } else if (sortCodeValue || accountValue) {
      ibanControl?.clearValidators();
      sortCodeControl?.setValidators([Validators.maxLength(14), Validators.required]);
      accountControl?.setValidators([Validators.required]);
    } else {
      sortCodeControl?.setValidators([Validators.maxLength(14), Validators.required]);
      accountControl?.setValidators([Validators.required]);
      ibanControl?.setValidators([Validators.required]);
    }
    sortCodeControl?.updateValueAndValidity({ emitEvent: false });
    accountControl?.updateValueAndValidity({ emitEvent: false });
    ibanControl?.updateValueAndValidity({ emitEvent: false });
  }

  private formatSortCode(value: string): string {
    return value.replace(/(\d{2})(?=\d)/g, '$1-');
  }

  ngOnDestroy() {
    this.resetDatePickerConfig();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

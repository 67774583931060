import { HttpService } from '../../../services/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { InvestmentsQueryParams } from '../../../shared/interfaces/investments-query-params';
import { Investment, InvestmentsResponseInterface } from '../../../shared/interfaces/investments-response.interface';
import { Summary } from '../../../shared/interfaces/bank-response.interface';
import { EntityBanks, InvestmentsDistinctFilterValuesInterface } from '../interfaces/investments-distinct-filter-values.interface';
import { InvestorsByIdResponseInterface } from 'src/app/financial-statement/common/interfaces/Investors-response.interface';

@Injectable({ providedIn: 'root' })
export class InvestmentsHttpService extends HttpService {
  getInvestmentsFilterData(): Observable<InvestmentsDistinctFilterValuesInterface> {
    return this.http.get<InvestmentsDistinctFilterValuesInterface>(`${this.baseUrl}investments/distinct-filter-values`);
  }

  getCurrencyFilterData(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}currencies`);
  }

  getInvestmentsData(params: Partial<InvestmentsQueryParams>): Observable<InvestmentsResponseInterface> {
    const baseUrl = this.baseUrl + 'investments';
    return this.http.get<InvestmentsResponseInterface>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  getInvestmentsDataById(investmentsId: number): Observable<Investment> {
    const baseUrl = `${this.baseUrl}investments/${investmentsId}`;
    return this.http.get<Investment>(baseUrl);
  }

  getInvestmentsSummary(params: Partial<InvestmentsQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${this.baseUrl}investments/summary`, {
      params: this.queryCleaner(params),
    });
  }

  getEntityBanks(): Observable<EntityBanks[]> {
    return this.http.get<EntityBanks[]>(`${this.baseUrl}entity-banks`);
  }

  // @todo get filter data
  getIntermediariesWithInvestors(): Observable<any> {
    return this.http.get(`${this.baseUrl}entity/intermediaries-with-investors`);
  }

  bulkUpdateInvestments(body: any): Observable<any> {
    return this.http.patch(`${this.baseUrl}investments/bulk-update`, body);
  }

  getInvestmentsChartData(params: any): Observable<any> {
    return this.http.get(`${this.baseUrl}investments/chart`,{
      params: this.queryCleaner(params),
    });
  }

  getInvestorById(id: string): Observable<any> {
    return this.http.get<InvestorsByIdResponseInterface>(`${this.baseUrl}investors/${id}`,{
    });
  }

  getUnapprovedCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}investments/count-unapproved`);
  }
}

<app-grid-container
  [gridRowData]="rowData"
  [gridColDefs]="tarColDefs"
  [gridDefaultColDef]="tarDefaultColDef"
  [hasPagination]="false"
  [hasMasterDetail]="true"
  [keepDetailRowsCount]="1"
  [detailCellRendererParams]="detailCellRendererParams"
  (gridReady)="onGridReady($event)"
  (rowGroupOpened)="onRowGroupOpened($event)"
  (rowDoubleClicked)="handleCellDoubleClick($event)"
/>

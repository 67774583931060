import { Component, inject, OnInit } from '@angular/core';
import { ControlsService } from './controls.service';
import { SelectItem } from '../shared/interfaces/select-item.interface';
import { EditInvestmentsService } from '../investments/edit-investments.service';
import { JournalsCalculated } from '../accounting/models/common/interfaces/journals-calculated.interface';
import { ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { generalObjectCleaner } from '../shared/helpers/general-object-cleaner';
import { JournalsInterface } from '../accounting/models/common/interfaces/journals.interface';
import { setNormal, setPrinterFriendly } from '../shared/helpers/print';

@Component({
  template: '',
})
export abstract class BaseComponent implements OnInit {
  isLoading: boolean;
  ftService = inject(ControlsService);
  editInvestmentsService = inject(EditInvestmentsService);
  monthList: SelectItem[];
  originatorIds: SelectItem[];
  ledgers: SelectItem[];
  rowData: any[] = [];
  gridApi: GridApi;
  params: any;
  colDefs: ColDef[];
  queryParams: any = {};
  filterQueryParams: any = {};
  defaultQueryParams = {
  };
  postings = false;

  constructor() {
    this.monthList = this.editInvestmentsService.allMonthList;
    this.queryParams = { ...this.defaultQueryParams };

  }

  setColDefs() {
    setTimeout(() => {
      this.gridApi?.setGridOption('columnDefs', this.colDefs);
      this.gridApi?.refreshHeader();
    });
  }

  ngOnInit() {
    this.getData(this.queryParams);
  }

  getData(params?: any): void {
    this.isLoading = true;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.params = params;
  }

  onFilterChange(event?: any): void {
    const timeoutId = setTimeout(() => {
      this.gridApi.showNoRowsOverlay();
    }, 300);

    this.gridApi.forEachNodeAfterFilter((rowNode) => {
      clearTimeout(timeoutId);
      this.gridApi.hideOverlay();
    });
  }

  setPinnedData(data: any): void {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData(data);
      this.gridApi.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
    }, 500);
  }

  generatePinnedBottomData(data: any, api?: GridApi) {
    const result: any = {};
    this.gridApi.getAllGridColumns()?.forEach((item: any) => {
      result[item.colId] = null;
    });
    Object.keys(data).forEach((key) => {
      result[key] = data[key];
    });
    return result;
  }

  filterChanged($event: any) {
    if ($event.ledger) {
      $event = { ...$event, ownerName: this.ledgers.find((value) => value.id === $event.ledger)?.name };
      delete $event.ledger;
    }
    const query = generalObjectCleaner($event);
    this.filterQueryParams = { ...this.queryParams, ...query };
    this.getData({ ...this.queryParams, ...this.filterQueryParams });
  }

  abstract initTable(
    response:
      | JournalsCalculated[]
      | JournalsInterface[]
      | { items: JournalsCalculated[]; meta: { pageIndex: number; perPage: number; total: number } }
      | any[],
  ): void;

  exportData() {
    this.gridApi.exportDataAsExcel();
  }

  onPrint() {
    setPrinterFriendly(this.gridApi);
    setTimeout(() => {
      print();
      setNormal(this.gridApi);
    }, 2000);
  }

  reload(): void {
    this.queryParams = { ...this.defaultQueryParams };

    this.gridApi.setFilterModel(null);
    this.gridApi.resetColumnState();
    this.gridApi.refreshHeader();
    this.gridApi.refreshCells();

    this.getData({ ...this.queryParams });
  }
}

<div *ngIf="control && control.invalid && control.hasError('required') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Data is Required.
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('min') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Value to be greater than or equal to {{control.errors?.min.min}}.
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('max') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Value to be less than or equal to {{control?.errors?.max.max}}.
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('email') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Not valid email.
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('minlength') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Value to be greater than or equal to the provided minimum length {{control?.errors?.minlength.requiredLength}}.
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('maxlength') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Value to be greater than or equal to the provided minimum length {{control?.errors?.maxlength.requiredLength}}.
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('greaterThanZero') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Value to be greater than 0.
    </label>
</div>

<div *ngIf="control && control.invalid && control.errors?.pattern && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Numeric value only
    </label>
</div>

<div *ngIf="control && control.invalid && control.hasError('duplicateData') && (control.touched || control.dirty)" class="error-msg">
    <label for="" class="error">
        <img alt="" src="assets/images/icon/error.png" class="error-icon">
        Value already exists.
    </label>
</div>

import { HttpService } from "../../../../services/http.service";
import { FxrateQueryParamsInterface } from "../interfaces/fxrate-query-params.interface";
import { Observable } from "rxjs/internal/Observable";
import { FxrateResponseInterface } from "../interfaces/fxrate-response.interface";
import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { TarWidgetInterface } from "../interfaces/tar-widget.interface";

@Injectable({providedIn: "root"})
export class TarService extends HttpService {
  getTarData(): Observable<Record<string,TarWidgetInterface[]>> {
    return this.http.get<Record<string,TarWidgetInterface[]>>(`${this.baseUrl}etr/tar-widget`)
  }
}

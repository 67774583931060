export function generateDateRange() {
  const currentDate = new Date();
  const startDate = new Date(2010, 0, 1); // January is 0 in JavaScript
  const dateList = [];

  while (startDate <= currentDate) {
    const year = startDate.getFullYear();
    const month = ('0' + (startDate.getMonth() + 1)).slice(-2); // Month is zero-indexed
    const dateString = year + '-' + month;
    dateList.unshift({ date: dateString, id: dateString });

    startDate.setMonth(startDate.getMonth() + 1); // Move to the next month
  }

  return dateList;
}

export function generateYearRange(startYear = 2010): { id: string; name: string }[] {
  let yearsList = [];
  const currentYear = new Date().getFullYear();
  while (startYear <= currentYear) {
    yearsList.push({ id: `${startYear}`, name: `${startYear}` });
    startYear++;
  }
  yearsList = yearsList.reverse();
  return [{ id: '', name: 'All' }, ...yearsList];
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PAGE_SIZES } from '../../constants/helpers.const';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  protected readonly pageSizes = PAGE_SIZES;
  @Input() currentPageIndex = 0;
  @Input() paginationPageSize!: number;
  @Input() set totalRecordsDisplay(val: string | number) {
    this._totalRecordsDisplay = val?.toLocaleString('en-US');
  }
  get totalRecordsDisplay(): string {
    return this._totalRecordsDisplay;
  }
  @Input() set totalPageString(val: string | number) {
    this._totalPageString = val?.toLocaleString('en-US');
  }
  get totalPageString(): string {
    return this._totalPageString;
  }
  @Input() totalPage!: number;
  @Input() perPageSelect = false;

  @Output() pageChange = new EventEmitter();
  @Output() perPageChange = new EventEmitter();

  private _totalRecordsDisplay: string;
  private _totalPageString: string;
}

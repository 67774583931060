<div *ngIf="isLoading" class="loader_main">
    <div class="loader">Loading...</div>
  </div>
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>

  <div class="iframe-container">
    <div class="card mb-0 common-header" id="header-row">
      <app-grid-filter
        class="sideBarClass"
        [loading]="isLoading"
        [label]="'Trade v. Finance'"
        [currentFilter]="currentFilter"
        [noSearch]="true"
        [isShowPerPage]="false"
        [noDateAndSearch]="true"
        [rangeSelector]="false"
        [selectedMonth]="selectedMonth"
        [selectedFinancialYear]="selectedFinancialYear"
        [dateRange]="dateRange"
        [savedFilterModel]="savedFilterModel"
        (refreshPage)="reload()"
      />
    </div>
    <app-grid-container
      [gridRowData]="rowData"
      [gridColDefs]="colDefs"
      [gridDefaultColDef]="defaultColDef"
      [hasPagination]="false"
      [domLayout]="domLayoutType"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChange($event)"
    />
  </div>

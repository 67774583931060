import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PAGE_SIZES } from "../../constants/helpers.const";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  protected readonly pageSizes = PAGE_SIZES;
  @Input() currentPageIndex = 0;
  @Input() paginationPageSize!: number;
  @Input() totalRecordsDisplay!: string;
  @Input() totalPageString!: string;
  @Input() totalPage!: number;
  @Input() perPageSelect = false;

  @Output() pageChange = new EventEmitter();
  @Output() perPageChange = new EventEmitter();
}

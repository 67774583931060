import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { EntityItem } from "src/app/shared/interfaces/entity-response.interface";
import { getFormData } from "src/app/shared/helpers/utils";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime, distinctUntilChanged, filter, forkJoin, Subscription } from "rxjs";
import { SelectItem } from "src/app/shared/interfaces/select-item.interface";
import { EntitiesHttpService } from "../../entities/common/services/entities-http.service";
import { CreditorFormProvider } from "./creditor-form.provider";

@Component({
  selector: 'app-add-creditor',
  templateUrl: './add-creditor.component.html',
  styleUrls: ['./add-creditor.component.scss'],
})

export class AddCreditorComponent implements OnInit, OnDestroy {
  entityForm: FormGroup;
  roleList: SelectItem[] = [];
  typeList: { id: number; name: string }[] = [
    { id: 1, name: 'Organisation' },
    { id: 2, name: 'Person' },
  ];
  entityList: any;
  banksList: SelectItem[] = [];
  banksListTemp: SelectItem[] = [];
  areasList: any;
  citiesList: string[];
  statesList: string[];
  countriesList: string[];
  isNewEntity: boolean;
  private bankSubscription: Subscription | undefined;
  @Input() currentEntity: any;
  @Input() distinctValues: any;
  @Input() currencyBankAccountsData: any;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() createUpdateEvent = new EventEmitter<boolean>();
  entityCreated = true;
  isPerson = true;
  isShowPreviousInput: boolean;
  constructor(
    private creditorFormProvider: CreditorFormProvider,
    private entitiesHttpService: EntitiesHttpService,
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.initSelectOptionsData();
    this.getBanksList();
    this.initEntityForm(this.currentEntity);
    this.subscribeToBank();
    this.subscribeToSortCodeValueChange();
  }


  private subscribeToBank(): void {
    this.bankSubscription?.unsubscribe();
    this.bankSubscription = this.entityForm.get('bank')?.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: any) => {
      if (value && !this.banksList.some(tag => tag.id === value)) {
        const newTag = { id: value, name: value };
        this.banksList = [...this.banksList, newTag];
      }
      else {
        this.banksList = [...this.banksListTemp];
      }
    });
  }

  private subscribeToSortCodeValueChange() {
    this.entityForm.get('sortCode')?.valueChanges.pipe(
      distinctUntilChanged(),
      filter((data) => !!data),
    ).subscribe((value: string) => {

      const sanitizedValue = value.replace(/[^0-9-]/g, '');
      const formattedValue = this.formatSortCode(sanitizedValue);
      this.entityForm.get('sortCode')?.patchValue(formattedValue, { emitEvent: false });
    });
  }

  private formatSortCode(value: string): string {
    return value.replace(/(\d{2})(?=\d)/g, '$1-');
  }

  initEntityForm(data: EntityItem): void {
    this.creditorFormProvider.initEntityForm(data);
    this.entityForm = this.creditorFormProvider.creditorForm;
    this.entityForm.get('roleId')?.setValue('Creditor');

    this.changeFormValidators(this.isPerson);
    this.cdr.detectChanges();
  }

  private getBanksList(): void {
    this.entitiesHttpService.getBanksList()
      .subscribe(response => {
        this.banksList = response;
      })
  }

  private initSelectOptionsData(): void {
    this.roleList = [
      ...(this.distinctValues?.roles?.map((status: any) => ({
        id: status,
        name: status,
      })) || []),
    ];
    this.entitiesHttpService.getFilterDistinctValues().subscribe((response) => {
      this.countriesList = response?.countries;
      this.statesList = response?.states;
      this.citiesList = response?.cities;
    });
  }

  onSubmit() {
    if (this.entityForm.invalid) return;
    let rawData = this.entityForm.value;
    rawData.sortCode = rawData.sortCode?.toString().replace(/-/g, '');
    rawData.phoneNumber = `${rawData.phoneCountryCode || ''}${rawData.phoneAreaCode || ''}${rawData.phoneNumber || ''}`;
    delete rawData.id;
    rawData = getFormData(rawData);
    const requestData: Partial<EntityItem> = {
      ...rawData
    };

    this.doCreate(requestData);
  }


  doCreate(data: any): void {
    const body = {
      ...(data.type && { type: data.type }),
      ...(data.firstName && { firstName: data.firstName }),
      ...(data.lastName && { lastName: data.lastName }),
      ...(data.tradingAs && { tradeName: data.tradingAs }),
      ...(data.roleId && { roleNames: [data.roleId] }),
      ...(data.entityRoleId && { entityRoleId: data.entityRoleId }),
      isPrevious: data.isPrevious,
      name: this.isPerson ? `${data.firstName} ${data.lastName}` : data.name,
    };
    if (this.entityCreated) {
      this.entitiesHttpService.createEntity(body).subscribe({
        next: (response: any) => {
          this.currentEntity = response;
          this.doUpdate(data);
        },
      });
    }
    else {
      this.doUpdate(data);
    }
  }

  doUpdate(data: any): void {
    const entityAddressBody = {
      ...(data.country && { country: data.country }),
      ...(data.state && { state: data.state }),
      ...(data.city && { city: data.city }),
      ...(data.area && { area: data.area }),
      ...(data.address1 && { lineOne: data.address1 }),
      ...(data.address2 && { lineTwo: data.address2 }),
      ...(data.postCode && { zipCode: data.postCode }),
      countryCode: 'Arm',
    };
    const entityPropertiesBody = {
      ...(data.email && { emails: [data.email] }),
      ...(data.phoneNumber && { phoneNumbers: { mobile: data.phoneNumber } }),
      ...(data.previousName && { previousName: data.previousName }),
    };
    const entityBanksBody = {
      ...(data.bankId && { bankId: data.bankId }),
      ...(data.bankName && { name: data.bankName }),
      ...(data.swiftBic && { swiftBic: data.swiftBic }),
      ...(data.sortCode && { sortCode: data.sortCode }),
      ...(data.accountNumber && { account: data.accountNumber.toString() }),
      ...(data.iban && { iban: data.iban }),
    };

    const entityDocumentsBody = {
      POFile: data.POFile,
      PoDFile: data.PoDFile,
      entityId: this.currentEntity.id,
      etrFile: data.etrFile,
      fxFile: data.fxFile,
      organisationFile: data.organisationFile,
      proofFile: data.proofFile,
      signedFile: data.signedFile
    }

    const requests = [];

    if (Object.keys(entityAddressBody).length && this.entityCreated) {
      requests.push(this.updateAddressData(entityAddressBody));
    }

    if (Object.keys(entityPropertiesBody).length && this.entityCreated) {
      requests.push(this.updateProperties(entityPropertiesBody));
    }

    if (Object.keys(entityDocumentsBody).length && this.entityCreated) {
      requests.push(this.updateDocumentsSettings(entityDocumentsBody));
    }

    if (Object.keys(entityBanksBody).length) {
      requests.push(this.createBankAccount(entityBanksBody));
    }

    if (Object.keys(entityBanksBody).length == 0 && !this.entityCreated) {
      this.setAttribute();
      this.activeModal.close(this.currentEntity);
      this.entityForm.reset();
      Swal.fire({
        icon: 'success',
        text: 'Creditor successfully added.',
        showConfirmButton: false,
        timer: 5000,
      });
    }

    if (requests.length) {
      forkJoin(requests).subscribe({
        next: (responses) => {
          this.setAttribute();
          this.activeModal.close(this.currentEntity);
          this.entityForm.reset();
          Swal.fire({
            icon: 'success',
            text: 'Creditor successfully added.',
            showConfirmButton: false,
            timer: 5000,
          });
        },
        error: (err) => {
          this.entityCreated = false;
        },
      });
    }
  }

  private updateAddressData(data: any) {
    return this.entitiesHttpService.createEntityAddress({ entityId: this.currentEntity.id, data })
  }

  private updateProperties(data: any) {
    return this.entitiesHttpService.updateEntityProperties({ entityId: this.currentEntity.id, data })
  }

  private updateDocumentsSettings(data: any) {
    return this.entitiesHttpService.updateEntityDocuments(data)
  }

  private createBankAccount(data: any) {
    return this.entitiesHttpService.createEntityBanksAccounts({ entityRoleId: this.currentEntity.entityRoles[0].id, data })
  }

  closeModal() {
    this.setAttribute();
    this.activeModal.dismiss();
    this.entityForm.reset();
  }

  handleBankChange(event: any) {
    this.entityForm.get('bankName')?.patchValue(event?.name || null);
  }


  addNewTag(tagName: string): string {
    return tagName;
  }

  handleTypeChange(event: any) {
    this.isPerson = event.name === 'Person';
    if (this.isPerson) {
      this.entityForm.get('name')?.patchValue('');
    } else {
      this.entityForm.get('firstName')?.patchValue('');
      this.entityForm.get('lastName')?.patchValue('');
    }
    this.changeFormValidators(this.isPerson);
  }

  changeFormValidators(isPerson: boolean): void {
    const controls = ['name', 'firstName', 'lastName'];

    controls.forEach((control) => {
      const formControl = this.entityForm.get(control);
      if (!formControl) return;

      if (isPerson) {
        if (control === 'firstName' || control === 'lastName') {
          formControl.addValidators([Validators.required]);
        } else {
          formControl.clearValidators();
        }
      } else {
        if (control === 'name') {
          formControl.addValidators([Validators.required]);
        } else {
          formControl.clearValidators();
        }
      }
      formControl.updateValueAndValidity();
    });
  }

  handlePreviewCheck(event: any) {
    this.isShowPreviousInput = event.target.checked;
  }

  getTradingDynamicClass(): string {
    if (this.isPerson) {
      return 'width-147';
    }
    return this.isShowPreviousInput ? 'width-105' : 'width-173';
  }
   
  setAttribute(){
    const modalElement = document.querySelector('.buyAsset-custom-model');
      if (modalElement) {
        modalElement.setAttribute('aria-hidden', 'false');
      }
  }

  ngOnDestroy(): void {
    this.bankSubscription?.unsubscribe();
  }
}

<!-- <div class="login-container">
    <button *ngIf="!loginDisplay" (click)="login()">Login with Azure AD</button>
  </div> -->

<div class="login-container">
  <div class="login-box">
    <h2>Login with Azure AD</h2>
    <button (click)="login()" [disabled]="loginText !== 'Login'">{{ loginText }}</button>
  </div>
</div>

import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConvertISONumberToNormal } from '../helpers/utils';
import { PercentFormatter } from '../helpers/percent-formatter.helper';

@Directive({
  selector: '[appPercentNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PercentNumberDirective),
      multi: true,
    },
  ],
})
export class PercentNumberDirective implements ControlValueAccessor {
  private onChange!: (val: string | number) => void;
  private onTouched!: () => void;
  private value!: string | number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const filteredValue: string | number = filterValue(value);
    this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  @HostListener('blur')
  onBlur() {
    this.onValueFormating();
  }
  @HostListener('focus')
  onFocus() {
    if (!this.value) {
      return;
    }
    this.updateTextInput(ConvertISONumberToNormal(this.value as string), false);
  }

  private updateTextInput(value: string | number, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  onValueFormating() {
    if (!this.value) {
      return;
    }
    const formatedValue = PercentFormatter(this.value as number, false);
    this.updateTextInput(formatedValue!, false);
  }
  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    value = PercentFormatter(value, false);
    this.updateTextInput(value, false);
  }
}

function filterValue(value: any): string | number {
  return value.replace(/[^0-9.,]*/g, '');
}

<!-- example.component.html -->


  <div class="iframe-container">
    <div class="card mb-0" id="header-row">
        <div class="card-body header-title">
          <div class="row">
        <div class="col-md-2">
          <p class="card-title ps-1">Investments</p>
        </div>

              <div class="col-md-10 text-sm-end d-flex justify-content-end custom-invest-filter">
                  <select id="pageSize" class="form-select custom-invest-select border-radius-100 ng-pristine ng-valid ng-touched" disabled>
                    <option  value="50" class="ng-star-inserted">50 per page</option>
                    <option  value="100" class="ng-star-inserted">100 per page</option>
                    <option value="200" class="ng-star-inserted">200 per page</option>
                    <option  value="500" class="ng-star-inserted">500 per page</option>
                    <option value="1000" class="ng-star-inserted">1000 per page</option>
                  </select>
                <button type="button" class="btn btn-light custom-invest-button"><img alt="" src="./assets/images/icon/replay.svg"></button>
                <!-- <button type="button" class="btn btn-light custom-invest-button"><img alt="" src="./assets/images/icon/fullscreen.svg"></button>                -->
              </div>
            </div>
        </div>
      </div>

      <div id="iframsaction">
        <div *ngIf="isLoading" class="loader_main">
          <div class="loader">Loading...</div>
        </div>
        <iframe [src]="iframeSource"  (load)="onIframeLoad($event)">
        </iframe>
      </div>
</div>



import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntitiesSharedService {
  public selectedRole = new Subject<any>();

  setRoleType(roleType: any) {
    this.selectedRole.next(roleType);
  }

  getRoleType() {
    return this.selectedRole.asObservable();
  }
}

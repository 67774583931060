import * as moment from 'moment';

export const omitKey = (object: { [k: string]: any }, key: string | number): { [k: string]: any } => {
  return ({ [key]: key, ...object } = object) && object;
};

export const ParseDate = (param: string): { year: number; day: number; month: number } => {
  return {
    year: parseInt(moment(param).format('YYYY'), 10),
    day: parseInt(moment(param).format('DD'), 10),
    month: parseInt(moment(param).format('M'), 10),
  };
};

export const getFormData = (formValues: Record<string, unknown>, allowEmpty?: boolean): Record<string, any> => {
  return Object.keys(formValues).reduce((data: any, field) => {
    // Trim the string values;
    if (typeof formValues[field] === 'string') formValues[field] = (formValues[field] as string).trim();

    // transform Date object to formatted date string
    if (formValues[field] instanceof Date) formValues[field] = parseDate(formValues[field] as Date);

    // adding value to data if value is not false or empty and empty is allowed
    if (formValues[field] || (allowEmpty && formValues[field] === '')) data[field] = formValues[field];
    return data;
  }, {});
};

export const parseDate = (param: any): any  => {
    const dateMoment = moment(`${param.year}-${param.month}-${param.day}`, 'YYYY-MM-DD');
   return moment(dateMoment).format('YYYY-MM-DD');
    /*return {
        year: parseInt(moment(param).format('YYYY'), 10),
        day: parseInt(moment(param).format('DD'), 10),
        month: parseInt(moment(param).format('M'), 10),
    };*/
}

/**
 * Get difference values as object from comparision of the 2 object arguments
 * @param {object} formValues
 * @param {object} snapshot
 */
export const getModelChanges = (formValues: Record<string, unknown>, snapshot: any): Record<string, any> => {
    return Object.keys(formValues).reduce((changes, field) => {
        if (typeof formValues[field] === 'string') formValues[field] = (formValues[field] as string).trim();
        return (formValues[field] != snapshot[field]) ? {...changes, [field]: formValues[field]} : changes;
    }, {});
};

export const DateFilterParamsSettings = (dateFilter: Record<string, string>, key: string): any => {
    const queryParams: Record<string, string> = {};
    if (dateFilter['type'] === 'inRange') {
        queryParams[`${[key]}[from]`] = moment(dateFilter['dateFrom']).format('YYYY-MM-DD');
        queryParams[`${[key]}[to]`] = moment(dateFilter['dateTo']).format('YYYY-MM-DD');
        queryParams[`${[key]}[comparator]`] = 'Between';
    } else if (dateFilter['type'] === 'after') {
        queryParams[`${[key]}[from]`] = moment(dateFilter['dateFrom']).format('YYYY-MM-DD');
        queryParams[`${[key]}[comparator]`] = dateFilter['type'].charAt(0).toUpperCase() + dateFilter['type'].slice(1);
    }else if (dateFilter['type'] === 'blank') {
        queryParams[`${[key]}[from]`] = '';
        queryParams[`${[key]}[comparator]`] = dateFilter['type'].charAt(0).toUpperCase() + dateFilter['type'].slice(1);
    } else {
        queryParams[`${[key]}[from]`] = moment(dateFilter?.['dateFrom']).format('YYYY-MM-DD');
        queryParams[`${[key]}[comparator]`] = dateFilter['type'].charAt(0).toUpperCase() + dateFilter['type'].slice(1);
    }

    return queryParams;
}

export const GetExtensionFile = (file: File): string => {
  return file.name.substring(file.name.lastIndexOf('.') + 1);
}

export const ConvertISONumberToNormal = (isoNumber: string | number): number => {
  return typeof isoNumber === 'string' ? +isoNumber.replace(/,/g, '') : isoNumber;
}

export const RestrictDecimalValueLength = (inputField: any, length = 4): string => {
  const match = inputField.value.match(/^(\d+(\.\d{0,4})?)?/);
  return  match ? match[0] : ''
}

/**
 * Loop trough data and return array of the string of the error messages.
 */
export const extractErrorList = (errorData: Record<string, unknown>): string[] => {
  let messages: string[] = [];
  for (const field in errorData) {
    if (typeof errorData[field] === 'string') {
      messages.push(errorData[field] as string);
    } else if (typeof errorData[field] === 'object') {
      if (Array.isArray(errorData[field])) {
        for (const item of (errorData[field] as unknown[])) {
          if (typeof item === 'string') {
            messages = [...messages, item];
          } else {
            const innerMessage = extractErrorList(item as Record<string, unknown>);
            messages = [...messages, ...innerMessage];
          }
        }
      } else {
        const innerMessage = extractErrorList(errorData[field] as Record<string, unknown>);
        messages = [...messages, ...innerMessage];
      }
    }
  }
  return messages;
};

export const FormatPhoneNumber = (number: string, slicePlace = 3):string => {
  // return number.slice(0, slicePlace) + '-' + number.slice(slicePlace);

  // Remove all non-digit characters
  let sanitized = number.replace(/[^0-9]/g, '');

  // Apply the desired format: 123-1234564
  if (sanitized.length > slicePlace) {
    sanitized = sanitized.slice(0, slicePlace) + '-' + sanitized.slice(slicePlace);
  }

  return sanitized;
}

export const GetFileExtension = (fileName: string): string => {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
}

<div *ngIf="isLoading" class="loader_main"></div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<div class="iframe-container">
  <div class="card mb-0 common-header" id="header-row">
    <app-grid-filter
      [label]="'Fx, Yield Rates, TaR & Closing Positions'"
      [currentFilter]="currentFilter"
      [selectedMonth]="selectedMonth"
      [selectedRecordPerPage]="selectedRecordPerPage"
      [selectedFinancialYear]="selectedFinancialYear"
      [dateRange]="dateRange"
      [showNewButton]="true"
      [noSearch]="true"
      [savedFilterModel]="savedFilterModel"
      [buttonsConfig]="buttonsSettings"
      (saveFilterData)="saveFilterData($event)"
      (refreshPage)="refreshPage()"
      (formChange)="filterChanged($event)"
      (createNew)="openModal()"
      (export)="exportData()"
      (print)="onPrint()"
    >
      <div content class="d-flex justify-content-center align-items-center">
        <div class="input-group me-1" style="width: 115px">
          <input
            id="date"
            type="text"
            class="form-control"
            placeholder="YYYY-MM-DD"
            name="name"
            ngbDatepicker
            #d2="ngbDatepicker"
            [(ngModel)]="latestDate"
            [readonly]="true"
            [maxDate]="maxDate"
            [markDisabled]="isDisabled"
            (dateSelect)="handleDate($event)"
          />
          <button
            class="btn btn-outline-secondary btn-calendar-icon"
            (click)="d2.toggle()"
            type="button"
          >
            <img alt="" src="../../../../../assets/images/icon/calendar-icon.svg"
            />
          </button>
        </div>

        <div class="me-1">
            <select class="custom-form-select border-radius-100" id="ccy" (change)="handleCcy($event)">
              <option *ngFor="let cc of ccy" [value]="cc.id">
                {{ cc.name }}
              </option>
            </select>
        </div>

        <div class="width-125 me-1">
            <input
              [appOnlyNumber]="false"
              type="text"
              class="form-control ft-width inputNumberText"
              placeholder=""
              [(ngModel)]="inputValue"
              [maxLength]="12"
              (focus)="handleFocusFaceValue()"
              (focusout)="handleFocusOutFaceValue()"
              (input)="handleInputValue()"
            />
        </div>
        <div class="width-125 me-1">
          <input
            type="text"
            class="form-control ft-width inputNumberText"
            placeholder=""
            style="border: none"
            [value]="generatedAmount"
            readonly
          />
        </div>
      </div>
    </app-grid-filter>
  </div>
  <div class="row">
    <div class="fx-rate-container">
      <app-grid-container
        [gridRowData]="rowData"
        [gridColDefs]="colDefs"
        [gridDefaultColDef]="defaultColDef"
        [gridPaginationPageSize]="paginationPageSize"
        [hasPagination]="false"
        [sideBar]="sideBar"
        (gridReady)="onGridReady($event)"
        (filterChanged)="onFilterChanged($event)"
        (sortChanged)="onSortChanged($event)"
      />
      <app-paginator
        [currentPageIndex]="currentPageIndex"
        [paginationPageSize]="paginationPageSize"
        [totalPage]="totalPage"
        [totalPageString]="totalPageString"
        [totalRecordsDisplay]="totalRecordsDisplay"
        (pageChange)="changePageSelection($event)"
      />
    </div>
    <div class="tar-container">
      <app-tar />
    </div>
  </div>
</div>


<div *ngIf="isShowAddEditFxRates"
     class="modal fade show"
     tabindex="-1"
     role="dialog"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title">
          {{ currentRecord ? "Edit" : "Add" }} Fx & Overnight Rates
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body padding-model form-editing">
        <app-add-edit-fxrate
          [fxRateData]="currentRecord"
          (closeDialogEvent)="isShowAddEditFxRates = $event"
          (createUpdateEvent)="onGridRefresh()"
        />
      </div>
    </div>
  </div>
</div>

import { SummaryKeyInvestments } from '../enums/common.enum';
import { Summary, SummaryItem } from '../interfaces/bank-response.interface';

export function CalculatePinnedInvestments(
  thisPinnedRowData: Summary,
  thisTarget: any,
  target: any,
  key: keyof Summary,
  type?: SummaryKeyInvestments,
) {
  const columnsWithAggregation = ['bankPayment', 'bankReceipt', 'faceValue', 'buyRate', 'commission', 'months', 'transactionType', 'ccy', 'date'];

  if (!thisTarget) {
    thisTarget = { ...target };
  }

  if (!thisPinnedRowData) {
    return {
      ...thisTarget,
      bankPayment: '-',
      bankReceipt: '-',
      faceValue: '-',
      buyRate: '-',
      commission: '-',
      months: '-',

      transactionType: 'sum',
      // ccy: 'sum',
      date: 'avg',
    };
  }

  columnsWithAggregation.forEach((element) => {
    if (element === 'transactionType' && (type ? type === SummaryKeyInvestments.Payment_Out : true)) {
      thisTarget[element] = key;
      return;
    }

    if (element === 'date' && (type ? type === SummaryKeyInvestments.Buy_Rate : true)) {
      thisTarget[element] = type ? key : 'avg';
      return;
    }

    if (element == SummaryKeyInvestments.Payment_Out && (type ? type === SummaryKeyInvestments.Payment_Out : true)) {
      thisTarget[element] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === element)?.value || 0;
      thisTarget[SummaryKeyInvestments.Receipt_In] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === 'bankReceipt')?.value || 0;
      return;
    } else if (element == SummaryKeyInvestments.Buy_Rate && (type ? type === SummaryKeyInvestments.Buy_Rate : true)) {
      thisTarget[SummaryKeyInvestments.Buy_Rate] =
        thisPinnedRowData[type ? key : 'avg']?.find((val: SummaryItem) => val?.key === SummaryKeyInvestments.Buy_Rate)?.value || 0;
      thisTarget[SummaryKeyInvestments.Commission] =
        thisPinnedRowData[type ? key : 'avg']?.find((val: SummaryItem) => val?.key === SummaryKeyInvestments.Commission)?.value || 0;
      thisTarget[SummaryKeyInvestments.Months] =
        thisPinnedRowData[type ? key : 'avg']?.find((val: SummaryItem) => val?.key === SummaryKeyInvestments.Months)?.value || 0;
      thisTarget[SummaryKeyInvestments.Face_Value] =
        thisPinnedRowData[type ? key : 'avg']?.find((val: SummaryItem) => val?.key === SummaryKeyInvestments.Face_Value)?.value || 0;
      return;
    }
  });
  return thisTarget;
}

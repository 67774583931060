import { HttpService } from '../../../../services/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { data } from "autoprefixer";
import { EntityDocumentsRequestInterface } from "../interfaces/entity-documents-request.interface";
import { AssetItem } from "../../../../shared/interfaces/asset-response.interface";
import { EntityQueryParams } from "../../../../shared/interfaces/entity-query-params";
import { EntityResponse } from "../../../../shared/interfaces/entity-response.interface";

@Injectable({ providedIn: 'root' })
export class EntitiesHttpService extends HttpService {
  getEntities(params: Partial<EntityQueryParams>): Observable<EntityResponse> {
    return this.http.get<EntityResponse>(`${this.baseUrl}entity`, {
      params: this.queryCleaner(params),
    });
  }
  createEntity(data: any): Observable<any> {
    return this.http.post<Observable<any>>(`${this.baseUrl}entity`, data);
  }

  updateEntity(payload: {entityId: number, data: any}): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity/${payload.entityId}`, payload.data);
  }

  createEntityAddress(payload: { entityId: number; data: any }): Observable<any> {
    return this.http.post(`${this.baseUrl}entity/address/${payload.entityId}`, payload.data);
  }

  updateEntityAddress(payload: { entityId: number; data: any }): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity/address/${payload.entityId}`, payload.data);
  }

  updateEntityProperties(payload: { entityId: number; data: any }): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity/properties/${payload.entityId}`, payload.data);
  }

  createEntityBanksAccounts(payload: { entityRoleId: string; data: any }): Observable<any> {
    return this.http.post(`${this.baseUrl}entity-banks/accounts/${payload.entityRoleId}`, payload.data);
  }

  updateEntityBanksAccounts(payload: { accountId: number; data: any }): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity-banks/accounts/${payload.accountId}`, payload.data);
  }

  getBanksList(): Observable<any> {
    return this.http.get(`${this.baseUrl}entity-banks`);
  }

  getOrganisationList(): Observable<any> {
    return this.http.get(`${this.baseUrl}entity/organisations`);
  }

  updateEntityDocuments(data: EntityDocumentsRequestInterface): Observable<any> {
    return this.http.patch<AssetItem>(`${this.baseUrl}entity/update-documents`, data);
  }

  updateRiskAndRates(data: any): Observable<any> {
    return this.http.patch<AssetItem>(`${this.baseUrl}entity/update-risk-and-rates`, data);
  }

  deleteEntity(entityId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}entity/${entityId}`);
  }

  deleteEntityAddress(addressId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}entity/address/${addressId}`);
  }

  deleteEntityBankAccount(bankAccountId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}entity-banks/accounts/${bankAccountId}`);
  }
}

import { PercentValueFormatter3 } from './percent-value-formatter';
import { numberValueFormatter } from './number-value-formatter';

export function tooltipRenderer(params: any) {
  const decimal = decimalPointSettings[params.colDef.colId] ? decimalPointSettings[params.colDef.colId] : 2;
  if (
    params.colDef?.field?.includes('paymentFromAccountToReaccount') ||
    params.colDef?.field?.includes('receiptByAccountFromReaccount') ||
    params.colDef?.field?.includes('Amount') ||
    params.colDef?.colId == 'bankReceipt' ||
    params.colDef?.colId == 'bankPayment' ||
    params.colDef?.colId == 'faceValue' ||
    params.colDef?.colId == 'Buy_Rate' ||
    params.colDef?.colId == 'commission' ||
    params.colDef?.colId == 'months' ||
    params.colDef?.colId == 'buyRate' ||
    params.colDef?.colId == 'gbp' ||
    params.colDef?.colId == 'usd' ||
    params.colDef?.colId == 'eonia' ||
    params.colDef?.colId == 'libor' ||
    params.colDef?.colId == 'obfr' ||
    params.colDef?.colId == 'yield' ||
    params.colDef?.colId == 'balance' ||
    params.colDef?.colId == 'sellRate' ||
    params.colDef?.colId == 'traded' ||
    params.colDef?.colId == 'eurFacevalue' ||
    params.colDef?.colId == 'ldc' ||
    params.colDef?.colId == 'eurLDC' ||
    params.colDef?.colId == 'reserves' ||
    params.colDef?.colId == 'outstanding' ||
    params.colDef?.colId == 'eurPurchased' ||
    params.colDef?.colId == 'deferredIncome' ||
    params.colDef?.colId == 'adso' ||
    params.colDef?.colId == 'edso' ||
    params.colDef?.colId == 'rdso' ||
    params.colDef?.colId == 'reservesDueOutstanding' ||
    params.colDef?.colId == 'purchasedValue' ||
    params.colDef?.colId == 'deposit' ||
    params.colDef?.colId == 'dueOutstanding' ||
    params.colDef?.colId == 'vatIncluded' ||
    params.colDef?.colId == 'vatOnPurchases' ||
    params.colDef?.colId == 'eot' ||
    params.colDef?.colId == 'unitPrice' ||
    params.colDef?.colId == 'eurOutstanding' ||
    params.colDef?.colId == 'principal' ||
    params.colDef?.colId == 'eurLossPremium' ||
    params.colDef?.colId == 'eurMarginCallSettled' ||
    params.colDef?.colId == 'eurTradeProfit' ||
    params.colDef?.colId == 'eurDayRule' ||
    params.colDef?.colId == 'eurDay180Reserves' ||
    params.colDef?.colId == 'eurFxGainSettled' ||
    params.colDef?.colId == 'eurFxGainReserve' ||
    params.colDef?.colId == 'eurMarginCallClosed' ||
    params.colDef?.colId == 'eurInstalment' ||
    params.colDef?.colId == 'eurInstalmentPrincipal' ||
    params.colDef?.colId == 'eurInstalmentProfit' ||
    params.colDef?.colId == 'eurReserves' ||
    params.colDef?.colId == 'eurIncomeValue' ||
    params.colDef?.colId == 'reconciledCommision' ||
    params.colDef?.colId == 'eurReconciledCommision' ||
    params.colDef?.colId == 'eurDeposit' ||
    params.colDef?.colId == 'eurTradeCommission' ||
    params.colDef?.colId == 'eurEot' ||
    params.colDef?.colId == 'eurInvestor' ||
    params.colDef?.colId == 'bankEurTotal' ||
    params.colDef?.colId == 'settledBalance' ||
    params.colDef?.colId == 'closedBalance' ||
    params.colDef?.colId == 'bankUsd' ||
    params.colDef?.colId == 'bankGbp' ||
    params.colDef?.colId == 'bankEur' ||
    params.colDef?.colId == 'tradeProfit' ||
    params.colDef?.colId == 'journal' ||
    params.colDef?.colId == 'debit' ||
    params.colDef?.colId == 'credit' ||
    params.colDef?.colId == 'eurCredit' ||
    params.colDef?.colId == 'eurDebit' ||
    params.colDef?.field?.includes('Sum')
  ) {
    const formattedValue = numberValueFormatter(params);
    const labelValue = params.value;
    if (labelValue === undefined || labelValue === null || isNaN(labelValue)) {
      return '-';
    }
    let dd: string;
    if (params.node.rowPinned === 'bottom') {
      dd =
        Math.abs(Number(labelValue)) >= 1.0e9
          ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(decimal) + (hasSignPercent.includes(params.colDef.colId) ? ' %' : ' b')
          : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
            ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(decimal) + (hasSignPercent.includes(params.colDef.colId) ? ' %' : ' m')
            : // Three Zeroes for Thousands
              Math.abs(Number(labelValue)) >= 1.0e3
              ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(decimal) + (hasSignPercent.includes(params.colDef.colId) ? ' %' : ' k')
              : // For values less than 1000, just return the number itself
                Math.abs(Number(labelValue)).toFixed(decimal) + (hasSignPercent.includes(params.colDef.colId) ? ' %' : '');

      if (params.value != '' && params.value != null) {
        return '<span title="' + formattedValue + '">' + (labelValue < 0 ? '-' + dd : dd) + '</span>';
      } else {
        return '-';
      }
    } else {
      dd = `${addPercent(params, formattedValue)}`;
      return dd;
    }
  } else {
    if (params.value != '' && params.value != null) {
      return '<span title="' + params.value + '">' + params.value + '</span>';
    } else {
      return '';
    }
  }
}

const hasSignPercent = ['buyRate', 'eonia', 'libor', 'obfr'];

const addPercent = (params: any, formatedValue: any): string => {
  return hasSignPercent.includes(params.colDef.colId) ? PercentValueFormatter3(formatedValue) : formatedValue;
};

const decimalPointSettings: Record<string, number> = {
  gbp: 4,
  usd: 4,
  buyRate: 3,
  months: 0,
};

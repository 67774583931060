
<div id="pdfTable" class="pdfTable" #pdfTable style="width: 80%; position: fixed; top: -9999px; left: -9999px"></div>

<div  class="page1Content" #page1Content>
  <div>
    <table class="w-100">
      <tr>
        <td class="align-top">
          <table class="w-100">
            <tr>
              <td class="text-start align-top img-logo">
                <img alt="" [src]="logoUrl"  height="65px" width="240px">
              </td>
              <td>
                <h4 class="fs-5 fw-bold custom-padding-left-rpa">RPA Order Confirmation</h4>
              </td>
            </tr>
            <tr>
              <td class="custom-height" colspan="2"></td>
            </tr>
            <tr>
              <td class="align-top w-50 custom-padding-left-address">
                <div class="account-info">
                  <h4>{{pdf_Data?.investorName}} </h4>
                  <p [innerHTML]="pdf_Data?.address">
                  </p>
                </div>
              </td>
              <td colspan="2" class="w-50 align-top custom-padding-left-iaa">
                <table class="statement-header-table">
                  <tr>
                    <td>
                      <p class="custom-font-weight-iaa mt-0">IAA</p>
                      <p class="fw-semibold">RI</p>
                    </td>
                    <td>
                      <p class="custom-font-weight-iaa mt-0">:</p>
                      <p class="fw-semibold">:</p>
                    </td>
                    <td>
                      <p class="custom-font-weight-iaa mt-0">&nbsp;{{pdf_Data?.intermediaryId}}</p>
                      <p class="fw-semibold">&nbsp;{{pdf_Data?.investorId}}</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
        <td class="float-end align-top custom-position-address-data">
          <p class="line-height-18 font-size-15 fw-semibold mb-1">Credebt Exchange Limited</p>
          <p class="line-height-18 font-size-12">15A Baggotrath Place, 15 - 16<br> Lower Baggot Street,<br>Dublin D02
            NX49, Ireland</p>
          <p class="line-height-18 font-size-12">Phone : +353 (1) 685-3600</p>
          <p class="line-height-18 font-size-12 ">Email: {{'yield@investabill.com'}}</p>
        </td>
      </tr>
      <tr>
        <td class="height-8px"></td>
      </tr>
    </table>
    <hr class="ps-2">
  </div>
  <section class="transactions">
    <div class="data-table-outer">
      <table class="w-100 custom-max-width-720">
        <tr>
          <td class="pe-2 custom-width-54 w-50 align-top">
            <table class="data-table w-100 mx-auto">
              <thead>
                <tr>
                  <th colspan="2" style="border-bottom: 1px solid #fff;">RPA & Transaction Details</th>
                </tr>
                <tr>
                  <th class="w-50 text-start ps-2"  style="border-right: 1px solid #fff;">RPA Details</th>
                  <th class="w-50 text-start ps-2">Transaction Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="td-text-dark td-style">Signed RPA Agreement</td>
                  <td class="td-text-dark">{{pdf_Data?.date}}</td>
                </tr>
                <tr>
                  <td class="td-text-dark td-style">Commencement Date</td>
                  <td class="td-text-dark">{{pdf_Data?.date}}</td>
                </tr>
                <tr>
                  <td class="td-text-dark td-style">Savings</td>
                  <td class="td-text-dark td-style">{{pdf_Data?.savings}}</td>
                </tr>
                <tr>
                  <td class="td-text-dark td-style">Type</td>
                  <td class="td-text-dark td-style">{{pdf_Data?.type}}</td>
                </tr>
                <tr>
                  <td class="td-text-dark td-style">Buy Rate</td>
                  <td class="td-text-dark">{{pdf_Data?.buyRate}}</td>
                </tr>
                <tr>
                  <td class="td-text-dark td-style">Months</td>
                  <td class="td-text-dark">{{pdf_Data?.months}}</td>
                </tr>
                <tr>
                  <td class="td-text-dark td-style">Maturity Date</td>
                  <td class="td-text-dark">{{pdf_Data?.maturityDate}}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td class="align-top custom-padding-left-6 custom-width-40 pe-3">
            <table class="data-table overide-statement w-100 mx-auto">
              <thead>
                <tr>
                  <th colspan="2">Purchase Overview</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="custom-padding-left-10 fw-bold custom-font-size-15 custom-width-50 text-start">Receipt
                    Amount</td>
                  <td class="text-end custom-padding-right-10 custom-width-50">{{pdf_Data?.ccy}} {{pdf_Data?.receiptAmount}}</td>
                </tr>
              </tbody>
            </table>
            <table class="custom-border-1 w-100 text-center">
              <tr>
                <td class="custom-height-5px"></td>
              </tr>
              <tr>
                <td class="fw-bold custom-padding-bottom-6px">
                  <h4 class="fw-bold custom-font-size-18 custom-padding-8px td-text-dark">EXCLUSIVE MEMBER’S OFFER: </h4>
                </td>
              </tr>
              <tr>
                <td class="td-text-dark custom-font-size-18 fw-500">Refer a friend using this code:</td>
              </tr>
              <tr>
                <td class="fw-700 text-uppercase custom-padding-y">
                  <h4 class="fw-bold custom-font-size-18 td-text-dark">{{pdf_Data?.referId}}</h4>
                </td>
              </tr>
              <tr>
                <td class="td-text-dark custom-font-size-18 fw-500 custom-padding-x text-start">Reward is 0.500%
                  additional Yield
                  on this investment</td>
              </tr>
              <tr>
                <td class="custom-height-8px"></td>
              </tr>
            </table>
          </td>
          <td class="align-top">
            <h4 class="term-overleaf">See Terms Overleaf</h4>
            <div class="stamp-icon">
              <img alt="" [src]="stampUrl" height="92px" width="95px">
            </div>
            <h4 class="fw-bold td-text-dark stamp-date">{{pdf_Data?.date}}</h4>
            <p class="page-count" >Page 1 of 1</p>
          </td>
        </tr>
      </table>
    </div>
  </section>
  <hr class="ps-2 margin-top-10" >
  <p class="page1_note">The Credebt Exchange logo, Credebt and Investabill are registered EU & US trademarks. ©2011-2024 All rights reserved. No part of the contents of this publication may be reproduced, copied or altered without the written permission of a Director of Credebt Exchange Limited. Registered No. 501210. Directors: P. Reynolds, S. Mackarel, M. Keegan</p>
</div>
<div class="page2Content" #page2Content>
  <h3>Credebt Exchange® Master Agreement</h3>
  <p>I/We the Investor hereby expressly confirm the terms and conditions of the Credebt Exchange® Master Agreement are understood. This RPA Order Confirmation Receipt confirms the Revolving Purchase Agreement [RPA] and the Master Agreement made between the Investor and Credebt on the Date Beginning in the RPA. The Investor hereby irrevocably appoints Trade Credebt Limited (or such other entity as Trade Credebt may delegate this power to) as its attorney for the purposes of entering into, negotiating, executing and completing all agreements, documents, transactions and arrangements necessary relating to the purchase by the Investor of Revolving ETR falling within the parameters in the RPA, and in particular, without limitation, Trade Credebt Limited or its nominee shall be authorised under this power of attorney to enter into and execute any trade confirmation for each trade and any other documents or agreement necessary to effect the agreement and purchase of such Revolving ETR. Pursuant to the terms and conditions of the Credebt Exchange® Master Agreement (as it may be amended, supplemented or otherwise modified from time to time, the “Master Agreement” terms not defined herein are used as defined therein), between, among others, Credebt Exchange Limited (“Credebt Exchange®”), the Investor, hereby proposes to purchase from Credebt Exchange® and Credebt Exchange® agrees to sell, Revolving ETR during the Revolving ETR Period in respect of Revolving ETR meeting the parameters set out below on the terms set in the RPA and otherwise subject to the terms and conditions set out in the Credebt Exchange® Master Agreement.
  </p>
  <h3>Credebt Exchange® Document </h3>
  <p>By use of the Exchange, I/We agree to be bound by all of the provisions contained in the Master Agreement and the Credebt Exchange Documents. The  <span style="width:242px; display: inline-block;"> </span> or <span style="width:220px; display: inline-block;"> </span> is a Credebt Exchange® Document. It explains how this RPA Order Confirmation Receipt Amount is impacted and is subject to the terms and conditions set out in the Credebt Exchange® Master Agreement</p>

</div>
